const NotFound = () => {
  return (
    <div>
      <div className="card mt-5">
        <div className="card-header bg-danger">
          <h5 className=" text-white">The requested page could not be found.</h5>
        </div>
        <div className="card-body">Check the Url Address.</div>
      </div>
    </div>
  );
};

export default NotFound;
