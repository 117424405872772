import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";


const Notifications = () => {
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getNotifications();
    }, [currentPage])
    return (
        <div className="card shadow-lg mb-3">
            <div className="card-header p-3">
                <span className="h5 text-primary">Notifications</span>
            </div>
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
                {/* <div>
            <div className="input-group mb-5">
                <input type="text" id="search" className="form-control" placeholder="Search Name or Email"/>
                <button type="button" className="btn btn-sm btn-success" onClick="{searchCustomers}">
                    <i className="bi bi-search mx-1"></i>
                    Search
                </button>
            </div>
        </div> */}
                <div className="table-responsive">
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th>row</th>
                                <th>Title</th>
                                <th>Message</th>
                                <th>Customer</th>
                                <th>Date</th>
                                <th>Seen</th>
                            </tr>
                        </thead>
                        <tbody>

                            {notifications && notifications.length !== 0 ? (
                                <>
                                    {notifications.map((item, index) => (
                                        <tr key={index} className={item.IsSeen? "bg-soft-light":"" }>
                                            <td>{index + 1}</td>
                                            <td>{item.Title}</td>
                                            <td>{item.Message}</td>
                                            <td>{item.CustomerFullName}</td>
                                            <td>{item.CreateDate.replace("GMT", "")}</td>
                                            <td>{item.IsSeen === false ?
                                                <button className="btn btn-sm btn-primary" onClick={() => markAsSeen(item.Id)}>Mark as seen</button>
                                                : <i className="fa fa-check text-success"></i>}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="6">
                                        <div className="alert alert-danger">No Item Found</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <button className={currentPage > 1 ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage - 1)}>
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <>
                                {(index + 1 >= currentPage - 2) && (index + 1 <= currentPage + 2) ?
                                    <li className="page-item" key={index + 1}>
                                        <button className={`btn page-link ${currentPage === index + 1 ? "active" : ""}`} onClick={() => setCurrentPage(index + 1)}>
                                            {index + 1}
                                        </button>
                                    </li>
                                    : ""}
                            </>
                        ))}
                        <li className="page-item">
                            <button className={currentPage < totalPages ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage + 1)}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
    //-------------------------------------------------------
    async function getNotifications() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .get(`/notifications?page=${currentPage}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setNotifications(result.data.data)
                        setTotalPages(result.data.totalPages);
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    async function markAsSeen(id) {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .put(`/notifications/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        getNotifications()
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    //-------------------------------------------------------
};

export default Notifications;
