import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { PublicConsts } from "../../publicConsts";
import FormInput from "../htmlForm/formInput";
import "../../assets/css/login.scss";
import "../../assets/css/login-boxicons.min.scss";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";


const SetupPassword = () => {

    const [urnId, setUrnId] = useState(null);

    useEffect(() => {
        const urlArray = window.location.pathname.split("/");
        const urnId = urlArray[urlArray.length - 1];

        setUrnId(urnId)
    }, []);

    return (
        <div className="bg-light py-5" style={{ height: "100vh" }}>
            <ToastContainer />
            <div className="row col-6 offset-3 pt-5">
                <div className="card p-0">
                    <div className="card-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Setup Password</h5>
                    </div>

                    <div className="card-body">
                        <div id="passwordErrorMessage" className="alert alert-danger d-none"></div>
                        <form id="form" className="formContent">
                            <FormInput type="password" label="Password" name="password" />
                            <FormInput type="password" label="Confirm Password" name="confirmPassword" />
                        </form>
                        <div id="successMessageElement" className="alert alert-success d-none">
                            <i className="bi bi-check-circle me-1"></i>
                            Password has been successfully set up
                            <div>
                                <NavLink className="nav-link mt-2 text-primary p-0" to={`/Customer/${urnId}`}>
                                    <i className="bi bi-box-arrow-in-right me-1"></i>
                                    <span>Go to login</span>
                                </NavLink></div>
                        </div>
                    </div>
                    <div id="footer" className="card-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={setPassword}>
                            <i className="fa fa-lock mx-1"></i>
                            Setup Password
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

    async function setPassword(e) {

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        const errorMessageElement = document.getElementById("passwordErrorMessage")

        errorMessageElement.classList.add("d-none")


        const password = document.getElementById("password").value
        const confirmPassword = document.getElementById("confirmPassword").value

        if (password.length < 6) {
            const errorText = "Your password must be at least 6 characters."
            errorMessageElement.innerHTML = errorText
            errorMessageElement.classList.remove("d-none")
            return toast.error(errorText)
        }
        if (password !== confirmPassword) {
            const errorText = "Password and confirm password are not the same."
            errorMessageElement.innerHTML = errorText
            errorMessageElement.classList.remove("d-none")
            return toast.error(errorText)
        }

        try {


            if (urnId === null) {
                return toast.error("Not Found")
            }
            //---------------------------------------------
            const formData = {
                "urn": urnId,
                "password": password,
            }
            // ---------------------------------------------

            await axiosConfig
                .put(`/account/setup_password`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        //............
                        document.getElementById("form").classList.add("d-none")
                        document.getElementById("successMessageElement").classList.remove("d-none")
                        document.getElementById("footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)

                        errorMessageElement.innerHTML = result.data.message
                        errorMessageElement.classList.remove("d-none")
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            toast.error(PublicConsts.TryCatch_ErrorMessage)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
}

export default SetupPassword;