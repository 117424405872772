import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";

const PaymentTermsModal = ({ customerId, customerEmail, fullName, address, company, urn, getCustomerDetails }) => {

    const [formInputs, setFormInputs] = useState(null);
    const [legalFees, setLegalFees] = useState(null);
    const [govFees, setGovFees] = useState(null);
    const [childrenRows, setChildrenRows] = useState([]);
    const [teamMemberRows, setTeamMemberRows] = useState([]);
    const [paymentRows, setPaymentRows] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0);


    return (
        <div className="modal fade" id="paymentTermsModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Payment Terms Generator</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div id="terms-modal-body" className="modal-body bg-light">
                        <form id="termsForm" method="post">

                            {/* Total Cost && Discount */}
                            <section className="bg-white mb-3 p-3 rounded">
                                <label className="form-label text-warning">Total Cost</label>
                                <hr className="mt-0" />
                                <div className="row">
                                    <div className="col-6 col-md-4">
                                        <strong className="text-primary mt-3">Total Cost</strong>
                                        <div className="input-group input-group-sm">
                                            <input type="number" id="totalCost" name="totalCost" className="form-control text-danger" defaultValue={totalCost ? totalCost : 0} />
                                            <button type="button" className="btn btn-sm btn-success input-group-text" onClick={calculateTotalCost}><i className="fbi bi-calculator mx-1"></i> Apply</button>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* Payment Terms */}
                            <section className="bg-white p-4 rounded mb-3">
                                <label className="form-label text-warning">Payment Terms</label>
                                <hr className="mt-0" />
                                <div className="row">

                                    <div className="col-6 col-md-4">
                                        <label className="form-label">Register customer's payment Terms</label>
                                        <div className="input-group mb-3">
                                            <input type="number" id="paymentRowsCount" className="form-control form-control-sm" min="1" max="6" placeholder="Number of Payments" onChange={generatePaymentRows} />
                                        </div>
                                    </div>
                                    <div className="col-6 d-none" id="payment-amount-format-container">
                                        <label className="form-label">Choose Amount format</label>
                                        <div>
                                            <div className="btn-group btn-group-sm" role="group">
                                                <input type="radio" className="btn-check" id="paymentPercentBtn" autoComplete="off" checked onClick={changePaymentAmountFormat} />
                                                <label className="btn btn-primary m-0" htmlFor="paymentPercentBtn">Percent</label>
                                                <input type="radio" className="btn-check" id="paymentAmountBtn" autoComplete="off" onClick={changePaymentAmountFormat} />
                                                <label className="btn btn-secondary m-0" htmlFor="paymentAmountBtn">Amount</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    {paymentRows.map((row, index) => (
                                        <div key={index}>{row}</div>
                                    ))}
                                </div>
                            </section>

                        </form>

                        <div id="success-message-container" className="d-none">
                            <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Proccess Finished Successfully.</div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button id="termsSubmitButton" type="button" className="btn btn-primary" onClick={paymentTermsProccess}>
                            <i className="bi bi-save me-1"></i>
                            <span>Generate And Save</span>
                            <i className="fas fa-spinner fa-spin mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    //--------------------------------------------------------
    async function paymentTermsProccess() {

        const submitBtn = document.getElementById("termsSubmitButton");
        const form = document.getElementById("termsForm");

        let totalInputAmount = 0;

        //Validations
        //---------------------------------------------------------------------------------

        if (totalCost === 0)
            return toast.error("Please apply the Total cost before continue");

        //..............
        const paymentInputs = document.getElementsByClassName("payment-row-input");
        if (paymentInputs.length === 0) return toast.error("Please generate payment terms");
        //.................
        let isAnyInputNull = false;

        Array.from(paymentInputs).forEach(item => {
            if (item.value === "") {
                isAnyInputNull = true;
                submitBtn.classList.remove("disabled");
                return;
            }
            if (item.name.startsWith('payment-amount-')) {
                totalInputAmount += parseInt(item.value);
                //totalPercent += parseInt(item.value);
            }
        });
        if (isAnyInputNull) return toast.error("Please fill out all the payment inputs");
        //..............................
        if (parseInt(totalInputAmount) !== parseInt(totalCost)) return toast.error("The sum of the payment terms is not equal to the total cost ");
        //if (totalPercent !== 100) return toast.error("Selected payment terms percentage are not equal to 100% ");
        //..............................
        //const inputs = form.querySelectorAll('input');
        //let allValid = true;

        //inputs.forEach(input => {
        //    if (input.type === 'number' && (input.value.trim() === '' || isNaN(input.value))) {
        //        allValid = false;
        //        input.classList.add("border-danger");
        //    }
        //    else {
        //        input.classList.remove("border-danger");
        //    }
        //});
        //if (!allValid) return toast.error("Please fill out all the inputs")
        //----------------------------------------------------------------------------------------

        //Confirm
        //---------------------------------------------------------------------------------
        let confirmProcess = window.confirm("Are you sure you want to submit the estimation? This action cannot be undone.")
        if (!confirmProcess) return
        //---------------------------------------------------------------------------------

        submitBtn.classList.add("disabled");
        submitBtn.querySelector(".fa-spinner").classList.remove("d-none");


        //-----------------------------------
        const formData = new FormData(form);
        const formDetails = Object.fromEntries(formData.entries());
        //-----------------------------------
        const data = {
            htmlContent: formDetails,
            //selectedTemplate: 'paymentTerms',
            //subject: subject,
            fullName: fullName,
            cid: customerId,
            //email: customerEmail,

            //address: address,
            //company: company,
            //subtotal: parseInt(totalCost) + parseInt(discountAmount),
            //discount: discountAmount,
            //totalCost: totalCost,
            //portalLink: portalLink,
        }
        //-----------------------------------

        try {
            await axiosConfig
                .post(`/payments/`, data)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message);
                        getCustomerDetails();
                        form.classList.add("d-none");
                        submitBtn.classList.add("d-none")
                        document.getElementById("success-message-container").classList.remove("d-none");
                        document.getElementById("terms-modal-body").classList.remove("bg-light")
                    } else {
                        toast.error(response.data.message, { timeOut: 0, });
                    }
                })
                .catch((error) => {
                    toast.error('Error on proccess. Please try again later.', { timeOut: 0, });
                });

        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitBtn.classList.remove("disabled");
        submitBtn.querySelector(".fa-spinner").classList.add("d-none");
    }
    function generatePaymentRows() {
        const rowsCountInput = document.getElementById("paymentRowsCount")
        const maxRow = rowsCountInput.max
        const rowsCount = rowsCountInput.value

        if (totalCost === 0) return toast.warning("Please apply the total price first.")
        if (rowsCount < 1 || rowsCount > maxRow) return toast.error(`number of payments should be between 1 and ${maxRow}`)
        //..............
        document.getElementById("payment-amount-format-container").classList.remove("d-none")
        //..............
        const newRows = [];
        for (let i = 0; i < rowsCount; i++) {
            newRows.push(
                <div className="row mb-3" key={i}>
                    <div className="col-1 text-center"><input className="form-control form-control-sm p-1 text-center" disabled value={i + 1} /></div>
                    <div className="col-4">
                        <div className="input-group input-group-sm">
                            <input type="number" className="form-control form-control-sm payment-row-amount isPercent" min="0" max="100" id={`payment-cost-${i + 1}`} placeholder="Payment Percent" onBlur={displayPaymentAmount} />
                            <span class="input-group-text payment-row-icon">
                                <i className="fa fa-sm fa-percent"></i>
                                <i className="fa fa-pound-sign d-none"></i>
                            </span>
                        </div>
                    </div>
                    <div className="col-4">
                        <input type="date" className="form-control form-control-sm payment-row-input" name={`payment-date-${i + 1}`} placeholder="Pay Date" onChange={changeSelectedDateFormat} />
                        <p className="formattedDate h6 mt-2 opacity-50"></p>
                    </div>
                    <div className="col-3">
                        <span className="input-group input-grpup-sm d-none" id={`payment-amount-container-${i + 1}`}>
                            <span className="input-group-text p-1"><i class='fa fa-2xs fa-pound-sign'></i></span>
                            <input class="form-control form-control-sm payment-row-input" id={`payment-amount-${i + 1}`} name={`payment-amount-${i + 1}`} readOnly="true" />
                        </span>
                    </div>
                </div>
            );
        }
        setPaymentRows(newRows);
    }
    function displayPaymentAmount(e) {

        const isPercentMode = e.target.classList.contains("isPercent")
        const elementId = e.target.id.split("-")[2]
        const elementValue = parseInt(e.target.value)
        const paymentCostContainer = document.getElementById("payment-amount-container-" + elementId)
        const paymentCostElement = document.getElementById("payment-amount-" + elementId)
        let amount = 0


        if (isPercentMode)
            amount = (elementValue / 100) * totalCost
        else
            amount = elementValue

        if (amount) {
            paymentCostElement.value = amount
            paymentCostContainer.classList.remove("d-none")
        }

    }
    function calculateTotalCost(shouldReturnValue) {

        let totalCost = document.getElementById("totalCost").value;

        if (parseInt(totalCost) === 0) {
            toast.warning('Please enter the total cost')
            return
        }

        setTotalCost(totalCost);
        setDiscountAmount(0); //Reset Discount state
        setPaymentRows([])//reset rows state

        if (shouldReturnValue === true) {
            return totalCost
        }
        else {
            toast.success("Total Cost has been updated")
        }

    }
    function changeInputStatus(inputId, event) {
        const input = document.getElementById(inputId)
        const checkbox = event.target

        if (checkbox.checked) {
            input.disabled = false;
            input.value = input.getAttribute("data-default-value");
            input.classList.remove("bg-secondary")
            input.classList.add("bg-soft-light")
            toast.info("This field will be included")
        }
        else {
            input.disabled = true;
            input.setAttribute("data-default-value", input.value)
            input.value = 0
            input.classList.remove("bg-soft-light")
            input.classList.add("bg-secondary")
            toast.warning("This field will not be included")
        }
    }
    function changePaymentAmountFormat(e) {

        const elementId = e.target.id
        const percentBtn = document.getElementById("paymentPercentBtn");
        const amountBtn = document.getElementById("paymentAmountBtn");
        const percentLabel = document.querySelector("label[for='paymentPercentBtn']");
        const amountLabel = document.querySelector("label[for='paymentAmountBtn']");
        const amountInputs = document.getElementsByClassName("payment-row-amount")
        const amountIcons = document.getElementsByClassName("payment-row-icon")


        if (elementId === "paymentPercentBtn") {

            percentLabel.classList.remove("btn-secondary");
            percentLabel.classList.add("btn-primary");
            amountLabel.classList.add("btn-secondary");
            //..................
            for (let i = 0; i < amountInputs.length; i++) {
                amountInputs[i].placeholder = "Amount Percent";
                amountInputs[i].max = 100;
                amountInputs[i].classList.add("isPercent")
            }
            for (let i = 0; i < amountIcons.length; i++) {
                //amountIcons[i].innerHTML = "%";
                amountIcons[i].querySelector(".fa-percent").classList.remove("d-none");
                amountIcons[i].querySelector(".fa-pound-sign").classList.add("d-none");
            }

        }
        else if (elementId === "paymentAmountBtn") {

            amountLabel.classList.remove("btn-secondary");
            amountLabel.classList.add("btn-primary");
            percentLabel.classList.add("btn-secondary");
            //..................
            for (let i = 0; i < amountInputs.length; i++) {
                amountInputs[i].placeholder = "Amount";
                amountInputs[i].removeAttribute("max");
                amountInputs[i].classList.remove("isPercent")
            }
            for (let i = 0; i < amountIcons.length; i++) {
                //amountIcons[i].innerHTML = "<i className='fa fa-pound-sign'></i>";
                amountIcons[i].querySelector(".fa-percent").classList.add("d-none");
                amountIcons[i].querySelector(".fa-pound-sign").classList.remove("d-none");
            }
        }

    }
    function changeSelectedDateFormat(e) {

        const dateInput = e.target;
        const formattedDate = dateInput.nextElementSibling;

        const date = new Date(dateInput.value);
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        const formatted = date.toLocaleDateString(undefined, options);
        formattedDate.textContent = formatted;
    }
};

export default PaymentTermsModal;