import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/portal.scss";
import UploadDocumentModal from "../modals/portal_UploadDocumentModal"
import ChoosePaymentMethodtModal from "../modals/portal_choosePaymentMethodModal"
//import PortalEstimateModal from "../modals/portal_estimateModal"
import PortalTicketModal from "../modals/portal_ticketModal"
import ChangePasswordModal from "../modals/changePasswordModal"


const Portal = () => {

    const [customerDetails, setCustomerDetails] = useState(null);
    const [fullName, setFullName] = useState(null);


    //Google Recaptcha ---------------------------------------------------------------------------
    const reCaptchaSiteKey = PublicConsts.ReCaptchaSiteKey;

    //Add google recaptcha script to header
    useEffect(() => {

        const loadReCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                getDetails();
            };

            document.head.appendChild(script);
        };

        // Call this function to load the script
        loadReCaptchaScript();
    }, []);

    useEffect(() => {
        if (customerDetails != null) {
            let title = ""
            if (customerDetails.Title) { title = customerDetails.Title }
            setFullName(title + " " + customerDetails.Name + " " + customerDetails.LastName)
        }

    }, [customerDetails]);

    //-------------------------------------------------------------------------------------------

    return (
        <div className="portal-container pt-3">
            <div id="loadingContainer" className="alert alert-danger d-none mb-3" style={{ zIndex: "9999" }}>
                Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
            </div>

            <div className="container">
                <div className="main-body">
                    <div className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="d-flex flex-column align-items-center text-center">
                                        {customerDetails !== null && customerDetails.ProfilePicture ?
                                            <img src={PublicConsts.user_portal_url + `${customerDetails.UrnId}/${customerDetails.ProfilePicture}`} alt="Avatar" className="rounded-circle" width="150" />
                                            :
                                            <img src="/assets/img/profile-picture.png" alt="Avatar" className="rounded-circle" width="150" />
                                        }
                                        <div className="mt-3">
                                            <h4>{customerDetails ? customerDetails.Name : "Unknow"}</h4>
                                            <p className="text-muted mb-1">Customer</p>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <button className="btn btn-sm btn-tertiary" data-bs-toggle="modal" data-bs-target="#ticketsModal">
                                            <i className="fa fa-question-circle mx-1"></i>Tickets
                                        </button>
                                        <button className="btn btn-sm btn-outline-danger float-end" title="Change Password" data-bs-toggle="modal" data-bs-target="#changePasswordModal">
                                            <i className="fa sa-sm fa-lock mx-1"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card mb-3">
                                <div className="card-body px-4">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-user-circle text-muted mx-1"></i>Full Name</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {fullName && customerDetails ? fullName : ""}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-calendar text-muted mx-1"></i>Email</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {customerDetails ? customerDetails.Email : ""}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-tags text-muted mx-1"></i>Status</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {customerDetails ?
                                                (customerDetails.Status === 3 && customerDetails.IsDocRequestSent ?
                                                    <span className="fw-bold text-warning">Waiting For Documents</span>
                                                    : <span className="fw-bold text-success">{customerDetails.StatusTitle}</span>)
                                                : ""}
                                        </div>
                                    </div>
                                    <hr />
                                    {/*{customerDetails && customerDetails.EstimateDetails ?*/}
                                    {/*    <>*/}
                                    {/*        <div className="row">*/}
                                    {/*            <div className="col-sm-4">*/}
                                    {/*                <h6 className="mb-0"><i className="fa fa-calculator text-muted mx-1"></i>Expansion Estimate</h6>*/}
                                    {/*            </div>*/}
                                    {/*            <div className="col-sm-8 text-muted text-end">*/}
                                    {/*                <button className="btn btn-sm btn-link btn-outline-primary mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#estimateDetailsModal"><i className="fa fa-eye mx-1"></i>Display</button>*/}
                                    {/*            </div>*/}
                                    {/*        </div>*/}
                                    {/*        <hr />*/}
                                    {/*    </>*/}
                                    {/*    : ""}*/}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Documents */}
                    <section className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-12 col-md-3"><h6 className="text-primary fw-bold">Documents</h6></div>
                                        <div className="col-12 col-md-9 text-end">
                                            {customerDetails ?
                                                <>
                                                    {/* {customerDetails.Status >= 2 && estimateDetails && customerDetails.IsEstimateApproved === false ? (*/}
                                                    {/*<button className="btn btn-sm btn-primary mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#estimateDetailsModal"><i className="fa fa-calculator mx-1"></i>Expansion Estimate</button>*/}
                                                    {/*) : ""}*/}
                                                    {customerDetails.Status === 5 && !customerDetails.PaymentMethod ? (
                                                        <button className="btn btn-sm btn-success mx-1 mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#choosePaymentMethodModal"><i className="fa fa-credit-card mx-1"></i>Choose Payment Method</button>
                                                    ) : ""}
                                                    {customerDetails.Status === 3 || customerDetails.Status === 4 ?
                                                        <button className="btn btn-sm btn-primary mx-1 mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#uploadDocModal"><i className="fa fa-file-upload mx-1"></i>Upload Document</button>
                                                        : ""}
                                                    {(customerDetails.Status === 5 || customerDetails.Status === 6) && customerDetails.PaymentMethod !== null ? (
                                                        customerDetails.Documents.filter(doc => doc.Type === 'payment' && doc.Status === '0').length > 0 ?
                                                            <span className="text-warning"><i className="fa fa-info-circle mx-1"></i>We are proccessing your documents</span> :
                                                            <button className="btn btn-sm btn-primary mt-2 mt-md-0" data-bs-toggle="modal" data-bs-target="#uploadDocModal"><i className="fa fa-file-upload mx-1"></i>Upload Document</button>

                                                    ) : ""}

                                                </> : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Name</th>
                                                    <th>File</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    (customerDetails && customerDetails.Documents && customerDetails.Documents.length > 0 ?

                                                        customerDetails.Documents.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.Type}</td>
                                                                <td>{item.Title}</td>
                                                                <td><a target="_blank" rel="noreferrer" className="text-decoration-none" href={PublicConsts.view_docs_base_url + `${customerDetails.UrnId}/${item.FileName}`}>View File</a></td>
                                                                <td>{item.CreateDate.split(".")[0]}</td>
                                                                <td className="status">
                                                                    {item.Title !== 'Initial Agreement' && item.Type !== 'DDR' ?
                                                                        <>
                                                                            {
                                                                                item.Status === '-1' ?
                                                                                    <span className="fail">Rejected</span> : item.Status === '0' ?
                                                                                        <span className="waiting">Waiting</span> : item.Status === '1' ?
                                                                                            <span className="success">Accepted</span> : ""
                                                                            }
                                                                        </>
                                                                        :
                                                                        "-"
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan="5"><div className="alert alert-warning">No File Uploaded</div></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Payment Terms */}
                    <section className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6 className="d-flex align-items-center mb-3 text-primary fw-bold">Payment Terms</h6>
                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Invoice Number</th>
                                                    <th>Amount</th>
                                                    <th>Pay Date</th>
                                                    <th>Update Date</th>
                                                    <th>Pay Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(customerDetails && customerDetails.Payments && customerDetails.Payments.length > 0 ?

                                                    customerDetails.Payments.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.InvoiceNumber}</td>
                                                            <td>{item.Amount}</td>
                                                            <td>{item.PayDate}</td>
                                                            <td>{item.UpdateDate.split(".")[0]}</td>
                                                            <td className="status">
                                                                {item.IsPaid === 'False' ?
                                                                    <span className="fail">Not Paid</span> : item.IsPaid === 'True' ?
                                                                        <span className="success">Paid</span> : ""
                                                                }
                                                            </td>
                                                        </tr>
                                                    )) : <tr><td colSpan="5"><div className="alert alert-warning">No Record Found</div></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

            {/* Modals---------------------------------------------------------- */}
            {customerDetails ? <UploadDocumentModal customerId={customerDetails.Id} customerStatus={customerDetails.Status} getDetails={getDetails} /> : ""}
            {customerDetails ? <ChoosePaymentMethodtModal customerId={customerDetails.Id} getDetails={getDetails} /> : ""}
            {/* {customerDetails ? <PortalEstimateModal customerId={customerDetails.Id} customer={customerDetails} getDetails={getDetails} /> : ""} */}
            {customerDetails ? <PortalTicketModal customerId={customerDetails.Id} customer={customerDetails} getDetails={getDetails} /> : ""}
            {customerDetails ? <ChangePasswordModal customerId={customerDetails.Id} /> : ""}

        </div>
    );
    async function getDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        const urlArray = window.location.pathname.split("/");
        const urnId = urlArray[urlArray.length - 1];

        await getRecaptchaToken();

        if (urnId !== "") {
            try {
                await axiosConfig
                    .get(`/customer/urn/${urnId}`)
                    .then((result) => {
                        if (result.data.status === "success") {
                            setCustomerDetails(result.data.data)

                        } else {
                            toast.error(PublicConsts.Result_NotFound)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } catch (error) {
                console.log(error)
            }
        }
        else {
            toast.error(PublicConsts.Result_NotFound)
        }

        loadingContainer.classList.add("d-none");
    }
    function getRecaptchaToken() {
        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' })
                    .then(token => {
                        resolve(token);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }
    //---------------------------------------------------
};

export default Portal;
