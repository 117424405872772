import FormInput from "../htmlForm/formInput";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";
import moment from 'moment';
import { PublicConsts } from "../../publicConsts";


const CreateCalendarEventModal = ({ getEvents }) => {

    return (
        <div className="modal fade" id="addCalendarEventModal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header bg-primary py-2">
                        <h5 className="modal-title text-white">
                            New Event
                        </h5>
                        <button type="button" className="btn btn-close btn-sm" onClick={handleCloseModal}><i className="fa fa-close text-white"></i></button>
                    </div>
                    <div className="modal-body">
                        <div className="mb-3">
                            <span>Selected Date: </span><span id="shortSelectedDateInModal" className=" text-primary"></span>
                            <span className="d-none" id="selectedDateInModal" />
                        </div>
                        <form id="formData">
                            <div className="row row-cols-2 d-none">
                                <div className="col">
                                    <FormInput label="Start Date" name="StartDate" required />
                                </div>
                                <div className="col">
                                    <FormInput label="End date" name="EndDate" required />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <FormInput label="Title" name="Title" />
                                </div>
                            </div>
                            <div className="mt-3 text-end">
                                <button
                                    type="button"
                                    className="btn btn-sm btn-success mx-1 mb-1"
                                    onClick={createEvent}
                                >
                                    <i className="fa fa-plus mx-1"></i>
                                    Create
                                    <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                                </button>
                            </div>

                        </form>
                        <div id="success-message-container" className="d-none">
                            <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Event Created Successfully.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // ------------------------------------------
    async function createEvent(e) {
        const submitButton = e.target;
        const spinner = submitButton.querySelector(".fa-spinner");

        const StartDate = document.getElementById("selectedDateInModal").innerHTML
        const EndDate = moment(StartDate).add(1, 'hours').toDate(); // Default duration: 1 hour
        const startDateShort = moment(StartDate).format('YYYY-MM-DD');
        const Title = document.getElementById("Title").value;
        if (!Title) {
            toast.error("Please Enter The Title")
            return
        }

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("formData");
            const formData = new FormData();
            formData.append("StartDate", StartDate)
            formData.append("EndDate", EndDate)
            formData.append("Title", Title)
            formData.append("StartDateShort", startDateShort)
            const formDetails = Object.fromEntries(formData.entries());
            //-----------------------------------
            await axiosConfig
                .post(`/calendar_events/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        form.classList.add("d-none");
                        document.getElementById("success-message-container").classList.remove("d-none");
                        getEvents()
                    } else toast.error(result.data.message);
                })

        }
        catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);

        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }

    function handleCloseModal() {
        const modal = document.getElementById("addCalendarEventModal")
        const backDrop = document.getElementById('modal-backdrop');
        if (modal) {
            modal.classList.remove("d-block")
            //backDrop.classList.remove("d-block")
            backDrop.classList.remove("modal-backdrop")
        }
    }
};

export default CreateCalendarEventModal;