import { useEffect } from "react";
import { toast } from "react-toastify"
import { PublicConsts } from "../../publicConsts";
import axiosConfig from "../../axiosConfig";

const InvoiceSendInvoiceEmailModal = ({ customerId, invoiceDetails, getCustomerDetails }) => {

    const subject = "Your Invoice - Payment Instructions - UK Residency";
    const messageLink = PublicConsts.user_portal_url + invoiceDetails.urn;
    const firstInvoiceMessage =
        `<p>Dear ${invoiceDetails.fullName},</p><br>

        <p>We are pleased to inform you that your signed copy of the client service agreement has been uploaded to the portal and accepted. Attached to this email is your first invoice detailing the amount due and our banking information.</p><br>

        <p>To facilitate a smooth process, please pay at your earliest convenience. Once the payment has been completed, kindly upload the proof of payment to the portal. This will enable us to promptly reconcile the transaction and update our records accordingly.</p><br>

        <p><a target='_blank' href="${messageLink}">Click here to upload payment receipt</a></p><br>

        <p>Should you have any questions or require further assistance, please do not hesitate to contact us. We are here to support you and ensure everything proceeds seamlessly.</p><br>

        <p>Thank you once again for your cooperation and prompt attention to this matter.</p><br>

        <p>Best regards,</p>
        `;
    const otherInvoiceMessage =
        `<p>Dear ${invoiceDetails.fullName},</p><br>

        <p>Attached to this email is your invoice detailing the amount due and our banking information.</p><br>

        <p>To facilitate a smooth process, please pay at your earliest convenience. Once the payment has been completed, kindly upload the proof of payment to the portal. This will enable us to promptly reconcile the transaction and update our records accordingly.</p><br>

        <p><a target='_blank' href="${messageLink}">Click here to upload payment receipt</a></p><br>

        <p>Should you have any questions or require further assistance, please do not hesitate to contact us. We are here to support you and ensure everything proceeds seamlessly.</p><br>

        <p>Thank you once again for your cooperation and prompt attention to this matter.</p><br>

        <p>Best regards,</p>
        `;


    useEffect(() => {

        let message = '';
        if (invoiceDetails.index === 0) {
            message = firstInvoiceMessage
        }
        else {
            message = otherInvoiceMessage
        }

        document.getElementById("invoiceSendInvoiceEmailContent").innerHTML = message;
    }, []);

    return (

        <div className="modal fade" id="invoiceSendInvoiceEmailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Send Email</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send Invoice by Email</div>
                            <div className="mb-3"><span className="text-muted">Email Address: </span><span>{invoiceDetails.email}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
                            <div className="mb-3"><span className="text-muted">Payment Amount (GBP): </span><span className="text-danger">{invoiceDetails.amounts.amountGbp}</span></div>
                            <div className="mb-3"><span className="text-muted">Final Payment Amount ({invoiceDetails.amounts.currency}): </span><span className="text-danger">{invoiceDetails.amounts.amount}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Message: </span><span id="invoiceSendInvoiceEmailContent"></span></div>
                        </form>
                        <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={invoiceSendInvoiceEmail}>
                            <i className="fa fa-paper-plane mx-1"></i>
                            Send
                            <i className="fas fa-spinner fa-spin mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
    //---------------------------------------------------------------
    async function invoiceSendInvoiceEmail(e) {

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        submitButton.classList.add("disabled");
        submitButtonSpinner.classList.remove("d-none");

        let message = '';
        if (invoiceDetails.index === 0) {
            message = firstInvoiceMessage
        }
        else {
            message = otherInvoiceMessage
        }

        try {

            // ---------------------------------------------
            const email_detail = {
                "cid": customerId,
                "recipient_email_address": invoiceDetails.email,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": true,
                // "target": invoiceDetails.invoiceType
                "target": "invoice"
            }

            const attachmentContentFields = {
                "text_Name": invoiceDetails.fullName,
                "URN": invoiceDetails.urn,
                "text_Address": invoiceDetails.address,
                "text_InvoiceNumber": invoiceDetails.invoiceNumber,
                "text_PaymentCount": invoiceDetails.paymentCount,
                "text_PayDate": invoiceDetails.payDate,
                "text_Amount": invoiceDetails.amounts.amount,
                "text_AmountGbp": invoiceDetails.amounts.amountGbp,
                "text_Currency": invoiceDetails.amounts.currency,
            }

            const formData = new FormData();
            formData.append('email_detail', JSON.stringify(email_detail));
            formData.append('attachment_content_fields', JSON.stringify(attachmentContentFields));
            // ---------------------------------------------

            await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getCustomerDetails();
                        //............
                        const modal = document.getElementById("invoiceSendInvoiceEmailModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".successMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default InvoiceSendInvoiceEmailModal;