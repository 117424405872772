import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";

const ChoosePaymentMethodModal = ({ customerId, getDetails }) => {

    return (

        <div className="modal fade" id="choosePaymentMethodModal" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-success">
                        <h5 className="modal-title text-white">Choose Payment Method</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <div className="mb-3">
                            Payment Methods:
                            <select id="paymentMethod" className="btn btn-sm border-info col-6 ms-2">
                                <option value="" required>Choose Payment Method</option>
                                <option value="International Bank Transfer">International Bank Transfer</option>
                                <option value=" UK to UK Bank Transfer">UK to UK Bank Transfer</option>
                                <option value="Online Card Payment">Online Card Payment</option>
                                <option value="Cryptocurrency">Cryptocurrency</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            Payment Currency:
                            <select id="paymentCurrency" className="btn btn-sm border-info col-6 ms-2">
                                <option value="" required>Choose Payment Currency</option>
                                <option value="AED">AED</option>
                                <option value="USD">USD</option>
                                <option value="GBP">GBP</option>
                                <option value="EUR">EUR</option>
                            </select>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={updateCustomerPaymentMethod}><i className="fa fa-save mx-1"></i>
                            Save
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>


    );
    //.....................................
    async function updateCustomerPaymentMethod(e) {

        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");
        const paymentMethod = document.getElementById("paymentMethod").value
        const paymentCurrency = document.getElementById("paymentCurrency").value
        if (paymentMethod === "") return toast.error("Please Choose a payment method")

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");

            const data = {
                "cid": customerId,
                "paymentMethod": paymentMethod,
                "paymentCurrency": paymentCurrency
            }

            await axiosConfig
                .put(`/customer/payment_method/`, data)
                .then((result) => {

                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getDetails();
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
};

export default ChoosePaymentMethodModal;