import { Route, Routes } from "react-router-dom";

import PanelDashboard from "./components/pages/panel/dashboard";
import PanelCustomers from "./components/pages/panel/customers";
import PanelCustomer from "./components/pages/panel/customer";
import PanelNotifications from "./components/pages/panel/notifications";
import PanelActivities from "./components/pages/panel/activities";
import PanelTickets from "./components/pages/panel/tickets";
import PanelTicket from "./components/pages/panel/ticket";
import PanelUsers from "./components/pages/panel/users";
import PanelSettings from "./components/pages/panel/settings";
import PanelInbox from "./components/pages/panel/dataInbox";
import PanelEventCalendar from "./components/pages/panel/eventCalendar";


import NotFound from "./components/pages/notFound";
import Error403 from "./components/pages/error403";
import Login from "./components/pages/login";
import Portal from "./components/pages/portal";
import SetupPassword from "./components/pages/setupPassword";

const RoutingPaths = () => {
    return (
        <>
            <Routes>
                <Route path="/Panel/dashboard" Component={PanelDashboard} />
                <Route path="/Panel/customers" Component={PanelCustomers} />
                <Route path="/Panel/customer/:id" Component={PanelCustomer} />
                <Route path="/Panel/notifications" Component={PanelNotifications} />
                <Route path="/Panel/activities" Component={PanelActivities} />
                <Route path="/Panel/tickets" Component={PanelTickets} />
                <Route path="/Panel/ticket/:id" Component={PanelTicket} />
                <Route path="/Panel/users" Component={PanelUsers} />
                <Route path="/Panel/settings" Component={PanelSettings} />
                <Route path="/Panel/inbox" Component={PanelInbox} />
                <Route path="/Panel/calendar" Component={PanelEventCalendar} />


                <Route path="/Login" exact Component={Login} />
                <Route path="/Error403" exact Component={Error403} />
                <Route path="/Customer/:id" exact Component={Portal} />
                <Route path="/SetupPassword/:id" exact Component={SetupPassword} />
                <Route path="/" exact Component={Login} />
                <Route path="*" Component={NotFound} />
            </Routes>
        </>
    );
};

export default RoutingPaths;
