import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";

const UploadDocumentModal = ({ customerId, customerStatus, getDetails }) => {
    return (
        <div className="modal fade" id="uploadDocModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Upload Documents</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-light">
                        <div className="mb-3">
                            <div className="alert alert-info">
                                <div><i className="fa fa-info-circle mx-1"></i>Maximum file size: <span className="text-danger">2MB</span></div>
                                <div><i className="fa fa-info-circle mx-1"></i>Allowed file types: <span className="text-danger">jpg, jpeg, png, pdf</span></div>
                            </div>
                        </div>

                        {customerStatus ?
                            (customerStatus === 3 ? (

                                <>
                                    {/* Setp 3 - File Inputs */}
                                    <div className="card p-3 mb-3">
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Proof of Identity <span className="text-danger">*</span></label>
                                            <form id="form-passport" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="passport" name="passport" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-passport" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-passport" className="alert alert-success d-none">Passport Uploaded Successfully</div>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Proof of Address <span className="text-danger">*</span></label>
                                            <form id="form-address" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="address" name="address" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-address" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-address" className="alert alert-success d-none">Proof of Address Uploaded Successfully</div>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Valid UK Visa (if applicable)</label>
                                            <form id="form-uk_visa" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="uk_visa" name="uk_visa" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm  btn-success" id="btn-uk_visa" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-uk_visa" className="alert alert-success d-none">Valid UK Visa Uploaded Successfully</div>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Previous refusals (If applicable)</label>
                                            <form id="form-previous_refusals" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="previous_refusals" name="previous_refusals" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-previous_refusals" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-previous_refusals" className="alert alert-success d-none">Previous refusals Uploaded Successfully</div>
                                        </div>
                                    </div>
                                    <div className="card p-3 mb-3">
                                        {/*<div className="mb-5">*/}
                                        {/*    <label className="form-label text-primary">Business Documents (If applicable)</label>*/}
                                        {/*    <form id="form-business" className="input-group" encType="multipart/form-data">*/}
                                        {/*        <input type="file" id="business" name="business" className="form-control form-control-sm" />*/}
                                        {/*        <button type="button" className="btn btn-sm btn-success" id="btn-business" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>*/}
                                        {/*    </form>*/}
                                        {/*    <div id="success-message-container-business" className="alert alert-success d-none">Business Documents Uploaded Successfully</div>*/}
                                        {/*</div>*/}
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Company Incorporation Certificate (If applicable)</label>
                                            <form id="form-incorporation_certificate" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="incorporation_certificate" name="incorporation_certificate" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-incorporation_certificate" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-incorporation_certificate" className="alert alert-success d-none">Company Incorporation Certificate Uploaded Successfully</div>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Company Profile (If applicable)</label>
                                            <form id="form-company_profile" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="company_profile" name="company_profile" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-company_profile" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-company_profile" className="alert alert-success d-none">Company Profile Uploaded Successfully</div>
                                        </div>
                                        {/*<div className="mb-5">*/}
                                        {/*    <label className="form-label text-primary">Personal/Immigration Documents (If applicable)</label>*/}
                                        {/*    <form id="form-immigration_document" className="input-group" encType="multipart/form-data">*/}
                                        {/*        <input type="file" id="immigration_document" name="immigration_document" className="form-control form-control-sm" />*/}
                                        {/*        <button type="button" className="btn btn-sm btn-success" id="btn-immigration_document" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>*/}
                                        {/*    </form>*/}
                                        {/*    <div id="success-message-container-immigration_document" className="alert alert-success d-none">Personal/Immigration Uploaded Successfully</div>*/}
                                        {/*</div>*/}
                                    </div>
                                    <div className="card p-3 mb-3">
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Website Screenshot</label>
                                            <form id="form-website" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="website" name="website" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-website" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-website" className="alert alert-success d-none">Website Screenshot Uploaded Successfully</div>
                                        </div>
                                        <div className="mb-5">
                                            <label className="form-label text-primary">Linkedin Profile Screenshot</label>
                                            <form id="form-linkedin" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="linkedin" name="linkedin" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-linkedin" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                            <div id="success-message-container-linkedin" className="alert alert-success d-none">Linkedin Profile Uploaded Successfully</div>
                                        </div>
                                        
                                    </div>
                                </>
                            ) : customerStatus === 4 ? (
                                <>
                                    {/* Step 5 File Inputs */}
                                    <div className="card p-3 mb-3">
                                        <div className="mb-3">
                                            {/* Upload Signed contract */}
                                                <label className="form-label text-primary">Signed Agreement</label>
                                            <form id="form-contract" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="contract" name="contract" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-contract" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                                <div id="success-message-container-contract" className="alert alert-success d-none">Signed Agreement Uploaded Successfully</div>
                                        </div>
                                    </div>
                                </>
                            ) : customerStatus === 5 || customerStatus === 6 ? (
                                <>
                                    {/* Step 6 File Inputs */}
                                    <div className="card p-3 mb-3">
                                        <div className="mb-3">
                                                    <label className="form-label text-primary">Payment Receipt</label>
                                            <form id="form-payment" className="input-group" encType="multipart/form-data">
                                                <input type="file" id="payment" name="payment" className="form-control form-control-sm" />
                                                <button type="button" className="btn btn-sm btn-success" id="btn-payment" onClick={uploadDocument}><i className="fa fa-save mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                            </form>
                                                    <div id="success-message-container-payment" className="alert alert-success d-none">Payment Receipt has been Uploaded Successfully</div>
                                        </div>
                                    </div>
                                </>
                            ) : "") : ""}
                    </div>
                </div>
            </div>
        </div>
    )
    //---------------------------------------------------------

    async function uploadDocument(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");
        const fileId = submitButton.id
        const docType = fileId.replace('btn-', '')

        const fileInput = document.getElementById(docType)
        if (fileInput.files.length === 0) return toast.error("Please Choose the File")

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById(`form-${docType}`);
            const formData = new FormData(form);
            formData.append("CId", customerId)
            formData.append("Type", docType)
            const formDetails = Object.fromEntries(formData.entries());
            //-----------------------------------
            await axiosConfig
                .post(`/customer/upload/`, formDetails, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        fileInput.classList.add("d-none");
                        submitButton.classList.add("d-none");
                        document.getElementById(`success-message-container-${docType}`).classList.remove("d-none");
                        getDetails();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    console.log(error)
                    toast.error("Error on uploading document");
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
}
export default UploadDocumentModal;