import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";

const Settings = () => {
    const [details, setDetails] = useState([]);
    const [generatedRows, setGeneratedRows] = useState([]);
    //const [rowCount, setRowCount] = useState(1);

    useEffect(() => {
        getDetails();
    }, []);

    return (
        <div className="card shadow-lg mb-3">
            <div className="card-header p-3">
                <span className="h5 text-primary">Estimation Settings</span>
                <button className="btn btn-sm btn-primary float-end" onClick={generateRow}><i className="fa fa-plus mx-1"></i> New Row</button>
            </div>
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
                <div className="col-12">

                    <form id="estimateRowsForm">
                        {generatedRows.length > 0 || details.length > 0 ?
                            <div id="rowsHeader" className="row mb-3 border-bottom pb-2 h5">
                                <span className="col-1">#</span>
                                <span className="col-3">Type</span>
                                <span className="col-3">Title</span>
                                <span className="col-3">Cost</span>
                                <span className="col-2">Delete</span>
                            </div>
                            : ""}
                        {/*Database Rows*/}
                        {details && details.length > 0 ? details.map((row, index) => (
                            <div className="row mb-3 estimate-row" id={index + 1} key={index + 1 }>
                                <div className="col-1"><input className="form-control form-control-sm p-1 text-center" disabled defaultValue={index + 1} /></div>
                                <div className="col-3">
                                    <select className="form-control form-control-sm row-input-type" name={`type-${index + 1}`} defaultValue={row.Type}>
                                        <option value="">Select Type</option>
                                        <option value="legal">Legal Fee</option>
                                        <option value="goverment_main">Goverment Fee - Main</option>
                                        <option value="goverment_spouse">Goverment Fee - Spouse</option>
                                        <option value="goverment_child">Goverment Fee - Child</option>
                                    </select>
                                </div>
                                <div className="col-3"><input className="form-control form-control-sm p-1 row-input-title" placeholder="Title (E.g: Healthcare)" name={`title-${index + 1}`} defaultValue={row.Title} /></div>

                                <div className="col-3">
                                    <input type="number" min="0" className="form-control form-control-sm row-input-value" placeholder="Cost (E.g: 256)" name={`cost-${index + 1}`} defaultValue={row.Cost} />
                                </div>
                                <div className="col-2">
                                    <button type="button" className="btn btn-sm btn-danger" onClick={deleteRow}><i className="fa fa-trash"></i></button>
                                </div>
                            </div>
                        )) : <div className="p-3"><div className="alert alert-warning"><i className="fa fa-info-circle mx-1"></i> No field has been defined by the administrator.</div></div>}
                        {/*New Rows*/}
                        {generatedRows ? generatedRows.map((row, index) => (
                            row
                        )) : ""}
                        {/*Submit button*/}
                        {generatedRows.length > 0 || details.length > 0 ?
                            <div className="border-top mt-3 pt-2">
                                <button id="submitButton" type="button" className="btn btn-sm btn-success float-end" onClick={saveRows}>
                                    <i className="fa fa-save mx-1"></i>
                                    Save Settings
                                    <i className="fa fa-spin fa-spinner d-none mx-1"></i>
                                </button>
                            </div>
                            : ""
                        }
                    </form>
                </div>
            </div>
        </div>
    )
    //---------------------------------------------------------------------
    async function getDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/estimate_settings`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setDetails(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function saveRows() {

        const submitBtn = document.getElementById("submitButton");
        const form = document.getElementById("estimateRowsForm");

        //Validations
        //==============================================================================================
        if (form.getElementsByTagName('input').length <= 0) return toast.error("Please Add row(s)")
        //...................................................
        let isAnyInputNull = false;
        Array.from(form).forEach(item => {
            if (item.type !== "button" && item.type !== "number" && item.value === "") {
                isAnyInputNull = true;
                item.classList.add("border-danger");
                return;
            }
            else {
                item.classList.remove("border-danger");
            }
        });
        if (isAnyInputNull) return toast.error("Please fill out all the inputs");
        //===============================================================================

        submitBtn.classList.add("disabled");
        submitBtn.querySelector(".fa-spinner").classList.remove("d-none");

        //-----------------------------------
        const formData = new FormData(form);
        const formDetails = Object.fromEntries(formData.entries());
        //-----------------------------------
        try {
            await axiosConfig
                .post(`/estimate_settings`, formDetails)
                .then((response) => {
                    if (response.data.status === 'success') {
                        toast.success(response.data.message);
                        
                    } else {
                        toast.error(response.data.message, { timeOut: 0, });
                    }
                })
                .catch((error) => {
                    toast.error('Error on proccess. Please try again later.', { timeOut: 0, });
                });

        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitBtn.classList.remove("disabled");
        submitBtn.querySelector(".fa-spinner").classList.add("d-none");
    }
    function generateRow() { 

        const rowCount = details.length + generatedRows.length + 1;

        const newRow = (
            <div className="row mb-3 estimate-row" id={rowCount} key={rowCount}>
                <div className="col-1 text-center"><input className="form-control form-control-sm p-1 text-center" disabled value={rowCount} /></div>
                <div className="col-3">
                    <select className="form-control form-control-sm row-input-type" name={`type-${rowCount}`}>
                        <option value="">Select Type</option>
                        <option value="legal">Legal Fee</option>
                        <option value="goverment_main">Goverment Fee - Main</option>
                        <option value="goverment_spouse">Goverment Fee - Spouse</option>
                        <option value="goverment_child">Goverment Fee - Child</option>
                    </select>
                </div>
                <div className="col-3"><input className="form-control form-control-sm p-1 row-input-title" placeholder="Title (E.g: Healthcare)" name={`title-${rowCount}`} /></div>

                <div className="col-3">
                    <input type="number" min="0" className="form-control form-control-sm row-input-value" placeholder="Cost (E.g: 256)" name={`cost-${rowCount}`} />
                </div>
                <div className="col-2">
                    <button type="button" className="btn btn-sm btn-danger" onClick={deleteRow}><i className="fa fa-trash"></i></button>
                </div>
            </div>
        )
        setGeneratedRows([...generatedRows, newRow]);
    }
    function deleteRow(e) {
        const rowElement = e.target.closest('.row')
        const updatedItems = generatedRows.filter((item, index) => item.key !== rowElement.id - 1);
        rowElement.remove();
    }
};
export default Settings;