import axios from "axios";
import { toast } from "react-toastify";
import { PublicConsts } from "./publicConsts";

const tokenSrting = localStorage.getItem("token");
let baseURL = "http://127.0.0.1:8443";

if (process.env.NODE_ENV !== "development") baseURL = PublicConsts.back_end_domain_name;

const instance = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: tokenSrting,
    },
});

// Add a request interceptor
instance.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        console.log(error);

        if (error.code === "ERR_NETWORK") {
            toast.error(PublicConsts.Axios_NetworkConnectionError);
        }

        if (error.response.status === 401) {
            toast.error(PublicConsts.Axios_SessionExpired);

            localStorage.removeItem("token");
            window.location = "/Login";



        } else if (error.response.status === 403) {
            toast.error(PublicConsts.Axios_Error403);
            window.location = "/Error403";
        } else if (error.response.status === 500) {
            toast.error(PublicConsts.Axios_Error500);

            //User Role Error
            if (error.response.data.includes("The AuthorizationPolicy named")) {
                window.location = "/NotFound";
                //console.log(error.response.data);
            }
        }
        return Promise.reject(error);
    }
);

export default instance;
