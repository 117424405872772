import { useEffect } from "react";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";

const FirstCallNotEligibleEmailModal = ({customerId, customerEmail})=>{

  const subject = "UK Residency - Application Result";
  const message = `<p>Welcome to Uk Residency.</p> <p>Unfortunately, you do not have the necessary conditions.</p>`;

  useEffect(() => {
    document.getElementById("firstCallNotEligibleEmailContent").innerHTML = message;
  }, []);

    return(
    
        <div className="modal fade" id="firstCallNotEligiblEmailModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header py-2 bg-danger">
              <h5 className="modal-title text-white">Send Email</h5>
              <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <form className="formContent">
              <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send Not Eligible Email to the Customer</div>
              <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerEmail}</span></div>
              <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
              <div className="mb-3"><span className="text-muted">Email Message: </span><span id="firstCallNotEligibleEmailContent"></span></div>
            </form>
            <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
          
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-danger" onClick={firstCallNotEligibleSendEmail}>
                <i className="fa fa-paper-plane mx-1"></i>
                Send
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                </button>
            </div>
          </div>
        </div>
      </div>
    
        )
        //---------------------------------------------------------------
    async function firstCallNotEligibleSendEmail(e){

      const submitButton = e.target;
      const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
      submitButton.classList.add("disabled");
      submitButtonSpinner.classList.remove("d-none");

      // const mainContent = document.getElementsByClassName("formContent");
      // const successMessage = document.getElementsByClassName("successMessage");
      // const footer = document.getElementsByClassName("modal-footer");

        try {

          // ---------------------------------------------
          const email_detail ={
            "cid": customerId,
            "recipient_email_address": customerEmail,
            "email_subject": subject,
            "email_message": message,
            "has_attachment": false,
            "target": "not_eligible"
          }

          const formData = new FormData();
          formData.append('email_detail', JSON.stringify(email_detail));
          // ---------------------------------------------

          await axiosConfig
            .post(`/send_email`, formData)
            .then((result) => {
              if (result.data.status === "success") {
                toast.success(result.data.message)
                 //............
                 const modal = document.getElementById("firstCallNotEligiblEmailModal")
                 modal.querySelector(".formContent").classList.add("d-none")
                 modal.querySelector(".successMessage").classList.remove("d-none")
                 modal.querySelector(".modal-footer").classList.add("d-none")
              }else {
                toast.error(result.data.message)
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      
        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default FirstCallNotEligibleEmailModal;