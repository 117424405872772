import { useEffect, useState } from "react";

import FormInput from "../htmlForm/formInput";
import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify";
import axiosConfig from "../../axiosConfig";

const UpdateCustomerDetailsModal = ({ id, getCustomerDetails }) => {

    const [customerDetails, setCustomerDetails] = useState([]);
    const [gender, setGender] = useState("");
    const [title, setTitle] = useState("");
    const [statuses, setStatuses] = useState([]);

    useEffect(() => {
        setGender(customerDetails.Gender);
        setTitle(customerDetails.Title);
    }, [customerDetails]);

    useEffect(() => {
        getStatuses();
        fetchCustomerDetails(id);
    }, [id]);

    return (
        <div className="modal fade" id="updateCustomerDetailsModal">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header bg-primary py-2">
                        <h5 className="modal-title text-white">
                            Update Customer Details
                        </h5>
                        <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#updateCustomerDetailsModal"><i className="fa fa-close text-white"></i></button>
                    </div>
                    <div className="modal-body">
                        <form id="formData">
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Name"
                                        name="Name"
                                        defValue={customerDetails.Name}
                                        required
                                    />
                                </div>
                                <div className="col">
                                    <FormInput
                                        label="Last Name"
                                        name="LastName"
                                        defValue={customerDetails.LastName}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Email"
                                        name="Email"
                                        defValue={customerDetails.Email}
                                    />
                                </div>
                                <div className="col">
                                    <FormInput
                                        label="Mobile"
                                        name="Mobile"
                                        defValue={customerDetails.Mobile}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Nationality"
                                        name="Nationality"
                                        defValue={customerDetails.Nationality}
                                    />
                                </div>
                                <div className="col">
                                    <FormInput
                                        label="Country"
                                        name="Country"
                                        defValue={customerDetails.Country}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Company Name"
                                        name="CompanyName"
                                        defValue={customerDetails.CompanyName}
                                    />
                                </div>
                                <div className="col">
                                    <FormInput
                                        label="Occupation"
                                        name="Occupation"
                                        defValue={customerDetails.Occupation}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        type="number"
                                        label="Revenue (Last Year)"
                                        name="RevenueLastYear"
                                        defValue={customerDetails.RevenueLastYear}
                                    />
                                </div>
                                <div className="col">
                                    <FormInput
                                        type="number"
                                        label="Profit (Last Year)"
                                        name="ProfitLastYear"
                                        defValue={customerDetails.ProfitLastYear}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Passport Number"
                                        name="PassportNumber"
                                        defValue={customerDetails.PassportNumber}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col mb-3">
                                    <label className="form-label">Gender</label>
                                    <select name="Gender" className="form-control" value={gender} onChange={changeGender}>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                </div>
                                <div className="col">
                                    <label className="form-label">Title</label>
                                    <select name="Title" className="form-control" value={title} onChange={changeTitle}>
                                        <option value="">Choose a Title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Prof">Prof</option>
                                    </select>
                                </div>
                            </div>
                            <div className="row row-cols-1">
                                <div className="col">
                                    <FormInput
                                        label="Address"
                                        name="Address"
                                        defValue={customerDetails.Address}
                                    />
                                </div>
                            </div>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <FormInput
                                        label="Email CC Recipients"
                                        name="CcRecipients"
                                        placeHolder="Seperate Email address with comma(,)"
                                        onKeyDown={displayCCEmailsSeperatly}
                                        onBlur={displayCCEmailsSeperatly}
                                        autoComplete="off"
                                        defValue={customerDetails.CcRecipients}
                                    />
                                    <div id="cc-emails" className="mb-3"></div>
                                </div>
                            </div>
                            {/* Change Status */}
                            {customerDetails.Id ?
                                <div className="row row-cols-2">
                                    <div className="col mb- d-none">
                                        <label>Status</label>
                                        <select className="form-control" name="Status" defaultValue={customerDetails.Status}>
                                            {statuses.map((item, index) => (
                                                <option value={item.Number} key={index}>{item.Title}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                                : ""}


                            {/* Submit button */}
                            {id ? (
                                <>
                                    <input type="hidden" name="Id" value={id} />
                                    <input type="hidden" name="Status" value={customerDetails.Status} />
                                    <button
                                        type="button"
                                        className="btn btn-success mx-1 mb-1"
                                        onClick={updateCustomer}
                                    >
                                        <i className="fa fa-edit mx-1"></i>
                                        Update <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                                    </button>
                                </>
                            ) : (
                                <></>
                            )}
                        </form>
                        <div id="success-message-container" className="d-none">
                            <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Customer Updated Successfully.</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    // ------------------------------------------

    async function fetchCustomerDetails(cid) {
        if (cid) {
            const loadingContainer = document.getElementById("loadingContainer");
            try {
                loadingContainer.classList.remove("d-none");
                await axiosConfig
                    .get(`/customer/${cid}`)
                    .then((result) => {
                        if (result.data.status === "success") setCustomerDetails(result.data.data)
                        else toast.error(result.data.message);
                    })
                    .catch((error) => { });
            } catch {
                toast.error(PublicConsts.TryCatch_ErrorMessage);
            }
            loadingContainer.classList.add("d-none");
        }

    }
    async function updateCustomer() {
        try {
            const form = document.getElementById("formData");
            const formData = new FormData(form);
            if (!formData.has('Status')) {
                formData.set('Status', 'False');
            }
            const formDetails = Object.fromEntries(formData.entries());
            //-----------------------------------
            let result = await axiosConfig.put(`/customer/`, formDetails);
            if (result.data.status === "success") {
                toast.success(result.data.message);
                fetchCustomerDetails(id);
                getCustomerDetails();
            }
            else toast.error(result.data.message);
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
    }
    async function getStatuses() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/customer_status/`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setStatuses(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    function changeGender(e) {
        setGender(e.target.value);
    }
    function changeTitle(e) {
        setTitle(e.target.value);
    }
    function displayCCEmailsSeperatly(e) {
        const ccInput = e.target;

        if (e.key === ' ') {
            e.preventDefault();
        }
        else {

            if (e.key === ',' || e.type === 'blur') {
                let emails = ccInput.value.trim().split(',').filter(email => email.trim() !== "");
                const displayEmailContainer = document.getElementById('cc-emails');
                // reset container
                displayEmailContainer.innerHTML = ""
                // Convert single email string to an array with one element
                if (!Array.isArray(emails)) emails = [emails];

                if (emails) {
                    emails.forEach(function (item) {
                        const parentSpan = document.createElement('span');
                        parentSpan.className = 'badge bg-primary position-relative mx-1';
                        parentSpan.textContent = item;
                        displayEmailContainer.appendChild(parentSpan);

                    })
                }
            }
        }
    }
};

export default UpdateCustomerDetailsModal;