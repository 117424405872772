import { Routes, Route, useLocation } from "react-router-dom";
import Routing from "./Routing";
import PanelLayout from "./components/layout/panelLayout";
import Dashboard from "./components/pages/panel/dashboard";
import NotFound from "./components/pages/notFound";
import Error403 from "./components/pages/error403";
import Login from "./components/pages/login";
import Portal from "./components/pages/portal";
import SetupPassword from "./components/pages/setupPassword";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const App = () => {
    const location = useLocation();
    const currentPath = location.pathname;
    //const hostname = window.location.hostname;
    const token = localStorage.getItem("token");
    const roles = localStorage.getItem("roles");



    ////Prevent Explore the crm using'Portal' subdomain. this subdomain is only for customers to check their portal
    ////=============================================================================================
    //if (hostname.startsWith('portal') && !currentPath.match(/^\/Customer\/\w+$/)) {
    //    console.log(hostname + "---" + currentPath)
    //    return <NotFound />;
    //}


    //Return to Login if Token is Null
    //=============================================================================================
    if (
        (token === null || roles === null) &&
        (currentPath !== "/Error403" && currentPath.startsWith('/SetupPassword') === false)) {
        return <Login returnUrl={currentPath} />;
    }
    //Prevent Showing Account pages if Token is not null
    //=============================================================================================
    else if (
        token !== null && roles !== null &&
        (currentPath === "/Login" || currentPath === "/")
    ) {
        //Go to Panel
        //---------------------------------------------------------------------------------------------
        return <PanelLayout mainContent={<Dashboard />} />;
    }

    //Main Routing Proccess
    //=============================================================================================
    else {
        return (
            <>
                {/* Toast Message Container */}
                <ToastContainer />

                {/* Routing
      --------------------------------------------- */}
                {!currentPath.startsWith("/Panel") ? (
                    <Routes>
                        <Route path="/NotFound" exact Component={NotFound} />
                        <Route path="/Error403" exact Component={Error403} />
                        <Route path="/Customer/:id" exact Component={Portal} />
                        <Route path="/SetupPassword/:id" exact Component={SetupPassword} />
                        <Route path="/Login" exact Component={Login} />
                        <Route path="/" exact Component={Login} />
                    </Routes>
                ) :

                    //Go to Panel
                    //---------------------------------------------------------------------------------------------
                    currentPath.startsWith("/Panel") ? (
                        <PanelLayout mainContent={<Routing />} />
                    ) : (
                        <Route path="/" exact Component={Login} />
                    )}
            </>
        );
    }
};

export default App;
