import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PublicConsts } from "../../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../../axiosConfig";
import FormTextArea from "../../htmlForm/formTextArea";


const Ticket = () => {
    const [ticket, setTicket] = useState([]);

    useEffect(() => {
        getTicketDetails();
    }, [])

    return (

        <div className="pt-3">
            <div id="loadingContainer" className="alert alert-danger d-none">
                Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
            </div>
            <div className="container">
                <div className="main-body">
                    <div className="sticky-top bg-white p-3 mb-2 rounded border">
                        <NavLink to="/Panel/Tickets" className="btn btn-sm btn-primary mb-3"><i className="fa fa-arrow-left mx-1"></i>Ticket List</NavLink>
                        <button className="btn btn-sm btn-danger float-end" data-bs-toggle="modal" data-bs-target="#replayModal"><i className="fa fa-edit mx-1"></i>New Replay</button>
                    </div>

                    {/* Ticket Details */}
                    <div className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6 className="d-flex align-items-center mb-4 text-primary fw-bold">Ticket Details</h6>
                                    <div className="p-3">
                                        {ticket ?
                                            <>
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-user-circle text-muted mx-1"></i>Full Name</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticket.FullName}</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Email</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticket.Email}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Create Date</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticket.CreateDate? ticket.CreateDate.replace("GMT", ""):""}</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Update Date</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticket.UpdateDate ? ticket.UpdateDate.replace("GMT", "") : ""}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-tags text-muted mx-1"></i>Status</h6>
                                                        <div className="text-muted mt-3 ms-5">
                                                            {ticket.Status === 0 ?
                                                                <span className="badge bg-warning">Waiting</span> : ticket.Status === 1 ?
                                                                    <span className="badge bg-success">Proccessing</span> : ticket.Status === 2 ?
                                                                        <span className="badge bg-secondary">Closed</span> : ""
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-tags text-muted mx-1"></i>Change Status</h6>
                                                        <div className="text-muted mt-3 ms-5">
                                                            <select className="form-control" onChange={changeTicketStatus}>
                                                                <option value="">Select status</option>
                                                                <option value="0">Waiting</option>
                                                                <option value="1">Proccessing</option>
                                                                <option value="2">Closed</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <h6><i className="fa fa-edit text-muted mx-1"></i>Subject</h6>
                                                    <div className="text-muted mt-3 ms-5">{ticket.Subject}</div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <h6><i className="fa fa-paragraph text-muted mx-1"></i>Message</h6>
                                                    <div className="text-muted mt-3 ms-5">{ticket.Message}</div>
                                                </div>
                                            </>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Replies */}
                    <div className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6 className="d-flex align-items-center mb-3 text-primary fw-bold mb-2">Replies</h6>
                                    <div className="p-4 mt-3">
                                        {(ticket.Replies && ticket.Replies.length > 0 ?



                                            ticket.Replies.map((item, index) => (

                                                (item.IsAdminReplay === "True" ?
                                                    //Message on Left 
                                                    <>
                                                        <div key={index} className="comment row">
                                                            <div className="comment-author-ava col-1">
                                                                <img src="https://www.iconpacks.net/icons/2/free-chat-support-icon-1721-thumb.png" alt="Avatar" className="rounded-circle bg-info" width="50" />
                                                            </div>
                                                            <div className="comment-body col-11 border rounded p-3 mb-2">
                                                                <p className="comment-text">{item.Message}</p>
                                                                <small className="comment-footer text-muted float-end">{item.CreateDate.split('.')[0]}</small>
                                                            </div>
                                                        </div>

                                                    </> :
                                                    //Message on Right 
                                                    <>
                                                        <div key={index} className="comment row">
                                                            <div className="comment-body col-11 border rounded p-3 mb-2">
                                                                <p className="comment-text text-end">{item.Message}</p>
                                                                <small className="comment-footer text-muted">{item.CreateDate.split('.')[0]}</small>
                                                            </div>
                                                            <div className="comment-author-ava col-1">
                                                                <img src="https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg" alt="Avatar" className="rounded-circle" width="50" />
                                                            </div>
                                                        </div>
                                                    </>
                                                )


                                            )) : "")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modals ------------------------- */}
            <div className="modal fade" id="replayModal" tabIndex="-1">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header py-2 bg-primary">
                            <h5 className="modal-title text-white">New Replay</h5>
                            <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body pt-1">
                            <form id="newReplayForm">
                                <FormTextArea name="Message" placeHolder="Message" rows="5"></FormTextArea>
                                <button type="button" className="btn btn-sm btn-success" onClick={newReplay}><i className="fa fa-save mx-1"></i>Save<i className="fa fa-spin fa-spinner mx-1 d-none"></i></button>
                            </form>
                            <div id="success-message-container-ticket" className="alert alert-success d-none">Ticket replay has been saved successfully</div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    );


    // ----------------------------------------------------------------

    async function getTicketDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        const urlArray = window.location.pathname.split("/");
        const id = urlArray[urlArray.length - 1];

        try {
            await axiosConfig
                .get(`/tickets/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setTicket(result.data.data);
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    async function newReplay(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");

        const urlArray = window.location.pathname.split("/");
        const tId = urlArray[urlArray.length - 1];

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("newReplayForm");
            const formData = new FormData(form);
            const formDetails = Object.fromEntries(formData.entries());
            formDetails["TicketId"] = tId;
            formDetails["IsAdminReplay"] = true;
            //-----------------------------------
            await axiosConfig
                .post(`/ticket_reply/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        form.classList.add("d-none");
                        document.getElementById("success-message-container-ticket").classList.remove("d-none");
                        getTicketDetails();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    async function changeTicketStatus(e) {
        const status = e.target.value
        if (!status) return null

        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        const urlArray = window.location.pathname.split("/");
        const id = urlArray[urlArray.length - 1];

        try {
            //-----------------------------------
            const data = {
                "ticketId": id,
                "status": status
            }
            //-----------------------------------
            await axiosConfig
                .put(`/tickets/change_status`, data)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        getTicketDetails();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }

        loadingContainer.classList.add("d-none");
    }
};

export default Ticket;