import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";

const InboxItemDetailsModal = ({ itemDetails }) => {

    return (

        <div className="modal fade" id="displayItemDetails" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Details</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div id="inboxItemdetails">
                            {(itemDetails ?
                                <table className="table">
                                    <tbody>
                                        {Object.entries(itemDetails).map(([key, value]) => (
                                            <tr key={key}>
                                                <td>{key.charAt(0).toUpperCase() + key.slice(1)}</td>
                                                <td>{value}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                
                                 : <div className="alert alert-warning">No Data Found</div>)}
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
    //-------------------------------------------------------
    
}

export default InboxItemDetailsModal;