import { useEffect, useState } from "react";
import { PublicConsts } from "../../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../../axiosConfig";
import { NavLink } from "react-router-dom";
import FirstCallRequestDocumentsModal from "../../modals/firstCall_RequestDocumentsModal";
import FirstCallQuestionsModal from "../../modals/firstCall_QuestionsModal";
import FirstCallNotEligibleEmailModal from "../../modals/firstCall_NotEligibleEmailModal";
import FirstCallChangeTagModal from "../../modals/firstCall_ChangeTagModal";
import InitialDocSendDDRModal from "../../modals/initialDoc_SendDDRModal";
import InitialDocChangeTagModal from "../../modals/initialDoc_ChangeTagModal";
import InitialDocNotEligibleEmailModal from "../../modals/initialDoc_NotEligibleEmailModal"
import FirstMeetingWriteSummaryModal from "../../modals/firstMeeting_WriteSummaryModal";
import FirstMeetingChangeTagModal from "../../modals/firstMeeting_ChangeTagModal";
//import EstimatorModal from "../../modals/estimatorModal";
//import EstimatorDisplayModal from "../../modals/estimatorDisplayModal";
import ContractSendContractModal from "../../modals/contract_SendContractModal";
import InvoiceChangeTagModal from "../../modals/invoice_ChangeTagModal";
import InvoiceSendReceiptEmailModal from "../../modals/invoice_SendReceiptEmailModal";
import InvoiceSendInvoiceEmailModal from "../../modals/invoice_SendInvoiceEmailModal"
import UpdateCustomerDetailsModal from "../../modals/updateCustomerDetailsModal";
import UploadProfilePictureModal from "../../modals/uploadProfilePictureModal";
import HelpModal from "../../modals/helpModal";
import SendCustomEmail from "../../modals/sendCustomEmail";
import ScheduleMeetingModal from "../../modals/scheduleMeetingModal";
import PaymentTermsModal from "../../modals/paymentTermsModal";
import CustomerActivitiesModal from "../../modals/customerActivitiesModal";


const Customer = () => {

    const [customerDetails, setCustomerDetails] = useState(null);
    const [customerStatusNumber, setCustomerStatusNumber] = useState(0);
    const [customerId, setCustomerId] = useState(null);
    const [tags, setTags] = useState([]);
    const [viewQuestionsReadOnly, setViewQuestionsReadOnly] = useState(false);
    const [viewSummaryReadOnly, setViewSummaryReadOnly] = useState(false);
    const [fullName, setFullName] = useState(null);
    // const [currentInvoiceAmounts, setCurrentInvoiceAmounts] = useState([]);
    const [currentInvoiceDetails, setCurrentInvoiceDetails] = useState(null);
    const [customerActivities, setCustomerActivities] = useState([]);

    useEffect(() => {
        getDetails();
        getTags();

    }, [customerStatusNumber]);

    useEffect(() => {
        if (customerDetails != null) {
            let title = ""
            if (customerDetails.Title) { title = customerDetails.Title }
            setFullName(title + " " + customerDetails.Name + " " + customerDetails.LastName)
        }

    }, [customerDetails]);


    //-------------------------------------------------------------------------------------------

    return (
        <div className="portal-container pt-3">
            <div id="loadingContainer" className="alert alert-danger d-none">
                Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
            </div>
            <div className="container">
                {customerDetails && customerDetails.IsDeleted ? <div className="alert alert-danger sticky-top"><i className="fa fa-info-circle"></i>The customer is deleted</div> : ""}
                <div className="main-body">
                    <NavLink to="/Panel/Customers" className="btn btn-sm btn-primary mb-3"><i className="fa fa-arrow-left mx-1"></i>Customer List</NavLink>

                    <div className="card p-2 mb-3">
                        <div className="card-header p-2">
                            <div className="row gap-2 gap-md-0">
                                <div className="col-12 col-md-5"><h4>Progress Step</h4></div>
                                <div className="col-12 col-md-6">
                                    <span className="text-primary fw-bold">Step {customerStatusNumber} - {(customerDetails ? customerDetails.StatusTitle : "")}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pb-0">
                            <div className="progress" style={{ height: '20px' }}>
                                <div
                                    className="progress-bar bg-success"
                                    role="progressbar"
                                    style={{ width: `${(customerStatusNumber / 6) * 100}%` }}
                                    aria-valuenow={customerStatusNumber}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                />
                            </div>
                            <p className="mt-2">{`${((customerStatusNumber / 6) * 100).toFixed()}% Complete`}</p>
                            <div className="row gap-2 bg-white p-3 mt-3 justify-content-between">
                                <div className="col-12 col-md-3">
                                    <button className={"btn btn-sm btn-danger" + (customerStatusNumber === 1 ? " disabled" : "")} onClick={() => updatCustomerStatus('pre')}>
                                        <i className="fa fa-arrow-left mx-1 py-2"></i>Previous Step
                                    </button>
                                </div>
                                <div className="col-12 col-md-8 text-end">
                                    {customerStatusNumber === 2 ? (
                                        <>
                                            <button className="btn btn-sm btn-success me-1"
                                                onClick={() => displayQuestionsAsReadOnly(false)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#questionsModal">
                                                <i className="fa fa-question-circle mx-1 py-2"></i>Questions
                                            </button>
                                            <button className="btn btn-sm btn-success me-2"
                                                onClick={() => displaySummaryAsReadOnly(false)}
                                                data-bs-toggle="modal"
                                                data-bs-target="#firstMeetingWriteSummaryModal">
                                                <i className="fa fa-edit mx-1 py-2"></i>Summary of Meeting
                                            </button>
                                        </>
                                    ) : ""}
                                    <button className={"btn btn-sm btn-primary" + (customerStatusNumber === 6 ? " disabled" : "")} onClick={() => updatCustomerStatus('next')}>
                                        <i className="fa fa-arrow-right mx-1"></i>Next Step
                                        <a className="btn btn-sm btn-info text-white ms-2" title="help" onClick={(e) => e.stopPropagation()}>
                                            <i className="fa fa-question-circle" data-bs-toggle="modal" data-bs-target="#helpModal"></i>
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card p-2 mb-3">
                        <div className="card-header p-2">
                            <h5>Actions of Step</h5>
                        </div>
                        <div className="card-body p-1">

                            <div className="row bg-white p-1 mt-1 justify-content-between">
                                {

                                    customerStatusNumber === 1 ? (
                                        <div className="col-5">
                                            <button
                                                type="button"
                                                className="btn btn-sm btn-warning mx-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#updateCustomerDetailsModal"
                                            >
                                                <i className="fa fa-edit mx-1"></i>
                                                Update Customer Details
                                            </button>
                                        </div>

                                    ) :
                                        //Step 2
                                        //-----------------------------------------------------------------------
                                        customerStatusNumber === 2 ? (
                                            <div className="row gap-2 gap-md-0">
                                                <div className="col">
                                                    <div className="card shadow-lg">
                                                        <div className="card-header p-2 bg-success">
                                                            <h5 className="text-white"><i className="fa fa-check mx-1"></i>Success Actions</h5>
                                                        </div>
                                                        <div className="card-body">

                                                            <div className="row gap-2 gap-md-0">
                                                                <div className="col-12">

                                                                    {customerDetails.Payments.length > 0 ?
                                                                        <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>Payment Terms has been generated.</div> :
                                                                        <button className="btn btn-sm btn-outline-primary me-1"
                                                                            data-bs-toggle="modal" data-bs-target="#paymentTermsModal">
                                                                            <i className="fa fa-file-export mx-1"></i>Generate Payment Terms
                                                                        </button>
                                                                    }

                                                                    {/*{customerDetails.HasEstimate ?*/}
                                                                    {/*    <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>Expansion Estimate has been sent. {customerDetails && customerDetails.IsEstimateApproved ? <span className="text-success">(Approved)</span> : <span className="text-danger">(Not Approved)</span>}</div> :*/}
                                                                    {/*    <button className="btn btn-sm btn-outline-primary me-1"*/}
                                                                    {/*        data-bs-toggle="modal" data-bs-target="#firstMeetingEstimatorModal">*/}
                                                                    {/*        <i className="fa fa-file-export mx-1"></i>Send Expansion Estimate*/}
                                                                    {/*    </button>*/}
                                                                    {/*}*/}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="card shadow-lg">
                                                        <div className="card-header p-2 bg-danger">
                                                            <h5 className="text-white"><i className="fa fa-times mx-1"></i>Fail Actions</h5>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="row gap-2 gap-md-0">
                                                                <div className="col-12 col-md-6">
                                                                    <button className="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#firstCallNotEligiblEmailModal">
                                                                        <i className="fa fa-paper-plane mx-1"></i>Send not eligible Email
                                                                    </button>
                                                                </div>
                                                                <div className="col-12 col-md-6">
                                                                    <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target="#firstCallChangeTagModal">
                                                                        <i className="fa fa-retweet mx-1"></i>Change customer's Tag
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                            //Step 3
                                            //-----------------------------------------------------------------------
                                            customerStatusNumber === 3 ? (
                                                <div className="row gap-2 gap-md-0">
                                                    <div className="col">
                                                        <div className="card shadow-lg">
                                                            <div className="card-header p-2 bg-success">
                                                                <h5 className="text-white"><i className="fa fa-check mx-1"></i>Success Actions</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                {!customerDetails.IsDocRequestSent ?
                                                                    <button className="btn btn-sm btn-outline-primary me-1" data-bs-toggle="modal" data-bs-target="#firstCallEligibleEmailModal">
                                                                        <i className="fa fa-paper-plane mx-1"></i>Email for asking required documents
                                                                    </button>
                                                                    :
                                                                    <>

                                                                        <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>The document request email has been sent.</div>
                                                                        {/* Check if required documents uploaded and accepted */}
                                                                        {(customerDetails && customerDetails.Documents.length === 0) ||
                                                                            customerDetails.Documents.filter(item => (item.Type === "passport" || item.Type === "address") && item.Status === '0').length === 1 ?
                                                                            <span className="text-danger"><i className="fa fa-times mx-1"></i>The required documents were not uploaded</span> :
                                                                            <>
                                                                                <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>Documents uploaded and Accepted</div>

                                                                                {/* Check if DDR Email has been sent */}
                                                                                {customerDetails.IsDdrSent ?
                                                                                    <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>The DDR file has been sent</div> :
                                                                                    <button className="btn btn-sm btn-outline-primary mt-2" data-bs-toggle="modal" data-bs-target="#sendDDRModal">
                                                                                        <i className="fa fa-file-export mx-1"></i>Send DDR
                                                                                    </button>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col">
                                                        <div className="card shadow-lg">
                                                            <div className="card-header p-2 bg-danger">
                                                                <h5 className="text-white"><i className="fa fa-times mx-1"></i>Fail Actions</h5>
                                                            </div>
                                                            <div className="card-body">
                                                                <div className="row gap-2 gap-md-0">
                                                                    <div className="col-12 col-md-6">
                                                                        <button className="btn btn-sm btn-outline-danger" data-bs-toggle="modal" data-bs-target="#initialDocNotEligibleEmailModal">
                                                                            <i className="fa fa-paper-plane mx-1"></i>Send not eligible Email
                                                                        </button>
                                                                    </div>
                                                                    <div className="col-12 col-md-6">
                                                                        <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target="#initialDocChangeTagModal">
                                                                            <i className="fa fa-retweet mx-1"></i>Change customer's Tag
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) :
                                                //Step 4
                                                //-----------------------------------------------------------------------
                                                customerStatusNumber === 4 ? (
                                                    <>
                                                        <div className="row">
                                                            {(customerDetails && customerDetails.Payments.length > 0 ?
                                                                // if payment terms added already, display send contract action
                                                                <>
                                                                    <div className="col-6">
                                                                        <div className="text-primary"><i className="fa fa-check-circle mx-1"></i>Payment Term has been generated.</div>
                                                                        {customerDetails.IsContractSent ?
                                                                            <div className="text-primary mx-1"><i className="fa fa-check-circle mx-1"></i>The contract has been sent</div>
                                                                            :
                                                                            <button className="btn btn-sm btn-outline-primary mt-2" data-bs-toggle="modal" data-bs-target="#sendContractModal">
                                                                                <i className="fa fa-file-export mx-1"></i>
                                                                                {customerDetails.IsContractSent ? "Send again" : "Send Contract"}
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </> :
                                                                <div className="col-6">
                                                                    <button className="btn btn-sm btn-outline-primary me-1"
                                                                        data-bs-toggle="modal" data-bs-target="#paymentTermsModal">
                                                                        <i className="fa fa-file-export mx-1"></i>Generate Payment Terms
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </>
                                                ) :
                                                    //Step 5
                                                    //-----------------------------------------------------------------------
                                                    customerStatusNumber === 5 ? (
                                                        <div className="row gap-2 gap-md-0">
                                                            <div className="col">
                                                                <div className="card shadow-lg">
                                                                    <div className="card-header p-2 bg-success">
                                                                        <h5 className="text-white"><i className="fa fa-check mx-1"></i>Success Actions</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <div className="row gap-2 gap-md-0">
                                                                            {customerDetails && customerDetails.PaymentMethod ? (
                                                                                <>
                                                                                    {/* Check if proof of payment is uploaded */}
                                                                                    {customerDetails && customerDetails.Documents.length > 0 && customerDetails.Documents.filter(item => item.Type === "payment")[0] ? (
                                                                                        <>
                                                                                            {/* Check if proof of payment Accepted */}
                                                                                            {customerDetails.Documents.filter(item => item.Type === "payment")[0].Status === '1' ? (
                                                                                                <>
                                                                                                    {/* Check if Receipt sent */}
                                                                                                    {customerDetails.Payments.filter(item => item.IsReceiptSent === 'True')[0] ? (
                                                                                                        <>
                                                                                                            <span className="text-primary mx-1 col-12">
                                                                                                                <i className="fa fa-check-circle mx-1"></i>The receipt of the payment has been sent
                                                                                                            </span>
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <span className="text-danger"><i className="fa fa-times mx-1"></i>The receipt of the payment has not been sent</span>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <span className="text-danger"><i className="fa fa-times mx-1"></i>Proof of payment was not Accepted</span>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <span className="text-danger"><i className="fa fa-times mx-1"></i>Proof of payment was not uploaded</span>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <span className="text-danger"><i className="fa fa-times mx-1"></i>No payment method selected by the customer</span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="card shadow-lg">
                                                                    <div className="card-header p-2 bg-danger">
                                                                        <h5 className="text-white"><i className="fa fa-times mx-1"></i>Fail Actions</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <button className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target="#invoiceChangeTagModal">
                                                                            <i className="fa fa-retweet mx-1"></i>Set As Pending Payment
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) :
                                                        //Step 6
                                                        //-----------------------------------------------------------------------
                                                        customerStatusNumber === 6 ? (
                                                            <div>
                                                                {customerDetails ? (
                                                                    // Check if Receipt sent
                                                                    customerDetails.Payments.filter(item => item.IsReceiptSent === 'False')[0] ? (
                                                                        <span className="text-danger"><i className="fa fa-times mx-1"></i>The proof of payment has not been uploaded or the receipt of it has not been sent</span>
                                                                    ) : (
                                                                        customerDetails.IsWelcomeEmailSent ?
                                                                            <span className="text-success"><i className="fa fa-check-circle mx-1"></i>Welcome email has been sent</span> :
                                                                            <div className="col-12 col-md-6">
                                                                                <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal" data-bs-target="#invoiceSendWelcomeEmailModal">
                                                                                    <i className="fa fa-paper-plane mx-1"></i>Send Welcome Email
                                                                                </button>
                                                                            </div>
                                                                    )
                                                                ) : (
                                                                    <span className="text-danger"><i className="fa fa-times mx-1"></i>The receipt of the payment has not been sent</span>
                                                                )}
                                                            </div>
                                                        ) : ""
                                }
                            </div>
                        </div>
                    </div>

                    {/* Information Summary */}
                    <section className="row gutters-sm">
                        <div className="col-md-4 mb-3">
                            <div className="card h-100">

                                <div className="card-header p-2">
                                    <button type="button" className="btn btn-sm btn-outline-danger" title="Customer Activities" data-bs-toggle="modal" data-bs-target="#customerActivitiesModal">
                                        <i className="fa fa-history" ></i>
                                    </button>
                                </div>
                                <div className="card-body mt-5">

                                    <div className="d-flex flex-column align-items-center text-center">
                                        {customerDetails !== null && customerDetails.ProfilePicture ?
                                            <img src={PublicConsts.user_portal_url + `${customerDetails.UrnId}/${customerDetails.ProfilePicture}`} alt="Avatar" className="rounded-circle" width="150" />
                                            :
                                            <img src="/assets/img/profile-picture.png" alt="Avatar" className="rounded-circle" width="150" />
                                        }
                                        <div className="mt-5">
                                            <h4>{customerDetails ? customerDetails.Name : "Unknow"}</h4>
                                            <p className="text-muted mb-1">Customer</p>
                                        </div>
                                        <div className="mt-5">
                                            <button type="button" className="btn btn-sm btn-primary" data-bs-toggle="modal" data-bs-target="#uploadProfilePictureModal">
                                                <i className="fa fa-upload mx-1"></i>
                                                Upload picture
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card mb-3">
                                <div className="card-header p-1 text-end">
                                    <button type="button" className="btn btn-sm btn-outline-primary me-1" data-bs-toggle="modal" data-bs-target="#scheduleMeetingModal">
                                        <i className="fa fa-handshake mx-1"></i>
                                        Schedule a Meeting
                                    </button>
                                    <button type="button" className="btn btn-sm btn-outline-warning" data-bs-toggle="modal" data-bs-target="#sendCustomEmailModal">
                                        <i className="fa fa-envelope mx-1"></i>
                                        Send custom email
                                    </button>
                                </div>
                                <div className="card-body px-4">
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-user-circle text-muted mx-1"></i>Full Name</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {fullName && customerDetails ? fullName : "Unknow"}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-calendar text-muted mx-1"></i>Email</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {customerDetails ? customerDetails.Email : "Unknow"}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-calendar text-muted mx-1"></i>Mobile</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            {customerDetails ? customerDetails.Mobile : "Unknow"}
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-tags text-muted mx-1"></i>Status</h6>
                                        </div>
                                        <div className="col-sm-9 text-end">
                                            <span className="badge bg-success">{customerDetails ? customerDetails.StatusTitle : "Unknow"}</span>

                                        </div>
                                    </div>
                                    <hr />
                                    {customerDetails && customerDetails.PaymentMethod ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0"><i className="fa fa-credit-card text-muted mx-1"></i>Payment Method</h6>
                                                </div>
                                                <div className="col-sm-8 text-muted text-end">
                                                    <span className="fw-bold">{customerDetails.PaymentMethod.replace("Invoice ", "")}</span>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <h6 className="mb-0"><i className="fa fa-coins text-muted mx-1"></i>Payment Currency</h6>
                                                </div>
                                                <div className="col-sm-8 text-muted text-end">
                                                    <span className="fw-bold">{customerDetails.PaymentCurrency ? customerDetails.PaymentCurrency.replace("Invoice ", "") : ""}</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                        : ""}
                                    {customerDetails && customerDetails.TagTitle ?
                                        <>
                                            <div className="row">
                                                <div className="col-sm-3">
                                                    <h6 className="mb-0"><i className="fa fa-tags text-muted mx-1"></i>Tagged As</h6>
                                                </div>
                                                <div className="col-sm-9 text-end">
                                                    <span className="badge bg-danger">{customerDetails.TagTitle}</span>

                                                </div>
                                            </div>
                                            <hr />
                                        </>
                                        : ""}
                                    <div className="row">
                                        <div className="col-sm-3">
                                            <h6 className="mb-0"><i className="fa fa-id-card text-muted mx-1"></i>URN Id</h6>
                                        </div>
                                        <div className="col-sm-9 text-muted text-end">
                                            <button className="btn btn-sm btn-outline-success mx-1" onClick={() => copyToClipboard(customerDetails.UrnId, "link")}>
                                                <i className="fa fa-link mx-1"></i>
                                                Copy Portal URL
                                            </button>
                                            <button className="btn btn-sm btn-outline-success" onClick={() => copyToClipboard(customerDetails.UrnId, "urn")}>
                                                <i className="fa fa-id-card mx-1"></i>
                                                Copy URN ID
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Information Details */}
                    <section className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-header row p-2">
                                    <div className="col-3">
                                        <h6 className="d-flex align-items-center mb-4 text-primary fw-bold">Information</h6>
                                    </div>
                                    <div className="col-9 text-end">
                                        {/*{customerDetails && customerDetails.HasEstimate ?*/}
                                        {/*    <button*/}
                                        {/*        type="button"*/}
                                        {/*        className="btn btn-sm btn-outline-danger mx-1"*/}
                                        {/*        data-bs-toggle="modal" data-bs-target="#estimateDisplayModal"*/}
                                        {/*    >*/}
                                        {/*        <i className="fa fa-eye mx-1"></i>*/}
                                        {/*        Estimate Details*/}
                                        {/*    </button>*/}
                                        {/*    : ""}*/}
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-outline-warning mx-1"
                                            data-bs-toggle="modal"
                                            data-bs-target="#updateCustomerDetailsModal"
                                        >
                                            <i className="fa fa-edit mx-1"></i>
                                            Update Details
                                        </button>
                                        {customerStatusNumber > 1 ? (
                                            customerDetails.HasQuestions ?
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-outline-primary mx-1"
                                                    onClick={() => displayQuestionsAsReadOnly(true)}
                                                    data-bs-toggle="modal" data-bs-target="#questionsModal"
                                                >
                                                    <i className="fa fa-eye mx-1"></i>
                                                    Questions
                                                </button> : ""
                                        ) : ""}
                                        {customerStatusNumber > 3 ? (
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-outline-success mx-1"
                                                    onClick={() => displaySummaryAsReadOnly(false)}
                                                    data-bs-toggle="modal" data-bs-target="#firstMeetingWriteSummaryModal"
                                                >
                                                    <i className="fa fa-eye mx-1"></i>
                                                    Meeting Summary
                                                </button>
                                            </>
                                        ) : ""}
                                    </div>
                                </div>
                                <div className="card-body">
                                    {customerDetails ?
                                        <div className="p-3">
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Name</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Name}</h5>
                                                </div>
                                                <div className="col">
                                                    <h6 className="text-muted">Last Name</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.LastName}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Email</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Email}</h5>
                                                </div>
                                                <div className="col">
                                                    <h6 className="text-muted">Mobile</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Mobile}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Nationality</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Nationality}</h5>
                                                </div>
                                                <div className="col">
                                                    <h6 className="text-muted">Passport Number</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.PassportNumber}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Company Name</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.CompanyName}</h5>
                                                </div>
                                                <div className="col">
                                                    <h6 className="text-muted">Occupation</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Occupation}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Revenue (Last Year)</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.RevenueLastYear}</h5>
                                                </div>
                                                <div className="col">
                                                    <h6 className="text-muted">Profit (Last Year)</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.ProfitLastYear}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Source Of The Lead</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.LeadSource}</h5>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row">
                                                <div className="col text-muted">
                                                    <h6 className="text-muted">Address</h6>
                                                    <h5 className="ms-4 mt-2">{customerDetails.Address}</h5>
                                                </div>
                                            </div>

                                        </div>
                                        : ""}
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Documents */}
                    <section className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6 className="d-flex align-items-center mb-3 text-primary fw-bold">Documents</h6>
                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Type</th>
                                                    <th>Name</th>
                                                    <th>File</th>
                                                    <th>Date</th>
                                                    <th>Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    (customerDetails && customerDetails.Documents.length > 0 ?

                                                        customerDetails.Documents.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.Type}</td>
                                                                <td>{item.Title}</td>
                                                                <td><a target="_blank" rel="noreferrer" className="text-decoration-none" href={PublicConsts.view_docs_base_url + `${customerDetails.UrnId}/${item.FileName}`}>View File</a></td>
                                                                <td>{item.CreateDate.split(".")[0]}</td>
                                                                <td className="status">
                                                                    {item.Title !== 'Initial Agreement' && item.Type !== 'DDR' ?
                                                                        <>
                                                                            {
                                                                                item.Status === '-1' ?
                                                                                    <span className="fail">Rejected</span> : item.Status === '0' ?
                                                                                        <span className="waiting">Waiting</span> : item.Status === '1' ?
                                                                                            <span className="success">Accepted</span> : ""
                                                                            }
                                                                        </>
                                                                        :
                                                                        <span className="success">Sent</span>
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {item.Title !== 'Initial Agreement' && item.Type !== 'DDR' ?
                                                                        <>
                                                                            {
                                                                                item.Status === '0' || item.Status === '-1' ?
                                                                                    <button type="button" className="btn btn-sm btn-success mx-1"
                                                                                        onClick={(event) => window.confirm("Accept the document?") ? changeDocumentStatus(item.Id, 1, event.currentTarget) : ""}>
                                                                                        Accept
                                                                                        <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                                                                                    </button>
                                                                                    : ""
                                                                            }
                                                                            {item.Status === '0' || item.Status === '1' ?
                                                                                <button type="button" className="btn btn-sm btn-danger"
                                                                                    onClick={(event) => window.confirm("Reject the document? (An Email will be sent to the customer)") ? changeDocumentStatus(item.Id, -1, event.currentTarget) : ""}>
                                                                                    Reject
                                                                                    <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                                                                                </button>
                                                                                : ""}
                                                                        </>
                                                                        : "-"}
                                                                </td>
                                                            </tr>
                                                        )) : <tr><td colSpan="6"><div className="alert alert-warning">No File Uploaded</div></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Payment Terms */}
                    <section className="row gutters-sm">
                        <div className="col-12 mb-3">
                            <div className="card h-100">
                                <div className="card-body">
                                    <h6 className="d-flex align-items-center mb-3 text-primary fw-bold">Payment Terms</h6>
                                    <div className="table-responsive">
                                        <table className="table text-center">
                                            <thead>
                                                <tr>
                                                    <th>Invoice Number</th>
                                                    <th>Amount</th>
                                                    <th>Pay Date</th>
                                                    <th>Update Date</th>
                                                    <th>Pay Status</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(customerDetails && customerDetails.Payments.length > 0 ?

                                                    customerDetails.Payments.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.InvoiceNumber}</td>
                                                            <td>{item.Amount}</td>
                                                            <td>{item.PayDate}</td>
                                                            <td>{item.UpdateDate.split(".")[0]}</td>
                                                            <td className="status">
                                                                {item.IsPaid === 'False' ?
                                                                    <span className="fail">Not Paid</span> : item.IsPaid === 'True' ?
                                                                        <span className="success">Paid</span> : ""
                                                                }
                                                            </td>
                                                            <td>








                                                                {customerStatusNumber > 4 ?
                                                                    customerDetails.PaymentMethod ?
                                                                        item.IsPaid === 'False' ?
                                                                            item.IsInvoiceSent === 'True' ?


                                                                                <button type="button" className="btn btn-sm btn-outline-primary mx-1" data-bs-toggle="modal" data-bs-target="#invoiceSendInvoiceEmailModal" onClick={() => prepareInvoiceDetails(item.Amount, item.InvoiceNumber, index)}><i className="fa fa-file-invoice mx-1"></i>test exchange</button> :

                                                                                //<span className="text-primary">Invoice has been sent</span> :
                                                                                index === 0 ?
                                                                                    <button type="button" className="btn btn-sm btn-outline-primary mx-1" data-bs-toggle="modal" data-bs-target="#invoiceSendInvoiceEmailModal" onClick={() => prepareInvoiceDetails(item.Amount, item.InvoiceNumber, index)}><i className="fa fa-file-invoice mx-1"></i>Send Invoice</button> :
                                                                                    customerDetails.Payments[index - 1].IsReceiptSent === 'True' ?
                                                                                        <button type="button" className="btn btn-sm btn-outline-primary mx-1" data-bs-toggle="modal" data-bs-target="#invoiceSendInvoiceEmailModal" onClick={() => prepareInvoiceDetails(item.Amount, item.InvoiceNumber, index)}><i className="fa fa-file-invoice mx-1"></i>Send Invoice</button> :
                                                                                        <span className="text-warning">Waiting for previous Invoice</span>
                                                                            :
                                                                            item.IsPaid === 'True' && item.IsReceiptSent === "True" ?
                                                                                <span className="text-success">Receipt has been sent</span> :
                                                                                <button type="button" className="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#invoiceSendReceiptEmailModal" onClick={() => prepareInvoiceDetails(item.Amount, item.InvoiceNumber)}><i className="fa fa-receipt mx-1"></i>Send Receipt</button>
                                                                        : <span className="text-primary">No payment method selected</span>
                                                                    : <span className="text-primary">Go to the next step</span>}
                                                            </td>
                                                        </tr>
                                                    )) : <tr><td colSpan="6"><div className="alert alert-warning">No Record Found</div></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            {/* Modals---------------------------------------------------------- */}
            {customerDetails ?
                <>
                    <FirstCallQuestionsModal id={customerId} isReadOnly={viewQuestionsReadOnly} />
                    {fullName ? <FirstCallRequestDocumentsModal customerId={customerId} customerEmail={customerDetails.Email} UrnId={customerDetails.UrnId} getCustomerDetails={getDetails} fullName={fullName} /> : ""}
                    <FirstCallNotEligibleEmailModal customerId={customerId} customerEmail={customerDetails ? customerDetails.Email : ""} />
                    <FirstCallChangeTagModal onConfirm={updateCustomerTag} tags={tags} />
                    <InitialDocChangeTagModal onConfirm={updateCustomerTag} tags={tags} />
                    <InitialDocNotEligibleEmailModal customerId={customerId} customerEmail={customerDetails.Email} />
                    {fullName ? <InitialDocSendDDRModal customerId={customerId} customerDetails={customerDetails} fullName={fullName} UrnId={customerDetails.UrnId} getCustomerDetails={getDetails} /> : ""}
                    <FirstMeetingWriteSummaryModal summaryText={customerDetails ? customerDetails.Summary : ""} isReadOnly={viewSummaryReadOnly} />
                    <FirstMeetingChangeTagModal onConfirm={updateCustomerTag} tags={tags} />
                    {/*<EstimatorModal customerId={customerId} customerEmail={customerDetails.Email} fullName={fullName} address={customerDetails.Address} company={customerDetails.CompanyName} urn={customerDetails.UrnId} getCustomerDetails={getDetails} />
                    //<EstimatorDisplayModal customerId={customerId} isApproved={customerDetails.IsEstimateApproved} />*/}
                    <PaymentTermsModal customerId={customerId} customerEmail={customerDetails.Email} fullName={fullName} address={customerDetails.Address} company={customerDetails.CompanyName} urn={customerDetails.UrnId} getCustomerDetails={getDetails} />
                    {fullName ? <ContractSendContractModal customerId={customerId} customerEmail={customerDetails.Email} fullName={fullName} passport={customerDetails.PassportNumber} urn={customerDetails.UrnId} country={customerDetails.Country} address={customerDetails.Address} nationality={customerDetails.Nationality} payments={customerDetails.Payments} getCustomerDetails={getDetails} /> : ""}
                    <InvoiceChangeTagModal onConfirm={updateCustomerTag} tags={tags} />
                    {customerDetails && currentInvoiceDetails ? <InvoiceSendReceiptEmailModal customerId={customerId} invoiceDetails={currentInvoiceDetails} getCustomerDetails={getDetails} /> : ""}
                    {customerDetails && currentInvoiceDetails ? <InvoiceSendInvoiceEmailModal customerId={customerId} invoiceDetails={currentInvoiceDetails} getCustomerDetails={getDetails} /> : ""}
                    <UpdateCustomerDetailsModal id={customerId} getCustomerDetails={getDetails} />
                    <UploadProfilePictureModal customerId={customerId} getCustomerDetails={getDetails} />
                    <HelpModal stepNumber={customerStatusNumber} />
                    <SendCustomEmail customerId={customerId} customerEmail={customerDetails.Email} />
                    {fullName ? <ScheduleMeetingModal customerId={customerId} customerEmail={customerDetails.Email} fullName={fullName} /> : ""}
                    <CustomerActivitiesModal activities={customerActivities} />
                </>
                : ""}
        </div>
    );
    async function getDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        const urlArray = window.location.pathname.split("/");
        const customerId = urlArray[urlArray.length - 1];

        if (customerId !== "") {
            try {
                setCustomerId(customerId);

                await axiosConfig
                    .get(`/customer/${customerId}`)
                    .then((result) => {
                        if (result.data.status === "success") {
                            setCustomerDetails(result.data.data);
                            setCustomerStatusNumber(result.data.data.Status);
                            setCustomerActivities(result.data.data.Activities);
                        } else {
                            toast.error(PublicConsts.Result_NotFound)
                        }
                    })
                    .catch((error) => {
                        console.log(error)
                    });
            } catch (error) {
                console.log(error)
            }
        }
        else {
            toast.error(PublicConsts.Result_NotFound)
        }

        loadingContainer.classList.add("d-none");
    }
    async function getTags() {

        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/customer_tags/pid/${customerStatusNumber}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setTags(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function updatCustomerStatus(type) {

        let statusValue = customerStatusNumber;
        if (type === 'pre') statusValue -= 1;
        else if (type === 'next') statusValue += 1

        const urlArray = window.location.pathname.split("/");
        const cId = urlArray[urlArray.length - 1];


        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {

            const data = {
                "cid": cId,
                "value": statusValue
            }

            await axiosConfig
                .put(`/customer_status/`, data)
                .then((result) => {

                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getDetails();
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");

    }
    async function updateCustomerTag(tagId) {

        const urlArray = window.location.pathname.split("/");
        const cId = urlArray[urlArray.length - 1];

        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {

            const data = {
                "cid": cId,
                "tagId": tagId
            }

            await axiosConfig
                .put(`/customer_tags/`, data)
                .then((result) => {

                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getDetails();
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");

    }
    async function changeDocumentStatus(docId, statusId, e) {

        const submitButton = e
        const spinner = submitButton.querySelector(".fa-spinner")

        submitButton.classList.add("disabled");
        spinner.classList.remove("d-none");

        try {

            const data = {
                "docId": docId,
                "statusId": statusId
            }

            await axiosConfig
                .put(`/customer/document_status/`, data)
                .then((result) => {

                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getDetails();
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none")

    }
    function displayQuestionsAsReadOnly(isReadOnly) {
        setViewQuestionsReadOnly(isReadOnly)
    }
    function displaySummaryAsReadOnly(isReadOnly) {
        setViewSummaryReadOnly(isReadOnly)
    }
    function copyToClipboard(id, type) {
        let textToCopy = "";
        let toastText = "";
        if (type === "urn") {
            textToCopy = id
            toastText = "URN ID Copied!"
        }
        else if (type === "link") {

            const hostnameParts = window.location.hostname.split('.');
            let domain = ""
            if (hostnameParts.length > 2) {
                domain = hostnameParts.slice(-2).join('.');
            } else {
                domain = hostnameParts;
            }

            textToCopy = PublicConsts.user_portal_url + id
            toastText = "Portal Url Copied!"
        }

        navigator.clipboard.writeText(textToCopy);
        toast.success(toastText)
    };
    async function prepareInvoiceDetails(gbpAmount, invoiceNumber, invoiceIndex) {

        // Prepare Amounts of the invoice
        //------------------------------------------------------------------------------------------------
        let targetCurrency = customerDetails.PaymentCurrency.replace("Invoice ", "").split(' ')[0];
        const apiUrl = `https://open.er-api.com/v6/latest/GBP`;
        const amounts = []

        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            // Extract exchange rate for target currency
            if (targetCurrency === 'Wise') targetCurrency = 'GBP'
            const exchangeRate = data.rates[targetCurrency];

            let totalAmount = Math.floor(parseFloat(gbpAmount) * parseFloat(exchangeRate));

            if (targetCurrency !== 'GBP') {
                const percentAmount = (totalAmount * 4) / 100
                totalAmount = totalAmount + percentAmount;
            }


            amounts["amount"] = totalAmount
            amounts["amountGbp"] = gbpAmount
            amounts["currency"] = targetCurrency

            // setCurrentInvoiceAmounts(amounts) 

        } catch (error) {
            console.error('Error on converting payment amount:', error);
            toast.error('Error on converting payment amount. Please try again before sending email ');
            return null;
        }

        // Prepare Other Details of the invoice
        //------------------------------------------------------------------------------------------------
        const payDateCondition = (item) => item.InvoiceNumber === invoiceNumber;
        const payment = customerDetails.Payments.find(payDateCondition);
        const payDate = payment.PayDate

        const invoiceDetails = []
        invoiceDetails["email"] = customerDetails.Email
        invoiceDetails["invoiceType"] = customerDetails.PaymentMethod
        invoiceDetails["fullName"] = fullName
        invoiceDetails["urn"] = customerDetails.UrnId
        invoiceDetails["address"] = customerDetails.Address
        invoiceDetails["invoiceNumber"] = invoiceNumber
        invoiceDetails["paymentCount"] = customerDetails.Payments.length
        invoiceDetails["amounts"] = amounts
        invoiceDetails["payDate"] = payDate
        invoiceDetails["index"] = invoiceIndex

        setCurrentInvoiceDetails(invoiceDetails)

    }
    //---------------------------------------------------
};

export default Customer;
