import { useEffect } from "react";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";

const SendCustomEmail = ({customerId, customerEmail})=>{

   return(

      <div className="modal fade" id="sendCustomEmailModal" tabIndex="-1">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header py-2 bg-primary">
            <h5 className="modal-title text-white">Send Custom Email</h5>
            <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form className="formContent">
              <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerEmail}</span></div>
              <div className="mb-3"><label className="text-muted form-label">Email Subject: </label><input className="form-control" id="subject" name="subject" /></div>
              <div className="mb-3"><label className="text-muted form-label">Email Message: </label><textarea className="form-control" id="message" name="message" ></textarea></div>
            </form>
            <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-success" onClick={sendCustomEmail}>
              <i className="fa fa-paper-plane mx-1"></i>
              Send
              <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
          </div>
        </div>
      </div>
    </div>
  
    )
    //---------------------------------------------------------------
    async function sendCustomEmail(e){

      const submitButton = e.target;
      const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
      submitButton.classList.add("disabled");
      submitButtonSpinner.classList.remove("d-none");

      // const mainContent = document.getElementsByClassName("formContent");
      // const successMessage = document.getElementsByClassName("successMessage");
      // const footer = document.getElementsByClassName("modal-footer");

      const subject = document.getElementById("subject").value
      const message = document.getElementById("message").value
      if(subject === "" || message === "")
      {
        toast.error("Please fill out all the inputs")
        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");
        return
      } 
        

        try {
          //---------------------------------------------
          const email_detail ={
            "cid": customerId,
            "recipient_email_address": customerEmail,
            "email_subject": subject,
            "email_message": message,
            "has_attachment": false,
            "target": "custom_email"
          }

          const formData = new FormData();
          formData.append('email_detail', JSON.stringify(email_detail));
          // ---------------------------------------------

          await axiosConfig
            .post(`/send_email`, formData)
            .then((result) => {
              if (result.data.status === "success") {
                toast.success(result.data.message)
                 //............
                 const modal = document.getElementById("sendCustomEmailModal")
                  modal.querySelector(".formContent").classList.add("d-none")
                  modal.querySelector(".successMessage").classList.remove("d-none")
                  modal.querySelector(".modal-footer").classList.add("d-none")
              }else {
                toast.error(result.data.message)
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      
        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default SendCustomEmail;