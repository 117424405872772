import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { PublicConsts } from "../../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../../axiosConfig";

const Tickets = () => {
    const [tickets, setTickest] = useState([]);

    useEffect(() => {
        getTickets();
    }, [])
    return (
        <div className="card shadow-lg mb-3">
            <div className="card-header">
                <span className="h5 text-primary">Tickets</span>
            </div>
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
                {/* <div className="alert alert-info">Comming Soon...</div> */}
                {/* <div>
            <div className="input-group mb-5">
                <input type="text" id="search" className="form-control" placeholder="Search Name or Email"/>
                <button type="button" className="btn btn-sm btn-success" onClick="{searchCustomers}">
                    <i className="bi bi-search mx-1"></i>
                    Search
                </button>
            </div>
        </div> */}
                <div className="table-responsive">
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Subject</th>
                                <th>Create Date</th>
                                <th>Update Date</th>
                                <th>Status</th>
                                <th>view & Replay</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(tickets && tickets.length > 0 ?

                                tickets.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Id}</td>
                                        <td>{item.Subject}</td>
                                        <td>{item.CreateDate ? item.CreateDate.replace("GMT", "") : ""}</td>
                                        <td>{item.UpdateDate ? item.UpdateDate.replace("GMT", "") : ""}</td>
                                        <td className="status">
                                            {item.Status === 0 ?
                                                <span className="waiting">Waiting</span> : item.Status === 1 ?
                                                    <span className="success">Proccessing</span> : item.Status === 2 ?
                                                        <span className="done">Closed</span> : ""
                                            }
                                        </td>
                                        <td>
                                            <NavLink className="nav-link" to={`/Panel/Ticket/${item.Id}`}>
                                                <i className="fa fa-eye  btn btn-sm btn-outline-primary"></i>
                                            </NavLink>
                                        </td>
                                    </tr>
                                )) : <tr><td colSpan="6"><div className="alert alert-warning">No Record Found</div></td></tr>)}
                        </tbody>

                    </table>
                </div>
                {/* <nav>
          <ul className="pagination">
           {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav> */}
            </div>
        </div>
    );
    //-------------------------------------------------------
    async function getTickets() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .get(`/tickets`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setTickest(result.data.data)
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    async function newTicket(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");

        const urlArray = window.location.pathname.split("/");
        const cId = urlArray[urlArray.length - 1];

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("newTicketForm");
            const formData = new FormData(form);
            const formDetails = Object.fromEntries(formData.entries());
            formDetails["Id"] = cId;
            //-----------------------------------
            await axiosConfig
                .post(`/tickets/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        form.classList.add("d-none");
                        document.getElementById("success-message-container-ticket").classList.remove("d-none");
                        getTickets();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    //-------------------------------------------------------
};

export default Tickets;
