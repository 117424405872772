import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";

const UploadProfilePictureModal = ({ customerId, getCustomerDetails }) => {
    return (
        <div className="modal fade" id="uploadProfilePictureModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Upload Profile Picture</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-light">
                        <div className="mb-3">
                            <div className="alert alert-info">
                                <div><i className="fa fa-info-circle mx-1"></i>Maximum file size: <span className="text-danger">2MB</span></div>
                                <div><i className="fa fa-info-circle mx-1"></i>Allowed file types: <span className="text-danger">jpg, jpeg, png, pdf</span></div>
                            </div>
                        </div>
                        <div className="card p-3 mb-3">
                            <div className="mb-5">
                                <label className="form-label text-primary">Choose profile picture</label>
                                <form id="form-profile-picture" className="input-group" encType="multipart/form-data">
                                    <input type="file" id="ProfilePicture" name="ProfilePicture" className="form-control form-control-sm" />
                                    <button type="button" className="btn btn-sm btn-success" id="btn-profilePicture" onClick={uploadDocument}><i className="fa fa-upload mx-1"></i>Upload<i className="fa fa-spin fa-spinner d-none mx-1"></i></button>
                                </form>
                                <div id="success-message-container-profile" className="alert alert-success d-none">Profile picture Uploaded Successfully</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    //---------------------------------------------------------

    async function uploadDocument(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");

        const fileInput = document.getElementById("ProfilePicture")
        if (fileInput.files.length === 0) return toast.error("Please Choose a file")

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById(`form-profile-picture`);
            const formData = new FormData(form);
            formData.append("cid", customerId)
            const formDetails = Object.fromEntries(formData.entries());
            //-----------------------------------
            await axiosConfig
                .post(`/customer/profile_picture/`, formDetails, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        fileInput.classList.add("d-none");
                        submitButton.classList.add("d-none");
                        document.getElementById(`success-message-container-profile`).classList.remove("d-none");
                        getCustomerDetails();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    console.log(error)
                    toast.error("Error on uploading document");
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
}
export default UploadProfilePictureModal;