import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";
import { NavLink } from "react-router-dom";


const Customers = () => {
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [validationErrors, setvalidationErrors] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [isAdmin, setIsAdmin] = useState(false);
    const [customerIdForActions, setCustomeIdForActions] = useState("");

    useEffect(() => {
        const roles = localStorage.getItem("roles");
        if (roles && roles.includes("1") && !isAdmin) setIsAdmin(true)
    }, [isAdmin])

    useEffect(() => {
        getCustomers();
        if (isAdmin) {
            getUsers();
        }

    }, [isAdmin, currentPage]);


    return (
        <div className="card shadow-lg mb-3">
            <div className="card-header">
                <span className="h5 text-primary">Customer List</span>
                <button
                    type="button"
                    className="btn btn-sm btn-primary float-end"
                    data-bs-toggle="modal"
                    data-bs-target="#addCustomerModal"
                >
                    <i className="fa fa-plus mx-1"></i>New Customer
                </button>
            </div>
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
                <div className="mb-5 row">
                    <label className="form-label col-12 col-md-3 align-self-end"><i className="fa fa-filter mx-1"></i>Filter By Status:</label>
                    <div className="col-12 col-md-3">
                        <select id="Status" className="form-control form-control-sm" onChange={() => searchCustomers("Status")}>
                            <option value="">Select Status</option>
                            <option value="1">New Lead</option>
                            <option value="2">First Call</option>
                            <option value="3">Initial Document</option>
                            <option value="4">First Meeting</option>
                            <option value="5">Contract</option>
                            <option value="6">Initial Payment</option>
                            <option value="7">Payment Follow Up</option>
                        </select>
                    </div>
                    <div className="col-2">
                        <button id="removeFilterBtn" className="btn btn-sm btn-outline-danger d-none" onClick={getCustomers}>Remove Filter</button>

                    </div>
                </div>
                {isAdmin ?
                    <>
                        <div className="mb-5 row">
                            <label className="form-label col-12 col-md-3 align-self-end"><i className="fa fa-filter mx-1"></i>Filter By Assigned To Me:</label>
                            <div className="col-12 col-md-2">
                                <span>
                                    <label id="myCustomersBtn" className="btn btn-sm btn-secondary" onClick={getAssignedToMeCustomers}>Show</label>
                                </span>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label className="form-label col-12 col-md-3 align-self-end"><i className="fa fa-user-slash mx-1"></i>Deleted Customers:</label>
                            <div className="col-12 col-md-2">
                                <span>
                                    <label id="deletedCustomersBtn" className="btn btn-sm btn-secondary" onClick={getDeletedCustomers}>Show</label>
                                </span>
                            </div>
                        </div>
                    </>
                    : ""
                }
                <div>
                    <div className="input-group mb-5">
                        <input type="text" id="search" className="form-control" placeholder="Search Customer" />
                        <button type="button" className="btn btn-sm btn-success" onClick={() => searchCustomers()}>
                            <i className="bi bi-search mx-1"></i>
                            Search
                        </button>
                    </div>

                </div>
                <div className="table-responsive customers-table-container">
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Email</th>
                                <th>Portal URL</th>
                                <th>Setup Password</th>
                                <th>Registerd Date</th>
                                <th>Status</th>
                                <th>Assigned To</th>
                                <th>Functions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {/* Get Data in Update Mode */}
                            {customers && customers.length !== 0 ? (
                                <>
                                    {customers.map((customer, index) => (
                                        <tr key={customer.Id} className={customer.IsDeleted ? "bg-soft-danger" : ""}>
                                            <td>{customer.Name + " " + customer.LastName}</td>
                                            <td>{customer.Email}</td>
                                            <td>
                                                <button className="btn btn-sm btn-outline-success" onClick={() => copyToClipboard(customer.UrnId, 'portalLink')}>
                                                    Copy URL
                                                </button>
                                            </td>
                                            <td>
                                                <button className="btn btn-sm btn-outline-success" onClick={() => copyToClipboard(customer.UrnId, 'setupPass')}>
                                                    Copy Link
                                                </button>
                                            </td>
                                            <td>{new Date(customer.RegisterDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                                            <td className="text-info">{customer.StatusTitle}</td>
                                            <td>{customer.AssignedUser}</td>
                                            <td>
                                                <NavLink
                                                    title="View Profile"
                                                    className="btn btn-sm btn-neutral mx-1 mb-1"
                                                    to={`/Panel/Customer/${customer.Id}`}>
                                                    <i className="fa fa-eye text-primary"></i>
                                                </NavLink>
                                                {isAdmin && !customer.IsDeleted ?
                                                    <>
                                                        <button type="button" className="btn btn-sm btn-neutral mx-1 mb-1" title="Delete Customer" data-bs-toggle="modal" data-bs-target="#deleteCustomerModal">
                                                            <i className="fa fa-trash text-danger" onClick={() => setCustomeIdForActions(customer.Id)}></i>
                                                        </button>
                                                        <button type="button" className="btn btn-sm btn-neutral mx-1 mb-1" title="Asign to User" data-bs-toggle="modal" data-bs-target="#assignCustomerModal">
                                                            <i className="fa fa-people-arrows text-warning" onClick={() => setCustomeIdForActions(customer.Id)}></i>
                                                        </button>
                                                    </>
                                                    : ""}
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="12">
                                        <div className="alert alert-danger">No Item Found</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <button className={currentPage > 1 ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage - 1)}>
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <>
                                {(index + 1 >= currentPage - 2) && (index + 1 <= currentPage + 2) ?
                                    <li className="page-item" key={index + 1}>
                                        <button className={`btn page-link ${currentPage === index + 1 ? "active" : ""}`} onClick={() => setCurrentPage(index + 1)}>
                                            {index + 1}
                                        </button>
                                    </li>
                                    : ""}
                            </>
                        ))}
                        <li className="page-item">
                            <button className={currentPage < totalPages ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage + 1)}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>

            {/* Modal------------------------------------------------------------------- */}
            <div className="modal fade" id="addCustomerModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header bg-primary py-2">
                            <h5 className="modal-title text-white">
                                Create New Customer
                            </h5>
                            <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#addCustomerModal"><i className="fa fa-close text-white"></i></button>
                        </div>
                        <div className="modal-body">
                            {validationErrors.length > 0 ? (
                                <div className="alert alert-danger">
                                    {validationErrors.map((item) => (
                                        <ul>
                                            <li>{item}</li>
                                        </ul>
                                    ))}
                                </div>
                            ) : (
                                ""
                            )}
                            <form id="formData">
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <FormInput
                                            label="Name"
                                            name="Name"
                                            required
                                        />
                                    </div>
                                    <div className="col">
                                        <FormInput
                                            label="Last Name"
                                            name="LastName"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <FormInput
                                            type="email"
                                            label="Email"
                                            name="Email"
                                        />
                                    </div>
                                    <div className="col">
                                        <FormInput
                                            label="Mobile"
                                            name="Mobile"
                                        />
                                    </div>
                                </div>
                                <div className="row row-cols-2">
                                    <div className="col mb-3">
                                        <label className="form-label">Gender</label>
                                        <select name="Gender" className="form-control" >
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <div className="col">
                                        <FormInput
                                            label="Source of The Lead"
                                            name="LeadSource"
                                        />
                                    </div>
                                </div>
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <FormInput
                                            label="Email CC Recipients"
                                            name="CcRecipients"
                                            placeHolder="Seperate Email address with comma(,)"
                                            autoComplete="off"
                                            onKeyDown={displayCCEmailsSeperatly}
                                            onBlur={displayCCEmailsSeperatly}
                                        />
                                        <div id="cc-emails" className="mb-3"></div>
                                    </div>
                                </div>
                                <button
                                    type="button"
                                    className="btn btn-success mx-1 mb-1"
                                    onClick={createCustomer}
                                >
                                    <i className="fa fa-plus mx-1"></i>
                                    Create
                                    <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                                </button>
                            </form>
                            <div id="success-message-container" className="d-none">
                                <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Customer Created Successfully.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ................... */}
            <div className="modal fade" id="deleteCustomerModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-danger py-2">
                            <h5 className="modal-title text-white">
                                Delete Customer
                            </h5>
                            <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#deleteCustomerModal"><i className="fa fa-close text-white"></i></button>
                        </div>
                        <div className="modal-body">
                            <div id="delete-confirm-container">
                                <label className="form-label d-block">Are you sure to delete the customer?</label>
                                <button className="btn btn-sm btn-danger mx-1 mb-1" onClick={deleteCustomer}><i className="fa fa-trash mx-1"></i>Delete<i className="fa fa-spin fa-spinner mx-1 d-none"></i></button>
                                <button className="btn btn-sm btn-secondary mx-1 mb-1" data-bs-toggle="modal" data-bs-target="#deleteCustomerModal"><i className="fa fa-times mx-1"></i>Cancel</button>

                            </div>
                            <div id="delete-success-message-container" className="d-none">
                                <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Customer Deleted Successfully.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ................... */}
            <div className="modal fade" id="assignCustomerModal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header bg-primary py-2">
                            <h5 className="modal-title text-white">
                                Assign Customer to user
                            </h5>
                            <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#assignCustomerModal"><i className="fa fa-close text-white"></i></button>
                        </div>
                        <div className="modal-body">
                            <div id="assign-confirm-contaner">
                                <label className="form-label">Select a user to assign the customer to</label>
                                <select id="userToAssign" className="form-control mb-3">
                                    <option value="">Select a user</option>
                                    {users.map((user, index) => (
                                        <option key={index} value={user.Id}>{user.Fullname}</option>
                                    ))}
                                </select>
                                <button className="btn btn-sm btn-success mx-1 mb-1" onClick={assignCustomer}><i className="fa fa-people-arrows mx-1"></i>Assign<i className="fa fa-spin fa-spinner mx-1 d-none"></i></button>
                                <button className="btn btn-sm btn-secondary mx-1 mb-1" data-bs-toggle="modal" data-bs-target="#assignCustomerModal"><i className="fa fa-times mx-1"></i>Cancel</button>

                            </div>
                            <div id="assign-success-message-container" className="d-none">
                                <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>Customer Deleted Successfully.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    //-------------------------------------------------------
    async function getCustomers() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");

            await axiosConfig
                .get(`/customers?page=${currentPage}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setCustomers(result.data.data);
                        setTotalPages(result.data.totalPages);
                        document.getElementById("removeFilterBtn").classList.add("d-none")
                        document.getElementById("Status").value = "";
                        document.getElementById("search").value = "";
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function getUsers() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/users/fullname_only`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setUsers(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function getDeletedCustomers() {
        const loadingContainer = document.getElementById("loadingContainer");
        const deletedCustomersBtn = document.getElementById("deletedCustomersBtn")
        try {
            loadingContainer.classList.remove("d-none");
            if (deletedCustomersBtn.innerHTML === "Show") {
                await axiosConfig
                    .get(`/customers/deleted`)
                    .then((result) => {
                        if (result.data.status === "success") {
                            setCustomers(result.data.data);
                            setTotalPages(result.data.data.TotalPages);
                            document.getElementById("removeFilterBtn").classList.add("d-none")
                            document.getElementById("Status").value = "";
                            document.getElementById("search").value = "";
                            deletedCustomersBtn.classList.remove("btn-secondary");
                            deletedCustomersBtn.classList.add("btn-primary");
                            deletedCustomersBtn.innerHTML = "Hide";
                        }
                        else toast.error(result.data.message);
                    })
                    .catch((error) => { });
            }
            else if (deletedCustomersBtn.innerHTML === "Hide") {
                getCustomers();
                deletedCustomersBtn.classList.add("btn-secondary");
                deletedCustomersBtn.classList.remove("btn-primary");
                deletedCustomersBtn.innerHTML = "Show";
            }
            toast.success("Customer List Updated");
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function getAssignedToMeCustomers() {
        const loadingContainer = document.getElementById("loadingContainer");
        const myCustomersBtn = document.getElementById("myCustomersBtn")
        try {
            loadingContainer.classList.remove("d-none");
            if (myCustomersBtn.innerHTML === "Show") {
                await axiosConfig
                    .get(`/customers/my_customers`)
                    .then((result) => {
                        if (result.data.status === "success") {
                            setCustomers(result.data.data);
                            setTotalPages(result.data.data.TotalPages);
                            document.getElementById("removeFilterBtn").classList.add("d-none")
                            document.getElementById("Status").value = "";
                            document.getElementById("search").value = "";
                            myCustomersBtn.classList.remove("btn-secondary");
                            myCustomersBtn.classList.add("btn-primary");
                            myCustomersBtn.innerHTML = "Hide";
                        }
                        else toast.error(result.data.message);
                    })
                    .catch((error) => { });
            }
            else if (myCustomersBtn.innerHTML === "Hide") {
                getCustomers();
                myCustomersBtn.classList.add("btn-secondary");
                myCustomersBtn.classList.remove("btn-primary");
                myCustomersBtn.innerHTML = "Show";

            }

            toast.success("Customer list Updated");
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function searchCustomers(filterBy = "") {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");

            let searchString = document.getElementById("search").value;
            const removeFilterButton = document.getElementById("removeFilterBtn")
            if (filterBy) {
                const filterItem = document.getElementById(filterBy).value;
                if (filterItem !== "" || filterItem !== null)
                    searchString = filterItem
            }

            let action_url = `/customers/operator_search?q=${searchString}&f=${filterBy}`;
            if (isAdmin) action_url = `/customers/search?q=${searchString}&f=${filterBy}`

            removeFilterButton.classList.remove("d-none")
            await axiosConfig
                .get(action_url)
                .then((result) => {
                    if (result.data.status === "success") {
                        setCustomers(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function createCustomer(e) {
        const spinner = document.getElementsByClassName("fa-spinner")[0];

        try {
            e.target.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("formData");
            const formData = new FormData(form);
            const formDetails = Object.fromEntries(formData.entries());
            //-----------------------------------
            await axiosConfig
                .post(`/customer/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        form.classList.add("d-none");
                        document.getElementById("success-message-container").classList.remove("d-none");
                        getCustomers();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    const errors = error.response.data.errors;
                    const errorsMessages = [];

                    if (error.response.data.title === "One or more validation errors occurred.") {
                        toast.error(PublicConsts.Validation_CompleteAllRequirements);

                        //Convert Errors(json) to Array
                        Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

                        setvalidationErrors(errorsMessages);
                    }
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        e.target.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    async function deleteCustomer(e) {
        const button = e.target;
        const spinner = button.querySelector(".fa-spinner");
        try {

            button.classList.add("disabled");
            spinner.classList.remove("d-none");

            await axiosConfig
                .delete(`/customer/${customerIdForActions}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        getCustomers();
                        document.getElementById("delete-confirm-container").classList.add("d-none");
                        document.getElementById("delete-success-message-container").classList.remove("d-none");

                    } else toast.error(result.data.message);
                })
                .catch((error) => {

                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        button.classList.remove("disabled");
        spinner.classList.add("d-none")
    }
    async function assignCustomer(e) {

        const button = e.target;
        const spinner = button.querySelector(".fa-spinner");
        const selectedUser = document.getElementById("userToAssign").value;

        if (!selectedUser) {
            toast.error("Please select a user")
            return
        }
        try {

            button.classList.add("disabled");
            spinner.classList.remove("d-none");

            const data = {
                uid: selectedUser,
                cid: customerIdForActions
            }

            await axiosConfig
                .post(`/customer/assign`, data)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        document.getElementById("assign-confirm-contaner").classList.add("d-none");
                        document.getElementById("assign-success-message-container").classList.remove("d-none");
                        getCustomers();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {

                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        button.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    function copyToClipboard(id, type) {

        let textToCopy = "";
        let toastText = "";

        if (type === "setupPass") {
            textToCopy = PublicConsts.user_portal_setup_pass_base_url + id
            toastText = "Setup Password Link Copied!"
        }
        else if (type === "portalLink") {

            textToCopy = PublicConsts.user_portal_url + id
            toastText = "Portal Url Copied!"
        }

        navigator.clipboard.writeText(textToCopy);
        toast.success(toastText)
    };
    function displayCCEmailsSeperatly(e) {
        const ccInput = e.target;

        if (e.key === ' ') {
            e.preventDefault();
        }
        else {

            if (e.key === ',' || e.type === 'blur') {
                let emails = ccInput.value.trim().split(',').filter(email => email.trim() !== "");
                const displayEmailContainer = document.getElementById('cc-emails');
                // reset container
                displayEmailContainer.innerHTML = ""
                // Convert single email string to an array with one element
                if (!Array.isArray(emails)) emails = [emails];

                if (emails) {
                    emails.forEach(function (item) {
                        const parentSpan = document.createElement('span');
                        parentSpan.className = 'badge bg-primary position-relative mx-1';
                        parentSpan.textContent = item;
                        displayEmailContainer.appendChild(parentSpan);

                    })
                }
            }
        }
    }

    //-------------------------------------------------------
};

export default Customers;
