import { useState, useEffect } from 'react';
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import AddCalendarEventModal from "../../modals/addCalendarEventModal"

const localizer = momentLocalizer(moment);

const EventCalendar = () => {

    const [events, setEvents] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        getEvents();
    },[])

    return (
        <div className="card mb-3">
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
            <div>
                <Calendar
                    localizer={localizer}
                    events={events}
                    startAccessor="start"
                    endAccessor="end"
                    selectable
                    onSelectSlot={handleSelectDate}
                    onSelectEvent={handleEventClick}
                    popup={true}
                    popupOffset={{ x: 30, y: 20 }}
                    style={{ height: 700 }}
                />
            </div>
            {/* Modal------------------------------------------------------------------- */}
            <AddCalendarEventModal getEvents={getEvents} />

                <div id="modal-backdrop" className="fade show"></div>
            </div>
        </div>
    );
    //-----------------------------------------
    async function getEvents() {
        try {
            await axiosConfig
                .get(`/calendar_events/`)
                .then((result) => {

                    if (result.data.status === "success") {
                        
                        //Convert Date format from string to JS date format
                        const dateConvertedEvents = result.data.data.map(event => ({
                            ...event,
                            start: new Date(event.StartDate),
                            end: new Date(event.EndDate),
                            title: event.Title,
                        }));
                        setEvents(dateConvertedEvents);
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    //console.log(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
    }
    function handleSelectDate(calendarDay) {

        setSelectedDate(calendarDay.start)

        const modal = document.getElementById('addCalendarEventModal');
        const backDrop = document.getElementById('modal-backdrop');
        const selectedDateInModal = document.getElementById("selectedDateInModal")
        const shortSelectedDateInModal = document.getElementById("shortSelectedDateInModal")

        const date = new Date(calendarDay.start);
        const shortDate = date.toDateString();

        if (modal) {
            modal.classList.add("show");
            backDrop.classList.add("modal-backdrop");
            modal.classList.add("d-block");
            selectedDateInModal.innerHTML = calendarDay.start
            shortSelectedDateInModal.innerHTML = shortDate
        }

    }
    async function handleEventClick(event) {

        const isConfirm = window.confirm("Do you want to delete the event?")

        if (isConfirm) {
            try {
                await axiosConfig
                    .delete(`/calendar_events/${event.Id}`)
                    .then((result) => {

                        if (result.data.status === "success") {
                            toast.success(result.data.message);
                            getEvents();
                        } else toast.error(result.data.message);
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            } catch {
                toast.error(PublicConsts.TryCatch_ErrorMessage);
            }
        }
        
    };
};

export default EventCalendar;
