import { useEffect } from "react";
import { toast } from "react-toastify"
import { PublicConsts } from "../../publicConsts";
import axiosConfig from "../../axiosConfig";

const FirstRequestDocumentsModal = ({ customerId, customerEmail, UrnId, getCustomerDetails, fullName }) => {

    const subject = "Welcome to UK Residency - Your Journey Starts Here";
    const messageLink = PublicConsts.user_portal_url + UrnId;
    const setUpPasswordLink = PublicConsts.user_portal_setup_pass_base_url + UrnId;
    const message = `
<p>Dear ${fullName},</p>

<p>Welcome to <strong>UK Residency</strong> - Your smart gateway to the United Kingdom</p>

<p>Thank you for choosing us as your trusted immigration partner on your journey to the United Kingdom. We are excited to support you every step of the way.</p>

<p>Your secure portal is now ready and waiting for you! It's your one-stop shop for a seamless residency experience, where you can upload documents, track progress, and access essential information, all in one place.</p>

<p>Here's what you need to do next:</p>

<ol>
    <li><strong>Access Your Portal:</strong> Click the link below to access your portal and set up your username and password. This link is unique to you and should not be shared with anyone else.<br>
    <a target='_blank' href="${setUpPasswordLink}">Set up your password</a><br>
    <a target='_blank' href="${messageLink}">Access your portal</a></li>

    <li><strong>Upload Documents:</strong> Submit the necessary documents for our review. Our team will then begin the compliance checks.</li>

    <li><strong>Receive Your Due Diligence Report:</strong> Once compliance is complete, you will receive a comprehensive report. This will determine if we can represent you and take on your immigration case file.</li>
</ol>

<p>Our innovative and industry-leading technology ensures a smooth and secure experience throughout the process. If you have any questions or need further assistance, please don't hesitate to contact our support team by replying to this email.</p>

<p>We look forward to helping you achieve UK residency.</p>

<p>Best regards,<br>The UK Residency Team</p>
`;


    useEffect(() => {
        const messageConverted = message.replace("uk_residency", "uk-residency");
        document.getElementById("firstCallEligibleEmailContent").innerHTML = messageConverted;
    }, []);


    return (

        <div className="modal fade" id="firstCallEligibleEmailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Send Email</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send an email asking for required document and customer's portal link</div>
                            <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerEmail}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Message: </span><span id="firstCallEligibleEmailContent"></span></div>
                        </form>
                        <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={firstCallEligibleSendEmail}>
                            <i className="fa fa-paper-plane mx-1"></i>
                            Send
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
    //---------------------------------------------------------------
    async function firstCallEligibleSendEmail(e) {

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        submitButton.classList.add("disabled");
        submitButtonSpinner.classList.remove("d-none");

        // const mainContent = document.getElementsByClassName("formContent");
        // const successMessage = document.getElementsByClassName("successMessage");
        // const footer = document.getElementsByClassName("modal-footer");

        try {

            // ---------------------------------------------
            const email_detail = {
                "cid": customerId,
                "recipient_email_address": customerEmail,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": false,
                "target": "requestDocuments"
            }

            const formData = new FormData();
            formData.append('email_detail', JSON.stringify(email_detail));
            // ---------------------------------------------

            await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        getCustomerDetails();
                        //............
                        const modal = document.getElementById("firstCallEligibleEmailModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".successMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default FirstRequestDocumentsModal;