import { PublicConsts } from "../../publicConsts";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";

const FirstMeetingWriteSummaryModal = ({summaryText, isReadOnly}) =>{

    return(
        <div className="modal fade" id="firstMeetingWriteSummaryModal" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header py-2 bg-primary">
                <h5 className="modal-title text-white">Write Summary About Customer</h5>
                <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <textarea id="summaryText" rows={5} className="form-control" defaultValue={summaryText} readOnly={isReadOnly === true? "readonly":""}
                placeholder="Facts about the program, summary of the meeting, payment terms and plan"></textarea>
              </div>
              <div className="modal-footer">
                {isReadOnly === false?
                <button type="button" className="btn btn-sm btn-success" onClick={updateCustomerSummary}><i className="fa fa-save mx-1"></i>Save changes</button>
              :""}
              </div>
            </div>
          </div>
        </div>
    )
    //--------------------------------------------------------
    
    async function updateCustomerSummary(){

      const urlArray = window.location.pathname.split("/");
      const cId = urlArray[urlArray.length - 1];

      const summary = document.getElementById("summaryText").value
      if(summary === null)
        return toast.error("Please Enter Summary")

      const loadingContainer = document.getElementById("loadingContainer");
      loadingContainer.classList.remove("d-none");

        try {

          const data ={
            "cId": cId,
            "summary": summary
          }

          await axiosConfig
            .put(`/customer/summary`, data)
            .then((result) => {

              if (result.data.status === "success") {
                toast.success(result.data.message)
              }else {
                toast.error(PublicConsts.Result_NotFound)
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      
      loadingContainer.classList.add("d-none");

    }
};

export default FirstMeetingWriteSummaryModal;