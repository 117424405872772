import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import FormInput from "../../htmlForm/formInput";
import { PublicConsts } from "../../../publicConsts";


const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [userUpdateDetails, setUserUpdateDetails] = useState([]);
  const [selectedUserRole, setSelectedUserRole] = useState(0);
  const [validationErrors, setvalidationErrors] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getUsers();
    getRoles();
  }, [currentPage]);

  return (
    <div className="card shadow-lg mb-3">
      <div className="card-header">
        <span className="h5 text-primary">User List</span>
        <button
          type="button"
          className="btn btn-sm btn-primary float-end"
          data-bs-toggle="modal"
          data-bs-target="#addUserModal"
        >
          <i className="fa fa-plus mx-1"></i>New User
        </button>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none mb-3">
            Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        <div>
            <div className="input-group mb-5">
                <input type="text" id="search" className="form-control" placeholder="Search User"/>
                <button type="button" className="btn btn-sm btn-success" onClick={searchUsers}>
                    <i className="bi bi-search mx-1"></i>
                    Search
                </button>
            </div>
            
        </div>
        <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr>
              <th>row</th>
              <th>Full Name</th>
              <th>Username</th>
              <th>Email</th>
              <th>Role</th>
              <th>Status</th>
              <th>Update</th>
            </tr>
          </thead>
          <tbody>
            {/* Get Data in Update Mode */}
            {users.length !== 0 ? (
              <>
                {users.map((user, index) => (
                  <tr key={user.Id}>
                    <td>{index + 1}</td>
                    <td>{user.Name + " " + user.LastName}</td>
                    <td>{user.Username}</td>
                    <td>{user.Email}</td>
                    <td><span className={user.RoleName === "Admin"? "badge bg-danger":"badge bg-primary"}>{user.RoleName}</span></td>
                    <td>{user.Status === 1? 
                    <span className="badge badge-lg badge-dot"><i className="bg-success"></i>Active</span>
                    :
                    user.Status === 0?
                    <span className="badge badge-lg badge-dot"><i className="bg-danger"></i>InActive</span>
                    :
                    ""
                    }</td>
                    <td>
                      <button className="btn btn-sm btn-warning" data-bs-toggle="modal" data-bs-target="#updateUserModal" onClick={()=> getUser(user.Id)}><i className="fa fa-edit"></i></button>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="12">
                  <div className="alert alert-danger">No Item Found</div>
                </td>
              </tr>
            )}
          </tbody>
          
        </table>
        </div>
        <nav>
          <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav>
      </div>

      {/* Modal------------------------------------------------------------------- */}
      <div className="modal fade" id="addUserModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-primary py-2">
              <h5 className="modal-title text-white">
                Create New User
              </h5>
              <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#addUserModal"><i className="fa fa-close text-white"></i></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="formData">
                <div className="row row-cols-2">
                  <div className="col">
                    <FormInput
                      label="Name"
                      name="Name"
                      required
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="Last Name"
                      name="LastName"
                      required
                    />
                  </div>
                </div>
                <div className="row row-cols-2">
                  <div className="col">
                    <FormInput
                      label="Username"
                      name="Username"
                    />
                  </div>
                  <div className="col">
                    <FormInput
                    type="password"
                      label="Password"
                      name="Password"
                    />
                  </div>
                </div>
                <div className="row row-cols-2">
                  <div className="col">
                    <FormInput
                      label="Email"
                      name="Email"
                    />
                  </div>
                  <div className="col">
                    <FormInput label="Calendar Link" name="CalendarLink" />
                  </div>
                </div>
                <div className="row d-inline">
                  <label className="form-label">User Role</label>
                {roles.map((item, index) => (
                  <span key={index}>
                    <label className="btn btn-sm btn-secondary">{item.Name}
                    <input type="radio" 
                    className="btn-check" 
                    name="userCreateRole"
                     value={item.Id} 
                     onClick={selectUserRole}/>
                    </label>
                    </span>
                  ))}
                </div>
                <div className="mt-3 text-end">
                <button
                      type="button"
                      className="btn btn-sm btn-success mx-1 mb-1"
                      onClick={createUser}
                    >
                      <i className="fa fa-plus mx-1"></i>
                      Create 
                      <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                    </button>
                    </div>        
                
              </form>
              <div id="success-message-container" className="d-none">
                <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>User Created Successfully.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="updateUserModal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header bg-primary py-2">
              <h5 className="modal-title text-white">
                Update User
              </h5>
              <button type="button" className="btn btn-close btn-sm" data-bs-toggle="modal" data-bs-target="#updateUserModal"><i className="fa fa-close text-white"></i></button>
            </div>
            <div className="modal-body">
              {validationErrors.length > 0 ? (
                <div className="alert alert-danger">
                  {validationErrors.map((item) => (
                    <ul>
                      <li>{item}</li>
                    </ul>
                  ))}
                </div>
              ) : (
                ""
              )}
              <form id="updateUserForm">
                {userUpdateDetails? (
                  <>
                <div className="row row-cols-2">
                  <div className="col">
                    <FormInput
                      label="Name"
                      id="upateName"
                      name="Name"
                      required
                      defValue={userUpdateDetails.Name}
                    />
                  </div>
                  <div className="col">
                    <FormInput
                      label="Last Name"
                      id="upateLastName"
                      name="LastName"
                      required
                      defValue={userUpdateDetails.LastName}
                    />
                  </div>
                </div>
                <div className="row row-cols-2">
                  <div className="col">
                    <label className="form-label">Username</label>
                    <div className="form-control text-muted">{userUpdateDetails.Username}</div>
                  </div>
                  <div className="col">
                    <FormInput
                      type="password"
                      label="Password"
                      id="upatePassword"
                      name="Password"
                      required
                    />
                  </div>
                </div>
                <div className="row row-cols-2">
                  <div className="col">
                    <FormInput
                      label="Email"
                      id="upateEmail"
                      name="Email"
                      required
                      defValue={userUpdateDetails.Email}
                    />
                  </div>
                  <div className="col">
                    <FormInput 
                      label="Calendar Link" 
                      id="updateCalendarLink" 
                      name="CalendarLink" 
                      defValue={userUpdateDetails.CalendarLink}/>
                  </div>
                  <input type="hidden" name="Id" value={userUpdateDetails.Id} />
                </div>
                <div className="row d-inline">
                  <label className="form-label">User Role</label>
                {roles.map((item, index) => (
                  <span key={index}>
                    <label className={`btn btn-sm ${item.Id === selectedUserRole? ' btn-primary':" btn-secondary"}`}>{item.Name}
                      <input type="radio" 
                      className="btn-check"
                      name="userUpdateRole" 
                      value={item.Id} 
                      checked={parseInt(item.Id) === parseInt(selectedUserRole)}
                      onChange={selectUserRole}/>
                    </label>
                    
                  </span>
                  ))}
                </div>
                <div className="mt-3 text-end">          
                <button
                  type="button"
                  className="btn btn-sm btn-success mx-1 mb-1"
                  onClick={updateUser}
                >
                  <i className="fa fa-edit mx-1"></i>
                  Update 
                  <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                </button>
                </div>
                </>
                ):(<div className="alert alert-danger">Details Not Found</div>)}
              </form>
              <div id="update-success-message-container" className="d-none">
                <div className="alert alert-success mb-3"><i className="fa fa-check-circle mx-1"></i>User Updated Successfully.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //-------------------------------------------------------
  async function getUsers() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/users?page=${currentPage}`)
        .then((result) => {
          if (result.data.status === "success"){
            setUsers(result.data.data);
            setTotalPages(result.data.data.TotalPages);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function getUser(id){
    const loadingContainer = document.getElementById("loadingContainer");
    loadingContainer.classList.remove("d-none");

    if(id !== "")
    {
      try {
        await axiosConfig
          .get(`/users/${id}`)
          .then((result) => {
            if (result.data.status === "success") {
              setUserUpdateDetails(result.data.data)
              setSelectedUserRole(result.data.data.UserRole)
            }else {
              toast.error(PublicConsts.Result_NotFound)
            }
          })
          .catch((error) => {
            console.log(error)
          });
      } catch (error) {
        console.log(error)
      }
    }
    else
    {
      toast.error(PublicConsts.Result_NotFound)
    }
    
    loadingContainer.classList.add("d-none");
  }
  async function searchUsers() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      const searchString = document.getElementById("search").value;
      await axiosConfig
        .get(`/users/search?q=${searchString}`)
        .then((result) => {
          if (result.data.status === "success"){
            setUsers(result.data.data);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  async function createUser(e) {
    const submitButton = e.target;
    const spinner = submitButton.querySelector(".fa-spinner");
    let selectedUserRole = document.querySelector('input[name="userCreateRole"]:checked');
    if(selectedUserRole !== null) selectedUserRole = selectedUserRole.value
    else return toast.error("Please Select a Role")

    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      //-----------------------------------
      const form = document.getElementById("formData");
      const formData = new FormData(form);
      formData.append("UserRole", selectedUserRole)
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------
      await axiosConfig
        .post(`/users/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            form.classList.add("d-none");
            document.getElementById("success-message-container").classList.remove("d-none");
            getUsers();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }
  async function updateUser(e) {
    const submitButton = e.target;
    const spinner = submitButton.querySelector(".fa-spinner");
    let selectedUserRole = document.querySelector('input[name="userUpdateRole"]:checked');
    if(selectedUserRole !== null) selectedUserRole = selectedUserRole.value
    else return toast.error("Please Select a Role")

    try {
      submitButton.classList.add("disabled");
      spinner.classList.remove("d-none");
      
      //-----------------------------------
      const form = document.getElementById("updateUserForm");
      const formData = new FormData(form);
      formData.append("UserRole", selectedUserRole)
      const formDetails = Object.fromEntries(formData.entries());
      //-----------------------------------

      await axiosConfig
        .put(`/users/`, formDetails)
        .then((result) => {
          if (result.data.status === "success") {
            toast.success(result.data.message);
            form.classList.add("d-none");
            document.getElementById("update-success-message-container").classList.remove("d-none");
            getUsers();
          } else toast.error(result.data.message);
        })
        .catch((error) => {
          const errors = error.response.data.errors;
          const errorsMessages = [];

          if (error.response.data.title === "One or more validation errors occurred.") {
            toast.error(PublicConsts.Validation_CompleteAllRequirements);

            //Convert Errors(json) to Array
            Object.keys(errors).forEach((key) => errorsMessages.push(errors[key][0]));

            setvalidationErrors(errorsMessages);
          }
        });
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    submitButton.classList.remove("disabled");
    spinner.classList.add("d-none");
  }

  async function getRoles() {
    const loadingContainer = document.getElementById("loadingContainer");
    try {
      loadingContainer.classList.remove("d-none");
      await axiosConfig
        .get(`/users/all_roles/`)
        .then((result) => {
          if (result.data.status === "success"){
            setRoles(result.data.data);
          } 
          else toast.error(result.data.message);
        })
        .catch((error) => {});
    } catch {
      toast.error(PublicConsts.TryCatch_ErrorMessage);
    }
    loadingContainer.classList.add("d-none");
  }
  function selectUserRole(e){

    const button = e.target;
    const label = button.parentElement
    const groupName = button.name
    const allRadios = document.getElementsByName(groupName)

    allRadios.forEach((item)=>{
      item.parentElement.classList.remove("btn-primary")
      item.parentElement.classList.add("btn-secondary")
    })
    label.classList.remove("btn-secondary")
    label.classList.add("btn-primary")
    setSelectedUserRole(e.target.value)
  }
  //-------------------------------------------------------
};

export default Users;
