const getBaseUrl = () => {
    const protocol = window.location.protocol; // 'http:' or 'https:'
    const host = window.location.hostname;     // 'example.com'
    return `${protocol}//${host}:8443`;
};



export const PublicConsts = {
    Axios_NetworkConnectionError: "Network Connection Error",
    Axios_SessionExpired: "Your session is expired. Please Sign in again",
    Axios_Error500: "Internal Error",
    Axios_Error403: "Permission Error",
    back_end_domain_name: getBaseUrl(),
    user_portal_url: 'https://portal.uk-residency.com/Customer/',
    user_portal_setup_pass_base_url: 'https://portal.uk-residency.com/SetupPassword/',
    view_docs_base_url: 'https://portal.uk-residency.com:8443/static/customers/',
    //---------------------------------------------------------------------------
    Result_Success: "success",
    Result_Fail: "fail",
    Result_NotFound: "Not found",
    //---------------------------------------------------------------------------
    Validation_CompleteAllRequirements: "Please fill all requested fields.",
    TryCatch_ErrorMessage: "Error on Proccess",
    //Google Recaptcha ---------------------------------------------------------------------------
    ReCaptchaSiteKey: "6LdV42QnAAAAAMJ8hnnj8hjJpTK7zkUeGvqigQM1",
};
