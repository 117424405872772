import React, { useState } from 'react';
import { toast } from 'react-toastify';

const FirstMeetingChangeTagModal = ({ onConfirm , tags})=>{

  const [selectedTagId, setSelectedTagId] = useState(null);

  const handleTagChange = (e) => {
    setSelectedTagId(e.target.value);
  };

  const handleConfirm = () => {
    if (selectedTagId !== null) {
      onConfirm(selectedTagId);
      // Reset the selected tag after confirming
      setSelectedTagId(null);
    }
    else 
      toast.error("Please Choose Customer Tag")
  };

    return(
    
        <div className="modal fade" id="firstMeetingChangeTagModal" tabIndex="-1">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header py-2 bg-danger">
              <h5 className="modal-title text-white">Change Customer Tag</h5>
              <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              Change Customer Tag to:
              <select className="btn btn-sm border-info col-5 ms-2" onChange={handleTagChange} value={selectedTagId || ''}>
              <option value="" required>Choose Customer Tag</option>
                  {tags.map((item, index) =>(
                    <option key={index} value={item.Id}>{item.Title}</option>
                  ))}
              </select>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-danger" onClick={handleConfirm}><i className="fa fa-retweet mx-1"></i>Change Customer Tag</button>
            </div>
          </div>
        </div>
      </div>
    
        )
};

export default FirstMeetingChangeTagModal;