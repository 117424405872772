import { useEffect, useState } from "react";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../publicConsts";
import { NavLink } from "react-router-dom";
import "../../assets/css/panel.bootstrap.min.scss";
import "../../assets/css/panel-custom.scss";
import io from 'socket.io-client';
import 'react-toastify/dist/ReactToastify.css';

let baseURL = "http://127.0.0.1:8443";
if (process.env.NODE_ENV !== "development") baseURL = PublicConsts.back_end_domain_name;
//const socket = io(baseURL);
const socket = io(baseURL, { transportOptions: { polling: { extraHeaders: { 'Authorization': localStorage.getItem("token") } } } });

const UserPanelLayout = ({ mainContent }) => {

    const [details, setDetails] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        autoCloseSidebarOnMobile();
        getDetails()
    }, []);

    useEffect(() => {
        const roles = localStorage.getItem("roles");
        if (roles.includes(1)) setIsAdmin(true)
    }, [])

    useEffect(() => {

        // Request user to accept Browser's notification if not accepted already
        if ("Notification" in window && Notification.permission === 'default') {
            document.getElementById("requestAcceptBrowserNotification").classList.remove("d-none")
        }

        socket.on('connect', () => {
            console.log('WebSocket connected');
        });

        socket.on('new_notification', (data) => {
            console.log('New notification:', data.message);
            toast.info(data.message)
            notify(data.message); // Notify using the Web Notifications API
        });

        socket.on('disconnect', () => {
            console.log('WebSocket disconnected');
        });

        // Cleanup on component unmount
        return () => {
            socket.off('connect');
            socket.off('new_notification');
            socket.off('disconnect');
        };
    }, []);

    const notify = (message) => {
        if ('Notification' in window) {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    new Notification('New Notification', {
                        body: message,
                    });
                }
            });
        }
    };

    return (
        <div className="panel-container">
            <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
                {/* <!-- Vertical Navbar --> */}
                <nav className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light border-bottom border-bottom-lg-0 border-end-lg" id="navbarVertical">
                    <div className="container-fluid">
                        {/* <!-- Toggler --> */}
                        <button className="navbar-toggler ms-n2" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <!-- Brand --> */}
                        <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="#">
                            <img src="/assets/img/logo.png" alt="..." />
                        </a>
                        {/* <!-- User menu (mobile) --> */}
                        <div className="navbar-user d-lg-none">
                            {/* <!-- Dropdown --> */}
                            <div className="dropdown">
                                {/* <!-- Toggle --> */}
                                <a href="#" id="sidebarAvatar" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <div className="avatar-parent-child">
                                        <img alt="Image Placeholder" src="/assets/img/avatar.png" className="avatar avatar- rounded-circle" />
                                        <span className="avatar-child avatar-badge bg-success"></span>
                                    </div>
                                </a>
                                {/* <!-- Menu --> */}
                                <div className="dropdown-menu dropdown-menu-end" aria-labelledby="sidebarAvatar">
                                    <NavLink className="nav-link" to="/Panel/Dashboard">
                                        <i className="bi bi-house me-3"></i>
                                        <span>Dashboard</span>
                                    </NavLink>
                                    <NavLink className="nav-link" to="/Panel/Notifications">
                                        <i className="bi bi-bell me-3"></i>
                                        <span>Notifications</span>
                                    </NavLink>
                                    <hr className="dropdown-divider" />
                                    <a className="nav-link">
                                        <i className="bi bi-box-arrow-left"></i>
                                        <button className="btn btn-sm text-danger" onClick={logout}>
                                            Logout
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Collapse --> */}
                        <div className="collapse navbar-collapse" id="sidebarCollapse">
                            {/* <!-- Navigation --> */}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Dashboard">
                                        <i className="bi bi-house"></i>
                                        <span>Dashboard</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Customers">
                                        <i className="bi bi-people"></i>
                                        <span>Customers</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Calendar">
                                        <i className="bi bi-calendar-week"></i>
                                        <span>My Calendar</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Inbox">
                                        <i className="bi bi-envelope"></i>
                                        <span>Inbox</span>
                                        {details && details.DataInbox > 0 ? < span className="badge badge-sm bg-soft-danger text-danger rounded-pill ms-auto">{details.DataInbox}</span> : ""}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Tickets">
                                        <i className="bi bi-ticket"></i>
                                        <span>Tickets</span>
                                        {details && details.OpenTicketCount > 0 ? < span className="badge badge-sm bg-soft-danger text-danger rounded-pill ms-auto">{details.OpenTicketCount}</span> : ""}
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" to="/Panel/Notifications">
                                        <i className="bi bi-bell"></i>
                                        <span>Notifications</span>
                                        {details && details.NotificationCount > 0 ? < span className="badge badge-sm bg-soft-danger text-danger rounded-pill ms-auto">{details.NotificationCount}</span> : ""}
                                    </NavLink>
                                </li>
                                {isAdmin ? (
                                    <>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/Panel/Activities">
                                                <i className="bi bi-activity"></i>
                                                <span>Activities</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/Panel/Users/">
                                                <i className="bi bi-people"></i>
                                                <span>Users</span>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink className="nav-link" to="/Panel/Settings">
                                                <i className="bi bi-sliders2-vertical"></i>
                                                <span>Settings</span>
                                            </NavLink>
                                        </li>
                                    </>
                                ) : ""}

                                <li className="nav-item">
                                    <a class="nav-link">
                                        <i className="bi bi-box-arrow-left"></i>
                                        <button type="button" className="btn btn-sm text-danger" onClick={logout}>
                                            Logout
                                        </button>
                                    </a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
                {/* <!-- Main content --> */}
                <div className="container px-4">
                    <div className="row bg-white p-2 px-5 text-end">
                        <nav className="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none px-0 py-3" id="topbar">
                            <div className="dropdown">
                                <a href="#" className="nav-link px-3 text-base text-muted text-opacity-70 text-opacity-100-hover" id="dropdown-notifications" data-bs-toggle="dropdown" aria-expanded="false">
                                    {details && details.NotificationCount > 0 ? <span className="badge px-2 bg-danger position-absolute top-0 end-6">{details.NotificationCount}</span> : ""}
                                    <i className="bi bi-bell-fill"></i>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end px-2" aria-labelledby="dropdown-notifications">
                                    {details && details.Notifications.length > 0 ?
                                        <>
                                            <div className="dropdown-item d-flex align-items-center">
                                                <h6 className="dropdown-header p-0 m-0 font-semibold">Notifications</h6>
                                            </div>
                                            {details.Notifications.map((item, index) => (
                                                <div key={index} className="dropdown-item py-3 d-flex">
                                                    <div className="flex-fill ms-3">
                                                        <div className="text-sm lg-snug w-64 text-wrap">
                                                            <b className="font-semibold text-heading text-primary-hover mx-1">{item.FullName}</b>
                                                            {item.Message}
                                                        </div>
                                                        <span className="text-muted text-xs">{item.CreateDate.replace("GMT", "")}</span>
                                                        <span className="text-muted text-xs float-end btn btn-sm" title="Mark as seen">
                                                            <i className="fa fa-check-square text-success" onClick={() => markNotificationAsSeen(item.Id)}></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="dropdown-divider"></div>
                                        </>

                                        : ""
                                    }

                                    <div className="dropdown-item py-1 text-center">
                                        <NavLink className="nav-link font-semibold text-muted text-primary-hover" to="/Panel/Notifications">
                                            <span>View all notifications</span>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                            <div className="dropdown">
                                <a className="d-flex align-items-center" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                    <div>
                                        <div className="avatar avatar-sm rounded-circle text-white">
                                            <img alt="avatar" src="/assets/img/avatar.png" />
                                        </div>
                                    </div>
                                    <div className="d-none d-sm-block ms-3">
                                        <span className="h6">{details ? details.Name : "User"}</span>
                                    </div>
                                    <div className="d-none d-md-block ms-md-2">
                                        <i className="bi bi-chevron-down text-muted text-xs"></i>
                                    </div>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <div className="dropdown-header">
                                        <span className="d-block text-sm text-muted mb-1">Signed in as</span>
                                        <span className="d-block text-heading font-semibold">{details ? details.Fullname : "User"}</span>
                                    </div>
                                    <div className="dropdown-divider"></div>
                                    <NavLink className="nav-link" to="/Panel/Dashboard">
                                        <i className="bi bi-house me-3"></i>
                                        <span>Dashboard</span>
                                    </NavLink>
                                    <NavLink className="nav-link" to="/Panel/Notifications">
                                        <i className="bi bi-bell me-3"></i>
                                        <span>Notifications</span>
                                    </NavLink>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item">
                                        <i className="bi bi-box-arrow-left me-3"></i>
                                        <button className="btn btn-sm text-danger" onClick={logout}>
                                            Logout
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </nav>

                    </div>

                    <div className="mt-5">
                        {mainContent}

                    </div>
                    {/* Request user to accept Browser's notification if not accepted already (Alert)*/}
                    <div id="requestAcceptBrowserNotification" className="fixed-bottom row justify-content-end pb-3 pe-4 d-none">
                        <div class="alert alert-danger alert-dismissible border-danger fade show col-3 pe-2" role="alert">
                            <h4 class="alert-heading mb-2">Enable Notifications!</h4>

                            <i className="fa fa-info-circle mx-1"></i>Stay updated.
                            <div className="text-end mt-2">
                                <button type="button" className="btn btn-sm btn-primary" onClick={() => Notification.requestPermission()}>Enable Notifications</button>
                            </div>
                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    //-----------------------------------------
    async function getDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/layout_data/`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setDetails(result.data.data);
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    //console.log(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function markNotificationAsSeen(id) {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .put(`/notifications/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        getDetails()
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    function logout() {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        window.location.href = "/";
    }
    function autoCloseSidebarOnMobile() {
        const screenWidth = window.screen.width;
        const menuItems = document.getElementsByClassName("nav-item");
        if (screenWidth < 768) {
            for (let item of menuItems) {
                item.setAttribute("data-bs-toggle", "collapse");
                item.setAttribute("data-bs-target", "#sidebarCollapse");
            }
        }
    }
    //----------------------------------------------------------------------
};

export default UserPanelLayout;
