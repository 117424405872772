
const HelpModal = ({ stepNumber }) => {

    return (

        <div className="modal fade" id="helpModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Help</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className='alert alert-primary'>
                            <i className='fa fa-info-circle mx-1'></i>
                            {stepNumber === 1 ?
                                <span>Complete details of the customer (Optional on this step)</span> :
                                stepNumber === 2 ?
                                    <>
                                        <span>1- Payment Terms should be added by the operator (Optional on this step)</span>
                                        {/*<div><i className='fa fa-info-circle mx-1'></i>2- Send Expansion Estimate to the Customer (Optional on this step)</div>*/}
                                    </> :
                                    stepNumber === 3 ?
                                        <>
                                            <span>1- The email to request upload documents should be sent</span>
                                            <div><i className='fa fa-info-circle mx-1'></i>2- Customer's details should be completed</div>
                                            <div><i className='fa fa-info-circle mx-1'></i>2- DDR email should be sent</div>
                                            <div><i className='fa fa-info-circle mx-1'></i>3- The customer should upload 2 documents and the operator should accept them (passport - Address)</div>
                                        </> :
                                        stepNumber === 4 ?
                                            <>
                                                <span>1- Payment Terms should be added by the operator</span>
                                                {/*<div><i className='fa fa-info-circle mx-1'></i>2- Expansion should be Estimated and the customer accepts it</div>*/}
                                                <div><i className='fa fa-info-circle mx-1'></i>2- The contract should be sent by email (The file also should be visible in the portal)</div>
                                                <div><i className='fa fa-info-circle mx-1'></i>3- The signed contract should be uploaded by the customer and accepted by the operator</div>
                                            </> :
                                            stepNumber === 5 ?
                                                <>
                                                    <span>1- The customer should choose a payment method</span>
                                                    <div><i className='fa fa-info-circle mx-1'></i>2- An Invoice should be sent to the customer</div>
                                                    <div><i className='fa fa-info-circle mx-1'></i>3- Proof of payment should be Uploaded by the customer and accepted by the operator</div>
                                                    <div><i className='fa fa-info-circle mx-1'></i>4- The Receipt should be sent to the customer(by accepting the proof of payment)</div>
                                                </> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
};

export default HelpModal;