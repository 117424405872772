import { useEffect } from "react";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";


const InitialDocSendDDRModal = ({ customerId, customerDetails, fullName, UrnId, getCustomerDetails }) => {

    const subject = "Your Due Diligence Report is Now Available - UK Residency";
    const messageLink = PublicConsts.user_portal_url + UrnId;
    const message = `
    <p>Dear ${fullName},</p>

<p>I hope this email finds you well.</p>

<p>We are pleased to inform you that your due diligence report is now complete and ready for your review. You can access the report by logging into your portal. Simply click the link below to view your results:</p><br>

<p><a target='_blank' href="${messageLink}">Click here to view your report</a></p><br>

<p>If you have any questions or need further assistance, please don’t hesitate to contact our support team by replying to this email.</p><br>

<p>Best regards,<br>The UK Residency Team</p>`


  useEffect(() => {
    document.getElementById("sendDDREmailContent").innerHTML = message;
  }, []);
 

    return(
    
        <div className="modal fade" id="sendDDRModal" tabIndex="-1">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header py-2 bg-primary">
              <h5 className="modal-title text-white">Send DDR file by Email</h5>
              <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
            <form className="formContent">
              <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send DDR File to Customer by Email</div>
              <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerDetails.Email}</span></div>
              <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
              <div className="mb-3"><span className="text-muted">Email Message: </span><span id="sendDDREmailContent"></span></div>
            </form>
            <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
          
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-sm btn-success" onClick={ddrFileSendEmail}>
                <i className="fa fa-paper-plane mx-1"></i>
                Send
                <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    
        )
        //---------------------------------------------------------------
        async function ddrFileSendEmail(e){
    
          const submitButton = e.target;
          const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
          submitButton.classList.add("disabled");
          submitButtonSpinner.classList.remove("d-none");
    
          // const mainContent = document.getElementsByClassName("formContent");
          // const successMessage = document.getElementsByClassName("successMessage");
          // const footer = document.getElementsByClassName("modal-footer");


          // check pre-requirements (if customer's details has been updated)
          for (const key in customerDetails) {
            if (customerDetails.hasOwnProperty(key)) {
              if ((key === "Name" || 
              key === "LastName" || 
              key === "Email" || 
              key === "Mobile" || 
              key === "Nationality" || 
              key === "PassportNumber" || 
              key === "CompanyName" || 
              key === "Address" || 
              key === "Country") &&
              (customerDetails[key] === null || customerDetails[key] === '' || customerDetails[key] === undefined)) {
                
              console.log(key + "----" + customerDetails[key])

                submitButton.classList.remove("disabled");
                submitButtonSpinner.classList.add("d-none");
                return toast.error("The customer's details has not been updated");
              }
            }
          }
    
            try {

              // ---------------------------------------------
              const email_detail ={
                "cid": customerId,
                "recipient_email_address": customerDetails.Email,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": true,
                "target": "ddr"
              }

              const attachmentContentFields ={
                "text_Name":fullName,
                "text_Passport": customerDetails.PassportNumber,
                "URN": customerDetails.UrnId
              }
    
              const formData = new FormData();
              formData.append('email_detail', JSON.stringify(email_detail));
              formData.append('attachment_content_fields', JSON.stringify(attachmentContentFields));
              // ---------------------------------------------
    
              await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                  if (result.data.status === "success") {
                    toast.success(result.data.message);
                    getCustomerDetails();
                     //............
                     const modal = document.getElementById("sendDDRModal")
                      modal.querySelector(".formContent").classList.add("d-none")
                      modal.querySelector(".successMessage").classList.remove("d-none")
                      modal.querySelector(".modal-footer").classList.add("d-none")
                  }else {
                    toast.error(result.data.message)
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
            } catch (error) {
              console.log(error)
            }
          
            submitButton.classList.remove("disabled");
            submitButtonSpinner.classList.add("d-none");
    
        }
};

export default InitialDocSendDDRModal;