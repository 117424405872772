import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import FormInput from "../htmlForm/formInput";

const ChangePasswordModal = ({ customerId }) => {

    return (
        <div className="modal fade" id="changePasswordModal" tabIndex="-1">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Change Password</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <FormInput type="password" label="Password" name="password" />
                            <FormInput type="password" label="Confirm Password" name="confirmPassword" />
                        </form>
                        <div className="passwordSuccessMessage alert alert-success d-none">Password has been changed Successfully</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={changePassword}>
                            <i className="fa fa-lock mx-1"></i>
                            Change Password
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
    //---------------------------------------------------------------
    async function changePassword(e) {

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");

        const password = document.getElementById("password").value
        const confirmPassword = document.getElementById("confirmPassword").value

        if (password.length < 6) {
            return toast.error("Your password must be at least 6 characters.")
        }
        if (password !== confirmPassword) {
            return toast.error("Password and confirm password are not the same.")
        }

        try {
            //---------------------------------------------
            const formData = {
                "cid": customerId,
                "password": password,
            }
            // ---------------------------------------------

            await axiosConfig
                .put(`/account/change_password`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        //............
                        const modal = document.getElementById("changePasswordModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".passwordSuccessMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default ChangePasswordModal;