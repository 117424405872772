import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import { NavLink } from "react-router-dom";


const Dashboard = () => {
    const [details, setDetails] = useState([]);
    useEffect(() => {
        getDetails();
    }, []);
    return (
        <>
            <div id="loadingContainer" className="alert alert-danger d-none mb-2">
                Loading...<i className="fa fa-spinner fa-spin mx-1"></i>
            </div>

            <main className="py-6 bg-surface-secondary">
                <div className="container-fluid">
                    {/* <!-- Card stats --> */}
                    <div className="row g-6 mb-6">
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">Follow Up</span>
                                            <span className="h3 font-bold mb-0">{details.FollowUpCustomerCount}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                                                <i className="bi bi-pin-angle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-0 text-sm">
                                        <span className="badge badge-pill bg-opacity-30 bg-success  me-2">
                                            <i className="bi bi-arrow-up me-1"></i>0%
                                        </span>
                                        <span className="text-nowrap text-xs text-muted">Since last month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">New Cases</span>
                                            <span className="h3 font-bold mb-0">{details.NewCustomerCount}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-primary text-white text-lg rounded-circle">
                                                <i className="bi bi-person"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-0 text-sm">
                                        <span className="badge badge-pill bg-opacity-30 bg-success me-2">
                                            <i className="bi bi-arrow-up me-1"></i>0%
                                        </span>
                                        <span className="text-nowrap text-xs text-muted">Since last month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">Total Cases</span>
                                            <span className="h3 font-bold mb-0">{details.AllCustomerCount}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-info text-white text-lg rounded-circle">
                                                <i className="bi bi-people"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-0 text-sm">
                                        <span className="badge badge-pill bg-opacity-30 bg-danger me-2">
                                            <i className="bi bi-arrow-down me-1"></i>0%
                                        </span>
                                        <span className="text-nowrap text-xs text-muted">Since last month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-sm-6 col-12">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <span className="h6 font-semibold text-muted text-sm d-block mb-2">Open Tickets</span>
                                            <span className="h3 font-bold mb-0">{details.OpenTicketCount}</span>
                                        </div>
                                        <div className="col-auto">
                                            <div className="icon icon-shape bg-warning text-white text-lg rounded-circle">
                                                <i className="bi bi-info-circle"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 mb-0 text-sm">
                                        <span className="badge badge-pill bg-opacity-30 bg-success me-2">
                                            <i className="bi bi-arrow-up me-1"></i>0%
                                        </span>
                                        <span className="text-nowrap text-xs text-muted">Since last month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card mb-7">
                        <div className="card-header">
                            <h5 className="mb-0 text-primary">Open Cases</h5>
                        </div>
                        <div className="table-responsive customers-table-container">
                            <table className="table text-center">
                                <thead>
                                    <tr>
                                        <th>Full Name</th>
                                        <th>Email</th>
                                        <th>Portal URL</th>
                                        <th>Setup Password</th>
                                        <th>Registerd Date</th>
                                        <th>Status</th>
                                        <th>View</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {details.length !== null ? (
                                        <>
                                            {details.AllCustomers ? (
                                                <>
                                                    {details.AllCustomers.map((customer, index) => (
                                                        <tr key={customer.Id}>
                                                            <td>{customer.Name + " " + customer.LastName}</td>
                                                            <td>{customer.Email}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-outline-success" onClick={() => copyToClipboard(customer.UrnId, 'portalLink')}>
                                                                    Copy URL
                                                                </button>
                                                            </td>
                                                            <td>
                                                                <button className="btn btn-sm btn-outline-success" onClick={() => copyToClipboard(customer.UrnId, 'setupPass')}>
                                                                    Copy Link
                                                                </button>
                                                            </td>
                                                            <td>{new Date(customer.RegisterDate).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}</td>
                                                            <td className="text-info">{customer.StatusTitle}</td>
                                                            <td>
                                                                <NavLink
                                                                    title="View Profile"
                                                                    className="btn btn-sm btn-neutral mx-1 mb-1"
                                                                    to={`/Panel/Customer/${customer.Id}`}>
                                                                    <i className="fa fa-eye text-primary"></i>
                                                                </NavLink>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <tr>
                                                    <td colSpan="12">
                                                        <div className="alert alert-danger">No Item Found</div>
                                                    </td>
                                                </tr>
                                            )}</>) : (<tr>
                                                <td colSpan="12">
                                                    <div className="alert alert-danger">Customers Details Not Found</div>
                                                </td></tr>
                                    )}
                                </tbody>

                            </table>
                        </div>
                        {/* <nav>
          <ul className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
              <li className="page-item" key={index + 1}>
                <button className={`page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
          ))}
          </ul>
        </nav> */}

                    </div>
                </div>
            </main>
        </>
    );

    //-----------------------------------------
    async function getDetails() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/dashboard/`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setDetails(result.data.data);
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    //console.log(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    function copyToClipboard(id, type) {

        let textToCopy = "";
        let toastText = "";

        if (type === "setupPass") {
            textToCopy = PublicConsts.user_portal_setup_pass_base_url + id
            toastText = "Setup Password Link Copied!"
        }
        else if (type === "portalLink") {

            textToCopy = PublicConsts.user_portal_url + id
            toastText = "Portal Url Copied!"
        }

        navigator.clipboard.writeText(textToCopy);
        toast.success(toastText)
    };
    //-----------------------------------------
};

export default Dashboard;
