const NotFound = () => {
  return (
    <div>
      <button className="btn btn-sm btn-danger text-white text-end" onClick={logout}><i className="fa fa-sign-out"></i></button>
      <div className="card mt-5">
        <div className="card-header bg-danger text-white">
          <h5>Access Denied</h5>
        </div>
        <div className="card-body">You do not have permission to view the desired content!</div>
      </div>
    </div>
  );
  function logout() {
    localStorage.removeItem("token");
    window.location = "/";
  }
};

export default NotFound;
