const CustomerActivitiesModal = (activities) => {
    return (

        <div className="modal fade" id="customerActivitiesModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Customer Activities</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div id="ticketList" className="table-responsive">
                            <table className="table table-hover table-center">
                                <thead>
                                    <tr>
                                        <th>row</th>
                                        <th>Operator</th>
                                        <th>Action</th>
                                        <th>Date</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {(activities.activities.length > 0 ?
                                        activities.activities.map((item, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td className="text-success">{item.UserFullName}</td>
                                                <td>{item.Action}</td>
                                                <td>{item.CreateDate.replace("GMT", "")}</td>
                                            </tr>
                                        )) : <tr><td colSpan="6"><div className="alert alert-warning">No Record Found</div></td></tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    //-------------------------------------------
};

export default CustomerActivitiesModal;