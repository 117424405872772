
import { useEffect, useState } from "react";
import FormInput from "../htmlForm/formInput";
import { PublicConsts } from "../../publicConsts";
import {toast} from "react-toastify"
import axiosConfig from "../../axiosConfig";

const FirstCallQuestionsModal=({id, isReadOnly}) =>{

  const [details, setDetails] = useState(null);

  useEffect(() => {
    getDetails(id);
  }, [id]);
    return(
        <div className="modal fade" id="questionsModal" tabIndex="-1">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header py-2 bg-primary">
                <h5 className="modal-title text-white">Questions at the first call</h5>
                <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
              <form id="questionForm">
                <h5 className="mb-3 text-primary">1- Business Profile and Operations:</h5>
                <hr />
                <section>
                  
                  <p className="mb-2">Are you a business owner or an employee?</p>
                  <FormInput name="AnswerQ1" defValue={details? details.AnswerQ1:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">When was the business established?</p>
                  <FormInput name="AnswerQ2" defValue={details? details.AnswerQ2:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">How long have you been actively involved in your business?</p>
                  <FormInput name="AnswerQ3" defValue={details? details.AnswerQ3:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What industry is your business in?</p>
                  <FormInput name="AnswerQ4" defValue={details? details.AnswerQ4:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Where is the head office of your business located?</p>
                  <FormInput name="AnswerQ5" defValue={details? details.AnswerQ5:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Does your business have any other offices? If yes, where?</p>
                  <FormInput name="AnswerQ6" defValue={details? details.AnswerQ6:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What is the legal structure of your business?</p>
                  <FormInput name="AnswerQ7" defValue={details? details.AnswerQ7:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Are you the sole owner, or do you have business partners?</p>
                  <FormInput name="AnswerQ8" defValue={details? details.AnswerQ8:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Can you describe the shareholding structure of your business?</p>
                  <FormInput name="AnswerQ9" defValue={details? details.AnswerQ9:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">How many shares do you hold?</p>
                  <FormInput name="AnswerQ10" defValue={details? details.AnswerQ10:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  </section>
                  <h5 className="mb-3 mt-5 text-primary">2- Business Performance and Financials:</h5>
                  <hr/>
                  <section>
                  <p className="mb-2">What is the annual turnover or revenue of your business?</p>
                  <FormInput name="AnswerQ11" defValue={details? details.AnswerQ11:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What percentages of your revenue are generated locally and internationally?</p>
                  <FormInput name="AnswerQ12" defValue={details? details.AnswerQ12:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What percentage of your revenue is profit?</p>
                  <FormInput name="AnswerQ13" defValue={details? details.AnswerQ13:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">How many employees are currently working in your business</p>
                  <FormInput name="AnswerQ14" defValue={details? details.AnswerQ14:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Do you take a salary or dividends from the business? If so, how much?</p>
                  <FormInput name="AnswerQ15" defValue={details? details.AnswerQ15:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Are you linked to any other businesses?</p>
                  <FormInput name="AnswerQ16" defValue={details? details.AnswerQ16:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Do you have any other streams of income?</p>
                  <FormInput name="AnswerQ17" defValue={details? details.AnswerQ17:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What is your gross annual income from all income streams?</p>
                  <FormInput name="AnswerQ18" defValue={details? details.AnswerQ18:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  </section>
                  <h5 className="mb-3 mt-5 text-primary">3- UK Business Interest and Intentions:</h5>
                  <section>
                  <p className="mb-2">Do you have any business interest in the UK?</p>
                  <FormInput name="AnswerQ19" defValue={details? details.AnswerQ19:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What value do you think a UK branch will add to your business?</p>
                  <FormInput name="AnswerQ20" defValue={details? details.AnswerQ20:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Do you have an existing innovative business idea? If yes, how is it innovative?</p>
                  <FormInput name="AnswerQ21" defValue={details? details.AnswerQ21:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">How can you demonstrate the viability and scalability of your business or idea?</p>
                  <FormInput name="AnswerQ22" defValue={details? details.AnswerQ22:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">How many years of experience do you have in this field?</p>
                  <FormInput name="AnswerQ23" defValue={details? details.AnswerQ23:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  </section>
                  <h5 className="mb-3 mt-5 text-primary">4- Personal Information and Immigration Intentions:</h5>
                  <hr />
                  <section>
                  <p className="mb-2">Which passport do you hold? Do you have residency in any other country?</p>
                  <FormInput name="AnswerQ24" defValue={details? details.AnswerQ24:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What is your marital status, and what does your spouse do?</p>
                  <FormInput name="AnswerQ25" defValue={details? details.AnswerQ25:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Do you have any children? If yes, how many and what are their ages?</p>
                  <FormInput name="AnswerQ26" defValue={details? details.AnswerQ26:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What is your highest level of education?</p>
                  <FormInput name="AnswerQ27" defValue={details? details.AnswerQ27:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Have you been to the UK before? When was your most recent visit?</p>
                  <FormInput name="AnswerQ28" defValue={details? details.AnswerQ28:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Have you ever been refused a visa?</p>
                  <FormInput name="AnswerQ29" defValue={details? details.AnswerQ29:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Do you have any family in the UK?</p>
                  <FormInput name="AnswerQ30" defValue={details? details.AnswerQ30:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">What motivates you to move to the UK?</p>
                  <FormInput name="AnswerQ31" defValue={details? details.AnswerQ31:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Are you the main applicant and the decision-maker for this process?</p>
                  <FormInput name="AnswerQ32" defValue={details? details.AnswerQ32:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">Is this program a priority for you?</p>
                  <FormInput name="AnswerQ33" defValue={details? details.AnswerQ33:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  <p className="mb-2">When do you intend to move to the UK?</p>
                  <FormInput name="AnswerQ34" defValue={details? details.AnswerQ34:""} readOnly={isReadOnly === true? "readonly":""}></FormInput>
                  
                </section>
                </form>
             </div>
              <div className="modal-footer">
              {isReadOnly === false?
                <button type="button" className="btn btn-sm btn-success" onClick={registerQuestions}><i className="fa fa-save mx-1"></i>Save</button>
                :""}
              </div>
            </div>
          </div>
        </div>
    )
    //-------------------------------------------------------------
    async function getDetails(cid){
      const loadingContainer = document.getElementById("loadingContainer");
      loadingContainer.classList.remove("d-none");

      if(cid)
      {
        try {
          await axiosConfig
            .get(`/questions/${cid}`)
            .then((result) => {
              if (result.data.status === "success") {
                setDetails(result.data.data)
              }else {
                // toast.error(PublicConsts.Result_NotFound)
              }
            })
            .catch((error) => {
              console.log(error)
            });
        } catch (error) {
          console.log(error)
        }
      }
      
      loadingContainer.classList.add("d-none");
    }
    async function registerQuestions() {
      try {
        const form = document.getElementById("questionForm");
        const formData = new FormData(form);
        formData.append("CustomerId", id);
        const formDetails = Object.fromEntries(formData.entries());
        //-----------------------------------
        let result = await axiosConfig.post(`/questions/`, formDetails);
        if (result.data.status === "success") {
          toast.success(result.data.message);
        } else toast.error(result.data.message);
      } catch {
        toast.error(PublicConsts.TryCatch_ErrorMessage);
      }
    }

};

export default FirstCallQuestionsModal;