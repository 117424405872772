import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";
import InboxItemModal from "../../modals/inboxItemDetailsModal"


const DataInbox = () => {
    const [inboxItems, setInboxItems] = useState([]);
    const [itemDetails, setItemDetails] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        getInboxItems();
    }, [currentPage])
    return (
        <div className="card shadow-lg mb-3">
            <div className="card-header p-3">
                <span className="h5 text-primary">Inbox</span>
            </div>
            <div className="card-body">
                <div id="loadingContainer" className="alert alert-danger d-none mb-3">
                    Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
                </div>
                {/* <div>
            <div className="input-group mb-5">
                <input type="text" id="search" className="form-control" placeholder="Search Name or Email"/>
                <button type="button" className="btn btn-sm btn-success" onClick="{searchCustomers}">
                    <i className="bi bi-search mx-1"></i>
                    Search
                </button>
            </div>
        </div> */}
                <div className="table-responsive">
                    <table className="table text-center">
                        <thead>
                            <tr>
                                <th>Type</th>
                                <th>Source</th>
                                <th>Phone Number</th>
                                <th>Date</th>
                                <th>Seen Date</th>
                                <th>Status</th>
                                <th>View</th>
                                <th>Is Seen</th>
                            </tr>
                        </thead>
                        <tbody>

                            {inboxItems && inboxItems.length !== 0 ? (
                                <>
                                    {inboxItems.map((item, index) => (
                                        <tr key={index} className={item.IsSeen ? "bg-soft-light" : ""}>
                                            <td>{
                                                item.Type === 'Eligibility' ? <span className="text-success">{item.Type}</span> :
                                                    item.Type === 'Question' ? <span className="text-warning">{item.Type}</span> :
                                                        item.Type === 'Consultation' ? <span className="text-primary">{item.Type}</span> :
                                                            item.Type === 'Contact' ? <span className="text-info">{item.Type}</span> :
                                                                item.Type
                                            }
                                            </td>
                                            <td>{
                                                item.Source === 'Whatsapp' ? <span><i className="fa fa-sm fa-circle text-success mx-1"></i>{item.Source}</span> :
                                                    item.Source === 'Website' ? <span><i className="fa fa-sm fa-circle text-primary mx-1"></i>{item.Source}</span> :
                                                        item.Source
                                            }</td>
                                            <td>{item.PhoneNumber}</td>
                                            <td>{item.CreateDate.replace("GMT", "")}</td>
                                            <td>{item.SeenDate ? item.SeenDate.replace("GMT", "") : ""}</td>
                                            <td className="status">
                                                {item.IsDone === false ?
                                                    <span className="waiting">Waiting</span> : <span className="success">Proccessing</span>
                                                }
                                            </td>
                                            <td>
                                                <button className="btn btn-sm" title="View Details" data-bs-toggle="modal" data-bs-target="#displayItemDetails" onClick={() => getItemDetails(item.Id)}>
                                                    <i className="fa fa-eye btn btn-sm btn-outline-primary"></i>
                                                </button>

                                            </td>
                                            <td>
                                                {item.IsSeen === false ?
                                                    <button className="btn btn-sm btn-outline-success" title="Mark as Seen" onClick={() => markAsSeen(item.Id)}>
                                                        Seen
                                                    </button>
                                                    : <i className="fa fa-check text-success"></i>
                                                }
                                            </td>
                                        </tr>
                                    ))}
                                </>
                            ) : (
                                <tr>
                                    <td colSpan="8">
                                        <div className="alert alert-danger">No Item Found</div>
                                    </td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <nav>
                    <ul className="pagination">
                        <li className="page-item">
                            <button className={currentPage > 1 ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage - 1)}>
                                <i className="fa fa-arrow-left"></i>
                            </button>
                        </li>
                        {Array.from({ length: totalPages }, (_, index) => (
                            <>
                                {(index + 1 >= currentPage - 2) && (index + 1 <= currentPage + 2) ?
                                    <li className="page-item" key={index + 1}>
                                        <button className={`btn page-link ${currentPage === index + 1 ? "active" : ""}`} onClick={() => setCurrentPage(index + 1)}>
                                            {index + 1}
                                        </button>
                                    </li>
                                    : ""}
                            </>
                        ))}
                        <li className="page-item">
                            <button className={currentPage < totalPages ? "btn page-link" : "btn page-link disabled"} onClick={() => setCurrentPage(currentPage + 1)}>
                                <i className="fa fa-arrow-right"></i>
                            </button>
                        </li>
                    </ul>
                </nav>
            </div>
            {itemDetails ? <InboxItemModal itemDetails={itemDetails} /> : ""}
        </div>
    );
    //-------------------------------------------------------
    async function getInboxItems() {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .get(`/data_inbox?page=${currentPage}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setInboxItems(result.data.data)
                        setTotalPages(result.data.totalPages);
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }

    async function getItemDetails(id) {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .get(`/data_inbox/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setItemDetails(result.data.data.DataJson)
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    async function markAsSeen(id) {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        try {
            await axiosConfig
                .put(`/data_inbox/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getInboxItems()
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    //-------------------------------------------------------
};

export default DataInbox;
