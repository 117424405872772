import { useEffect } from "react";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";


const InvoiceSendReceiptEmailModal = ({ customerId, invoiceDetails, getCustomerDetails }) => {
    const subject = "Your Receipt from UK Residency";
    const portalLink = PublicConsts.user_portal_url + invoiceDetails.urn;
    const message = `<p>Dear ${invoiceDetails.fullName},</p><br>

<p>I trust this message finds you well.</p><br>

<p>We are pleased to confirm receipt of your payment. Attached to this email, you will find the payment receipt for your records.</p>

<p>If you have any questions or need further assistance, please feel free to contact us.</p><br>

<p>Best regards,</p>
`;

    useEffect(() => {
        document.getElementById("invoiceSendReceiptEmailContent").innerHTML = message;
    }, []);

    return (

        <div className="modal fade" id="invoiceSendReceiptEmailModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Send Email</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send receipt by Email</div>
                            <div className="alert alert-info mb-3"><i className="fa fa-info-circle mx-1"></i>A welcome email will be sent with the first receipt.</div>
                            <div className="mb-3"><span className="text-muted">Email Address: </span><span>{invoiceDetails.email}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
                            <div className="mb-3"><span className="text-muted">Payment Amount (GBP): </span><span className="text-danger">{invoiceDetails.amounts.amountGbp}</span></div>
                            <div className="mb-3"><span className="text-muted">Final Payment Amount ({invoiceDetails.amounts.currency}): </span><span className="text-danger">{invoiceDetails.amounts.amount}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Message: </span><span id="invoiceSendReceiptEmailContent"></span></div>
                        </form>
                        <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={invoiceSendReceiptEmail}>
                            <i className="fa fa-paper-plane mx-1"></i>
                            Send
                            <i className="fas fa-spinner fa-spin mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
    //---------------------------------------------------------------
    async function invoiceSendReceiptEmail(e) {
        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        submitButton.classList.add("disabled");
        submitButtonSpinner.classList.remove("d-none");

        // const mainContent = document.getElementsByClassName("formContent");
        // const successMessage = document.getElementsByClassName("successMessage");
        // const footer = document.getElementsByClassName("modal-footer");

        try {

            // ---------------------------------------------
            const email_detail = {
                "cid": customerId,
                "recipient_email_address": invoiceDetails.email,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": true,
                "target": "receipt"
            }

            const attachmentContentFields = {
                "text_Name": invoiceDetails.fullName,
                "URN": invoiceDetails.urn,
                "text_Address": invoiceDetails.address,
                "text_InvoiceNumber": invoiceDetails.invoiceNumber,
                "text_PaymentCount": invoiceDetails.paymentCount,
                "text_PayDate": invoiceDetails.payDate,
                "text_Amount": invoiceDetails.amounts.amount,
                "text_AmountGbp": invoiceDetails.amounts.amountGbp,
                "text_Currency": invoiceDetails.amounts.currency,
                text_PortalLink: portalLink,
            }

            const formData = new FormData();
            formData.append('email_detail', JSON.stringify(email_detail));
            formData.append('attachment_content_fields', JSON.stringify(attachmentContentFields));
            // ---------------------------------------------

            await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getCustomerDetails();
                        //............
                        const modal = document.getElementById("invoiceSendReceiptEmailModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".successMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default InvoiceSendReceiptEmailModal;