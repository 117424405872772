import { useEffect } from "react";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";


const ContractSendContractModal = ({ customerId, customerEmail, fullName, passport, urn, country, address, nationality, payments, getCustomerDetails }) => {

    const subject = "Exciting News: Your UK Expansion Worker Visa Pre-Approval! - UK Residency";
    const messageLink = PublicConsts.user_portal_url + urn;
    const message = `
    <p>Dear ${fullName}</p><br>

<p>We are delighted to inform you that your application for the <strong>UK Expansion Worker Visa</strong> under the Global Business Mobility category has been pre-approved. Congratulations on this significant achievement, which marks an exciting phase in your journey to establishing a business presence in the United Kingdom.</p><br>

<p><strong>Next Steps</strong></p>
<p>To proceed, please confirm your intention to engage our services. Attached to this email, you will find the Client Service Agreement. Kindly review and return a signed copy within 7 days of receiving this email to secure the payment terms and commence the visa application process.</p>
<p>You can conveniently sign electronically via the portal.</p><br>
<p><a target='_blank' href="${messageLink}">Click here to view and sign the Client Service Agreement</a></p><br>

<p><strong>Overview of the UK Expansion Worker Visa Program</strong></p>
<p>Launched in April 2022, the UK Expansion Worker Visa is part of the UK Government's initiative to stimulate economic growth by attracting foreign direct investment. This program is designed to facilitate overseas companies in expanding their operations to the UK by offering residency options to business owners, senior managers, and specialised employees.</p><br>

<p><strong>Simplified Process Overview</strong></p>
<p>To assist you in navigating the upcoming steps, here is a brief outline of what you can expect:</p>
<ol>
    <li><strong>Company Formation:</strong> Incorporate a UK private limited company as a branch of your overseas parent company.</li>
    <li><strong>Office Setup:</strong> Establish a virtual or physical office in the UK to fulfil operational requirements.</li>
    <li><strong>Business Planning:</strong> Formulate a detailed UK Business Plan to present your expansion strategy to the UK Home Office.</li>
    <li><strong>Sponsorship Application:</strong> Apply for a UK Sponsorship Licence, which permits the sponsorship of up to five individuals:
        <ul>
            <li><strong>Expansion Leader:</strong> Main Applicant</li>
            <li><strong>Senior Employees:</strong> Up to 4</li>
            <li><strong>Full Residency:</strong> UK residency for each employee and their dependents.</li>
        </ul>
    </li>
    <li><strong>Residency Benefits:</strong> Enjoy access to free education, excellent healthcare, and resident-rate financial services in the UK.</li>
    <li><strong>Residency Permit:</strong> Acquire an initial 1-year residency permit (BRP), renewable for an additional year, with the possibility to transition to a 5-year settlement visa.</li>
    <li><strong>Permanent Residency and Citizenship:</strong> Become eligible for permanent residency (ILR) and British Citizenship after meeting the stay requirements during the 5-year settlement visa period.</li>
</ol>

<p><strong>Documentation Requirements</strong></p>
<p>A comprehensive list of necessary documents will be provided during your onboarding. Key documents include passports, proof of address, employment history, payslips, evidence of involvement in the overseas company, business financials, personal financials, and a TB Test Certificate.</p><br>

<p><strong>Time Frame</strong></p>
<p>The approval process typically takes 4 to 5 months, although this can vary depending on external factors. Expedited processing options are available.</p><br>

<p><strong>Cost Breakdown</strong></p><br>
<p><strong>UKR Legal and Consultancy Fees:</strong> Please refer to the attached client service agreement.</p><br>
<p><strong>UK Government Fees:</strong></p>
<ul>
    <li>£298.00 - UK Visa application fee (per person)</li>
    <li>£500.00 - Expedited Sponsorship Licence Fee (optional)</li>
    <li>£536.00 - Sponsorship licence fee</li>
    <li>£776.00 - Child Immigration Health Surcharge (per year)</li>
    <li>£1,035.00 - Adult Immigration Health Surcharge (per year)</li>
</ul>
<p><strong>Accountancy and Office Fees:</strong></p>
<ul>
    <li>£65 per month for a virtual office, with physical office costs varying based on your requirements.</li>
    <li>£1,200 per year (optional)</li>
</ul>

<p>If you have any questions or need further assistance, please don’t hesitate to contact our support team by replying to this email.</p><br>

<p>We look forward to your prompt response and are ready to assist you in every step of this exciting venture.</p><br>

<p>Best regards,</p>
`;


    useEffect(() => {
        document.getElementById("sendContractEmailContent").innerHTML = message;
    }, []);


    return (

        <div className="modal fade" id="sendContractModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Send Contract file by Email</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send Contract File to Customer by Email</div>
                            <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerEmail}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Message: </span><span id="sendContractEmailContent"></span></div>
                        </form>
                        <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-sm btn-success" onClick={contractFileSendEmail}>
                            <i className="fa fa-paper-plane mx-1"></i>
                            Send
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
    //---------------------------------------------------------------
    async function contractFileSendEmail(e) {

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        submitButton.classList.add("disabled");
        submitButtonSpinner.classList.remove("d-none");

        // const mainContent = document.getElementsByClassName("formContent");
        // const successMessage = document.getElementsByClassName("successMessage");
        // const footer = document.getElementsByClassName("modal-footer");

        try {

            // ---------------------------------------------
            const email_detail = {
                "cid": customerId,
                "recipient_email_address": customerEmail,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": true,
                "target": "contract"
            }

            const attachmentContentFields = {
                "text_Name": fullName,
                "text_Passport": passport,
                "URN": urn,
                "text_Country": country,
                "text_Address": address,
                "text_Nationality": nationality
            }

            let totalAmount = 0;

            if (payments.length > 0) {
                totalAmount = parseInt(payments[0].Amount)
                attachmentContentFields["text_FirstPaymentAmount"] = payments[0].Amount
                attachmentContentFields["text_FirstPaymentDate"] = payments[0].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }
            if (payments.length > 1) {
                totalAmount = totalAmount + parseInt(payments[1].Amount)
                attachmentContentFields["text_SecondPaymentAmount"] = payments[1].Amount
                attachmentContentFields["text_SecondPaymentDate"] = payments[1].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }
            if (payments.length > 2) {
                totalAmount = totalAmount + parseInt(payments[2].Amount)
                attachmentContentFields["text_ThirdPaymentAmount"] = payments[2].Amount
                attachmentContentFields["text_ThirdPaymentDate"] = payments[2].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }
            if (payments.length > 3) {
                totalAmount = totalAmount + parseInt(payments[3].Amount)
                attachmentContentFields["text_FourthPaymentAmount"] = payments[3].Amount
                attachmentContentFields["text_FourthPaymentDate"] = payments[3].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }
            if (payments.length > 4) {
                totalAmount = totalAmount + parseInt(payments[4].Amount)
                attachmentContentFields["text_FifthPaymentAmount"] = payments[4].Amount
                attachmentContentFields["text_FifthPaymentDate"] = payments[4].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }
            if (payments.length > 5) {
                totalAmount = totalAmount + parseInt(payments[5].Amount)
                attachmentContentFields["text_SixthPaymentAmount"] = payments[5].Amount
                attachmentContentFields["text_SixthPaymentDate"] = payments[5].PayDate
                attachmentContentFields["text_TotalAmount"] = totalAmount
            }

            const formData = new FormData();
            formData.append('email_detail', JSON.stringify(email_detail));
            formData.append('attachment_content_fields', JSON.stringify(attachmentContentFields));
            // ---------------------------------------------

            await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message)
                        getCustomerDetails();
                        //............
                        const modal = document.getElementById("sendContractModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".successMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default ContractSendContractModal;