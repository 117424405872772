import { useEffect } from "react";
import axiosConfig from "../../axiosConfig";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
// import { ReCaptchaProvider, ReCaptchaV3 } from "react-recaptcha-x";
import { PublicConsts } from "../../publicConsts";
import { jwtDecode } from 'jwt-decode';
import "../../assets/css/login.scss";
import "../../assets/css/login-boxicons.min.scss";


const Login = ({returnUrl }) => {
    const setToken = (token) => {
        localStorage.setItem("token", token);

        const decoded = jwtDecode(token);
        const { roles } = decoded;
        localStorage.setItem("roles", roles);
    };

    //Add google recaptcha script to header
    useEffect(() => {
        const loadReCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${PublicConsts.ReCaptchaSiteKey}`;
            script.async = true;
            script.defer = true;
            document.head.appendChild(script);
        };

        // Call this function to load the script
        loadReCaptchaScript();

    });

    return (
        <div className="login-container vh-100">
            <ToastContainer />

            <div className="wrapper">
                <form action="">
                    <h1>Login</h1>
                    <div className="input-box">
                        <input type="text" id="username" placeholder="Username or Email" name="username" onChange={usernameCheck} onKeyDown={handleKeyDown} />
                        <i className='bi bi-person'></i>
                    </div>
                    <div id="usernameError" className="d-none text-danger mb-3">
                        <small>Username is incorrect</small>
                    </div>
                    <div className="input-box">
                        <input type="password" id="password" name="password" placeholder="Password" onKeyDown={handleKeyDown} />
                        <i className='bi bi-lock'></i>
                    </div>
                    <div className="remember-forgot">
                        <label><input type="checkbox" />Remember me
                        </label>
                        <a href="#">Forgot password?</a>
                    </div>
                    <button type="button" className="btn" id="loginButton" onClick={loginProcess}>
                        Login
                        <i className="fa fa-spin bi bi-arrow-clockwise mx-1 d-none"></i>
                    </button>
                    {/* <div className="register-link">
                <p>Don't have an account? <a href="#">Register</a></p>
            </div> */}
                </form>
            </div>
        </div>
    );

    //-----------------------------------------

    function usernameCheck(e) {
        const text = e.target.value;

        if (e.target.id === "username") {
            const usernameError = document.getElementById("usernameError");
            const sendCodeButton = document.getElementById("sendCodeButton");
            const loginButton = document.getElementById("loginButton");

            // if (text.length !== 11 || text.startsWith("09") === false) {
            //   usernameError.classList.remove("d-none");
            //   sendCodeButton.classList.add("disabled");
            //   loginButton.classList.add("disabled");
            // } else {
            //   usernameError.classList.add("d-none");
            //   sendCodeButton.classList.remove("disabled");
            //   loginButton.classList.remove("disabled");
            // }
        }
    }
    async function loginProcess() {

        const loginButton = document.getElementById("loginButton");
        const spinner = loginButton.querySelector(".fa-spin");

        loginButton.classList.add("disabled");
        spinner.classList.remove("d-none");

        try {
            let reCaptchaToken = await getRecaptchaToken();
            const username = document.getElementById("username").value;
            const password = document.getElementById("password").value;

            //---------------------------------------------------------------------
            const loginDetails = {
                username: username,
                password: password,
                reCaptchaToken: reCaptchaToken,
            };
            await axiosConfig
                .post(`/account/login`, loginDetails)
                .then((result) => {
                    if (result.data.status === "success") {

                        toast.success(result.data.message);
                        setToken(result.data.data);
                        if (returnUrl && returnUrl !== '/') {
                            window.location = returnUrl;
                        }
                        else {
                            window.location = "/Panel/Dashboard";
                        }
                        
                    } else {
                        toast.error(result.data.message);
                        if (result.data.data === "mobileNotConfirmed") {
                            var button = document.getElementById("verifyMobileModalOpenerButton");
                            button.click();
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });

        } catch (error) {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
            console.log(error)
        }
        loginButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    function getRecaptchaToken() {

        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(PublicConsts.ReCaptchaSiteKey, { action: 'submit' })
                    .then(token => {
                        resolve(token);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }
    function handleKeyDown(event) {
        if (event.key === 'Enter') {
            loginProcess();
        }
    };
    //-----------------------------------------
};
export default Login;
