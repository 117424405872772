import { useEffect, useState } from "react";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import { PublicConsts } from "../../publicConsts";

const ScheduleMeetingModal = ({ customerId, customerEmail, fullName }) => {

    const [calendarLink, setCalendarLink] = useState(null);

    const subject = "UK Residency - Schedule a Meeting";
    const messageLink = calendarLink;
    const message = `<p>Dear ${fullName},</p>` +
        `<p>I trust this email finds you well and in good health.</p>` +
        `<p>Please select a convenient time for our meeting from the available options listed in the link: <a target='_blank' href="${messageLink}">Schedule a Meeting</a></p>`;

    useEffect(() => {
        document.getElementById("scheduleMeetingEmailContent").innerHTML = message;
        getUserCalendarLink()
    }, [calendarLink]);


    return (

        <div className="modal fade" id="scheduleMeetingModal" tabIndex="-1">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Send Email</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form className="formContent">
                            <div className="text-success fw-bold mb-3"><i className="fa fa-info-circle mx-1"></i>Send an email asking for meeting time</div>
                            <div className="bg-soft-light p-3 mb-3 rounded">
                                <label className="form-label">Calendar Link</label>
                                <div className="input-group">
                                    <input id="userCalendarLink" className="form-control" defaultValue={calendarLink} onChange={() => document.querySelector('.update-calendar-btn').classList.remove('d-none')} />
                                    <button type="button" className="btn btn-success update-calendar-btn d-none" onClick={updateUserCalendarLink}>Update</button>
                                </div>
                            </div>
                            <div className="mb-3"><span className="text-muted">Email Address: </span><span>{customerEmail}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Subject: </span><span>{subject}</span></div>
                            <div className="mb-3"><span className="text-muted">Calendar Link: </span><span>{calendarLink}</span></div>
                            <div className="mb-3"><span className="text-muted">Email Message: </span><span id="scheduleMeetingEmailContent"></span></div>
                        </form>
                        <div className="successMessage alert alert-success d-none">Email Sent Successfully</div>
                    </div>
                    <div className="modal-footer">
                        <button id="scheduleMeetingBtn" type="button" className="btn btn-sm btn-success" onClick={scheduleMeetingSendEmail}>
                            <i className="fa fa-paper-plane mx-1"></i>
                            Send
                            <i className="fa fa-spin fa-spinner mx-1 d-none"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>

    )
    //---------------------------------------------------------------
    async function getUserCalendarLink() {
        const loadingContainer = document.getElementById("loadingContainer");
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .get(`/users/calendar_link`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setCalendarLink(result.data.data);
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function updateUserCalendarLink() {
        const loadingContainer = document.getElementById("loadingContainer");
        const newCalendarLink = document.getElementById("userCalendarLink").value
        try {
            loadingContainer.classList.remove("d-none");
            await axiosConfig
                .put(`/users/calendar_link?link=${newCalendarLink}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setCalendarLink(result.data.data);
                        toast.success("Calendar link has been updated")
                    }
                    else toast.error(result.data.message);
                })
                .catch((error) => { });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        loadingContainer.classList.add("d-none");
    }
    async function scheduleMeetingSendEmail(e) {

        const calendarLinkInput = document.getElementById("userCalendarLink").value
        if (!calendarLink && !calendarLinkInput) {
            return toast.warning("Please enter Calendar link")
        }

        const submitButton = e.target;
        const submitButtonSpinner = submitButton.querySelector(".fa-spinner");
        submitButton.classList.add("disabled");
        submitButtonSpinner.classList.remove("d-none");

        try {

            // ---------------------------------------------
            const email_detail = {
                "cid": customerId,
                "recipient_email_address": customerEmail,
                "email_subject": subject,
                "email_message": message,
                "has_attachment": false,
                "target": "scheduleMeeting"
            }

            const formData = new FormData();
            formData.append('email_detail', JSON.stringify(email_detail));
            // ---------------------------------------------

            await axiosConfig
                .post(`/send_email`, formData)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        //............
                        const modal = document.getElementById("scheduleMeetingModal")
                        modal.querySelector(".formContent").classList.add("d-none")
                        modal.querySelector(".successMessage").classList.remove("d-none")
                        modal.querySelector(".modal-footer").classList.add("d-none")
                    } else {
                        toast.error(result.data.message)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        submitButton.classList.remove("disabled");
        submitButtonSpinner.classList.add("d-none");

    }
};

export default ScheduleMeetingModal;