const FormTextArea = ({
  label,
  name,
  type,
  defValue,
  onChange,
  placeHolder,
  rows,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={name} className="form-label">
        {label}
      </label>
      <textarea
        type={type}
        id={name}
        name={name}
        defaultValue={defValue}
        onChange={onChange}
        placeholder={placeHolder}
        rows={rows}
        className="form-control"
      ></textarea>
    </div>
  );
};

export default FormTextArea;
