import { useEffect, useState } from "react";
import axiosConfig from "../../../axiosConfig";
import { toast } from "react-toastify";
import { PublicConsts } from "../../../publicConsts";


const Activities = () => {
  const [activities, setActivities] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getActivities();
  }, [currentPage])
  return (
    <div className="card shadow-lg mb-3">
      <div className="card-header">
        <span className="h5 text-primary">Activities</span>
      </div>
      <div className="card-body">
        <div id="loadingContainer" className="alert alert-danger d-none mb-3">
            Receiving Data<i className="fa fa-spinner fa-spin mx-1"></i>
        </div>
        {/* <div>
            <div className="input-group mb-5">
                <input type="text" id="search" className="form-control" placeholder="Search Name or Email"/>
                <button type="button" className="btn btn-sm btn-success" onClick="{searchCustomers}">
                    <i className="bi bi-search mx-1"></i>
                    Search
                </button>
            </div>
        </div> */}
        <div className="table-responsive">
        <table className="table text-center">
          <thead>
            <tr>
              <th>row</th>
              <th>Operator</th>
              <th>Action Type</th>
              <th>Target Customer</th>
              <th>Action</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            
           {activities && activities.length !== 0 ? (
              <>
                {activities.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-success">{item.UserFullName}</td>
                    <td>{item.Type}</td>
                    <td className="text-primary">{item.CustomerFullName}</td>
                    <td>{item.Action}</td>
                    <td>{item.CreateDate.replace("GMT","")}</td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan="6">
                  <div className="alert alert-danger">No Item Found</div>
                </td>
              </tr>
            )}
          </tbody>
          
        </table>
        </div>
        <nav>
          <ul className="pagination">
              <li className="page-item">
                <button className={currentPage>1? "btn page-link":"btn page-link disabled"} onClick={() => setCurrentPage(currentPage - 1)}>
                  <i className="fa fa-arrow-left"></i>
                </button>
              </li>
              {Array.from({ length: totalPages }, (_, index) => (
                <>            
                {(index+1 >= currentPage - 2) && (index+1 <= currentPage + 2)?
              <li className="page-item" key={index + 1}>
                <button className={`btn page-link ${currentPage === index+1? "active":""}`} onClick={() => setCurrentPage(index + 1)}>
                  {index + 1}
                </button>
              </li>
              :""}
              </>
          ))} 
          <li className="page-item">
            <button className={currentPage<totalPages? "btn page-link":"btn page-link disabled"}  onClick={()=>setCurrentPage(currentPage + 1)}>
              <i className="fa fa-arrow-right"></i>
            </button>
          </li>
          </ul>
        </nav>
      </div>
    </div>
  );
  //-------------------------------------------------------
  async function getActivities(){
    const loadingContainer = document.getElementById("loadingContainer");
    loadingContainer.classList.remove("d-none");

      try {
        await axiosConfig
          .get(`/activities?page=${currentPage}`)
          .then((result) => {
            if (result.data.status === "success") {  
              setActivities(result.data.data)
              setTotalPages(result.data.totalPages);
            }else {
              toast.error(PublicConsts.Result_NotFound)
            }
          })
          .catch((error) => {
            console.log(error)
          });
      } catch (error) {
        console.log(error)
      }
    
    loadingContainer.classList.add("d-none");
  }
  //-------------------------------------------------------
};

export default Activities;
