import { useEffect, useState } from "react";
import { PublicConsts } from "../../publicConsts";
import { toast } from "react-toastify"
import axiosConfig from "../../axiosConfig";
import FormInput from "../htmlForm/formInput";
import FormTextArea from "../htmlForm/formTextArea";

const PortalTicketModal = ({ customerId, customer, getDetails }) => {

    const [ticketDetails, setTicketDetails] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);

    useEffect(() => {
        setCustomerDetails(customer);
        //getTicketDetails(customerId);

    }, [customerDetails]);

    //Google Recaptcha ---------------------------------------------------------------------------
    const reCaptchaSiteKey = PublicConsts.ReCaptchaSiteKey;

    //Add google recaptcha script to header
    useEffect(() => {

        const loadReCaptchaScript = () => {
            const script = document.createElement('script');
            script.src = `https://www.google.com/recaptcha/api.js?render=${reCaptchaSiteKey}`;
            script.async = true;
            script.defer = true;

            script.onload = () => {
                getDetails();
            };

            document.head.appendChild(script);
        };

        // Call this function to load the script
        loadReCaptchaScript();
    }, []);

    return (

        <div className="modal fade" id="ticketsModal" tabIndex="-1">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header py-2 bg-primary">
                        <h5 className="modal-title text-white">Tickets</h5>
                        <button type="button" className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div id="ticketList" className="table-responsive">
                            <div className="mb-3 p-2 border-bottom"><button className="btn btn-sm btn-outline-primary new-ticket" onClick={switchTicketModalContent}><i className="fa fa-plus mx-1"></i>New Ticket</button></div>
                            <table className="table table-hover table-center">
                                <thead>
                                    <tr>
                                        <th>Number</th>
                                        <th>Subject</th>
                                        <th>Create Date</th>
                                        <th>Update Date</th>
                                        <th>Status</th>
                                        <th>view & Replay</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {(customerDetails && customerDetails.Tickets && customerDetails.Tickets.length > 0 ?

                                        customerDetails.Tickets.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.Id}</td>
                                                <td>{item.Subject}</td>
                                                <td>{item.CreateDate.split(".")[0]}</td>
                                                <td>{item.UpdateDate.split(".")[0]}</td>
                                                <td className="status">
                                                    {item.Status === '0' ?
                                                        <span className="waiting">Waiting</span> : item.Status === '1' ?
                                                            <span className="success">Proccessing</span> : item.Status === '2' ?
                                                                <span className="done">Closed</span> : ""
                                                    }
                                                </td>
                                                <td><button className="btn btn-sm btn-outline-primary" onClick={() => getTicketDetails(item.Id)}><i className="fa fa-eye"></i></button></td>
                                            </tr>
                                        )) : <tr><td colSpan="6"><div className="alert alert-warning">No Record Found</div></td></tr>)}
                                </tbody>
                            </table>
                        </div>
                        <div id="newTicket" className="d-none">
                            <div className="mb-3 p-2 border-bottom"><button className="btn btn-sm btn-outline-danger ticket-list" onClick={switchTicketModalContent}><i className="fa fa-arrow-left mx-1"></i>Ticket List</button></div>
                            <label className="form-label">New Ticket</label>
                            <form id="newTicketForm">
                                <FormInput name="Subject" placeHolder="Subject"></FormInput>
                                <FormTextArea name="Message" placeHolder="Message" rows="5"></FormTextArea>
                                <button type="button" className="btn btn-sm btn-success" onClick={newTicket}><i className="fa fa-save mx-1"></i>Save<i className="fa fa-spin fa-spinner mx-1 d-none"></i></button>
                            </form>
                            <div id="success-message-container-ticket" className="alert alert-success d-none">Ticket has been created successfully</div>
                        </div>
                        <div id="newReplay" className="d-none">
                            <div className="mb-3 p-2 border-bottom"><button type="button" className="btn btn-sm btn-outline-primary ticket-details" onClick={switchTicketModalContent}><i className="fa fa-arrow-left mx-1"></i>Ticket Details</button></div>
                            <form id="newReplayForm">
                                <label className="form-label">New Replay</label>
                                <FormTextArea name="Message" placeHolder="Message" rows="5"></FormTextArea>
                                <button type="button" className="btn btn-sm btn-success" onClick={newReplay}><i className="fa fa-save mx-1"></i>Save<i className="fa fa-spin fa-spinner mx-1 d-none"></i></button>
                            </form>
                            <div id="success-message-container-ticket-replay" className="alert alert-success d-none">Ticket replay has been saved successfully</div>

                        </div>
                        <div id="ticketDetails" className="d-none">
                            <div className="sticky-top bg-white p-3 mb-2 rounded border">
                                <button id="ticketDetailsBtn" className="btn btn-sm btn-outline-primary ticket-list" onClick={switchTicketModalContent}><i className="fa fa-arrow-left mx-1"></i>Ticket List</button>
                                {/* <NavLink to="/Panel/Tickets" className="btn btn-sm btn-primary mb-3"><i className="fa fa-arrow-left mx-1"></i>Ticket List</NavLink> */}
                                <button className="btn btn-sm btn-danger float-end ticket-replay" onClick={switchTicketModalContent}><i className="fa fa-edit mx-1"></i>New Replay</button>
                            </div>

                            {/* Details */}
                            <div className="row gutters-sm">
                                <div className="col-12 mb-3">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h6 className="d-flex align-items-center mb-4 text-primary fw-bold">Ticket Details</h6>
                                            <div className="p-3">
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-user-circle text-muted mx-1"></i>Full Name</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.FullName : ""}</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Email</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.Email : ""}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Create Date</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.CreateDate : ""}</div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-calendar text-muted mx-1"></i>Update Date</h6>
                                                        <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.UpdateDate : ""}</div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <div className="col-12 col-md-6">
                                                        <h6><i className="fa fa-tags text-muted mx-1"></i>Status</h6>
                                                        <div className="text-muted mt-3 ms-5">
                                                            {ticketDetails ? ticketDetails.Status === 0 ?
                                                                <span className="badge bg-warning">Waiting</span> : ticketDetails.Status === 1 ?
                                                                    <span className="badge bg-success">Proccessing</span> : ticketDetails.Status === 2 ?
                                                                        <span className="badge bg-secondary">Closed</span> : ""
                                                                : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <h6><i className="fa fa-edit text-muted mx-1"></i>Subject</h6>
                                                    <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.Subject : ""}</div>
                                                </div>
                                                <hr />
                                                <div className="row mb-3">
                                                    <h6><i className="fa fa-paragraph text-muted mx-1"></i>Message</h6>
                                                    <div className="text-muted mt-3 ms-5">{ticketDetails ? ticketDetails.Message : ""}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Replies */}
                            <div className="row gutters-sm">
                                <div className="col-12 mb-3">
                                    <div className="card h-100">
                                        <div className="card-body">
                                            <h6 className="d-flex align-items-center mb-3 text-primary fw-bold mb-2">Replies</h6>
                                            <div className="p-4 mt-3">
                                                {(ticketDetails ? ticketDetails.Replies && ticketDetails.Replies.length > 0 ?

                                                    ticketDetails.Replies.map((item, index) => (

                                                        (item.IsAdminReplay === "True" ?
                                                            //Message on Left 
                                                            <>
                                                                <div key={item.Id} className="comment row">
                                                                    <div className="comment-author-ava col-1">
                                                                        <img src="https://www.iconpacks.net/icons/2/free-chat-support-icon-1721-thumb.png" alt="Avatar" className="rounded-circle bg-info" width="50" />
                                                                    </div>
                                                                    <div className="comment-body col-11 border rounded p-3 mb-2">
                                                                        <p className="comment-text">{item.Message}</p>
                                                                        <small className="comment-footer text-muted float-end">{item.CreateDate.split('.')[0]}</small>
                                                                    </div>
                                                                </div>

                                                            </> :
                                                            //Message on Right 
                                                            <>
                                                                <div key={item.Id} className="comment row">
                                                                    <div className="comment-body col-11 border rounded p-3 mb-2">
                                                                        <p className="comment-text text-end">{item.Message}</p>
                                                                        <small className="comment-footer text-muted">{item.CreateDate.split('.')[0]}</small>
                                                                    </div>
                                                                    <div className="comment-author-ava col-1">
                                                                        <img src="https://www.svgrepo.com/show/384674/account-avatar-profile-user-11.svg" alt="Avatar" className="rounded-circle" width="50" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )


                                                    )) : "" : "")}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    //-------------------------------------------

    async function getTicketDetails(id) {
        const loadingContainer = document.getElementById("loadingContainer");
        loadingContainer.classList.remove("d-none");

        const ticketDetailsContainer = document.getElementById("ticketDetails");
        const ticketListContainer = document.getElementById("ticketList");
        const ticketsModalBody = document.querySelector("#ticketsModal .modal-body");

        await getRecaptchaToken();

        try {
            await axiosConfig
                .get(`/tickets/${id}`)
                .then((result) => {
                    if (result.data.status === "success") {
                        setTicketDetails(result.data.data)
                        ticketDetailsContainer.classList.remove("d-none");
                        ticketListContainer.classList.add("d-none");
                        ticketsModalBody.classList.add("bg-light")
                    } else {
                        toast.error(PublicConsts.Result_NotFound)
                    }
                })
                .catch((error) => {
                    console.log(error)
                });
        } catch (error) {
            console.log(error)
        }

        loadingContainer.classList.add("d-none");
    }
    function switchTicketModalContent(e) {
        // const buttonContainer = document.getElementById("newTicketBtn");
        const button = e.target;
        const newTicketContainer = document.getElementById("newTicket");
        const ticketListContainer = document.getElementById("ticketList");
        const ticketDetailsContainer = document.getElementById("ticketDetails");
        const ticketsModalBody = document.querySelector("#ticketsModal .modal-body");
        const newReplayForm = document.getElementById("newReplay");

        if (button.classList.contains("new-ticket")) {
            newTicketContainer.classList.remove("d-none");
            ticketListContainer.classList.add("d-none");
            ticketsModalBody.classList.remove("bg-light");
        }
        else if (button.classList.contains("ticket-list")) {
            newTicketContainer.classList.add("d-none");
            ticketDetailsContainer.classList.add("d-none");
            ticketListContainer.classList.remove("d-none");
            ticketsModalBody.classList.remove("bg-light");
        }
        else if (button.classList.contains("ticket-replay")) {
            ticketDetailsContainer.classList.add("d-none");
            ticketsModalBody.classList.remove("bg-light")
            newReplayForm.classList.remove("d-none")
        }
        else if (button.classList.contains("ticket-details")) {
            ticketDetailsContainer.classList.remove("d-none");
            ticketsModalBody.classList.remove("bg-light")
            newReplayForm.classList.add("d-none")
        }
    }
    async function newTicket(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("newTicketForm");
            const formData = new FormData(form);
            const formDetails = Object.fromEntries(formData.entries());
            formDetails["Id"] = customerDetails.Id;
            //-----------------------------------
            await axiosConfig
                .post(`/tickets/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        form.classList.add("d-none");
                        document.getElementById("success-message-container-ticket").classList.remove("d-none");
                        getDetails();
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    async function newReplay(e) {
        const submitButton = e.currentTarget;
        const spinner = submitButton.querySelector(".fa-spinner");

        try {
            submitButton.classList.add("disabled");
            spinner.classList.remove("d-none");
            //-----------------------------------
            const form = document.getElementById("newReplayForm");
            const formData = new FormData(form);
            const formDetails = Object.fromEntries(formData.entries());
            formDetails["TicketId"] = ticketDetails.Id;
            formDetails["cid"] = customerDetails.Id;
            //-----------------------------------
            await axiosConfig
                .post(`/ticket_reply/`, formDetails)
                .then((result) => {
                    if (result.data.status === "success") {
                        toast.success(result.data.message);
                        document.getElementById("newReplay").classList.add("d-none");
                        getTicketDetails(ticketDetails.Id);
                    } else toast.error(result.data.message);
                })
                .catch((error) => {
                    toast.error(error);
                });
        } catch {
            toast.error(PublicConsts.TryCatch_ErrorMessage);
        }
        submitButton.classList.remove("disabled");
        spinner.classList.add("d-none");
    }
    function getRecaptchaToken() {
        return new Promise((resolve, reject) => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute(reCaptchaSiteKey, { action: 'submit' })
                    .then(token => {
                        resolve(token);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        });
    }
};

export default PortalTicketModal;